@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_article_container_pdf{
    // width: 65vw;
    // padding: 6vh 2vh 6vh 6vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    background: rgba(233, 233, 233, 0.35);
    padding: 16px;
    // background: #FFFFFF;

    // 11111

    .translate_text_icon{
        cursor: pointer;
        width: 3.9%;
    }

    .top_navigation_container{
        display: flex;
        .home_article_view_container{
                font-weight: bolder;
                font-family: 'DM Sans', sans-serif;
                font-size: 1.2rem;
        }
        .refresh_logo_container{
            cursor: pointer;
            margin-left: 0.5vw;
        }

        .preview_export_container{
            display: flex;
            margin-left: auto;

            button{
                border: 1px solid #C8C8C8;
                border-radius: 6px;
                padding: 1.1vh;
                background: white;
                font-weight: 500;
                font-family: 'DM Sans', sans-serif;
                img{
                    margin-left: 0.5vw;
                }
            }

            .export_container{
                margin-left: 1vw;
            }
        }
    }

    // 2222
    .article_content_container{
        display: flex;
        gap: 2vw;
        .left_article_content_container{
            border-radius: 1vh;
            display: flex;
            flex-direction: column;
            width: 55vw;
            .tags_date_container{
                display: flex;
                margin-top: 2vh;
                .tags_container{
                    .tag{
                        border: 1px solid #3B89FE;
                        border-radius: 10px;
                        color: #3B89FE;
                        padding: 0.2vw;
                        font-family: 'DM Sans', sans-serif;
                        // font-weight: 500;
                    }
                }
                .date_container{
                    margin: auto;
                    margin-left: 0.6vw;
                    font-family: 'DM Sans', sans-serif;
                }
            }

            .article_topic_container{
                margin-top: 1vh;
                color: #3B89FE;
                font-family: 'Inter', sans-serif;
                font-size: 5.5vh;
                font-weight: 500;
                line-height: 6.5vh;
                text-align: left;
            }

            .article_keywords_container{
                margin-top: 1vh;
                display: flex;
                flex-direction: column;
                gap: 0.5vh;
                .article_keywords_container_wrapper{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
                .key{
                    padding: 0.5vh 1vh 0.5vh 1vh; 
                    border-radius: 5px;
                    background-color: #FEEADB;
                    text-align: center;
                    font-weight: bold;
                    margin-right: 0.3vw;
                    margin-bottom: 1vh;
                }
                .keywords{
                    padding: 0.5vh 1vh 0.5vh 1vh; 
                    border-radius: 5px;
                    background-color: #DBEBFE;
                    text-align: center;
                    font-weight: bold;
                    margin-left: 0.3vw;
                    margin-bottom: 1vh;
                }
            }

            .source_link_container{
                margin-top: 1vh;

                img{
                    margin-right: 0.8vw;
                }
                a{
                    margin-left: 0.8vw;
                }
            }

            .header{
                font-weight: bold;
            }
            .article_summary{
                margin-top: 2vh;
                font-family: 'Inter', sans-serif;
                color:  #1E1E1E;
                text-align: justify;
                pre{
                    font-size: initial;
                    font-family: 'Inter', sans-serif;
                    white-space: pre-wrap;
                    color: #1E1E1E;
                }
            }
            .full_article{
                margin-top: 2vh;
                font-family: 'Inter', sans-serif;
                text-align: justify;
                pre{
                    font-size: initial;
                    font-family: 'Inter', sans-serif;
                    white-space: pre-wrap;
                    color: #1E1E1E;

                }
            }
            .map{
                width: 50%;
            }
            .leaflet-container{
                // width: 316px;
                height: 40vh;                
            }
        }

        .right_article_conatainer{
            display: flex;
            flex-direction: column;
            margin-left: auto;
            width: 17vw;
            margin-top: 3vh;
            .lop_containers{
                margin-top: 1.5vh;
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
                border-radius: 10px;
                background: linear-gradient(133.46deg, #ECF4FF -3.86%, rgba(244, 244, 244, 0) 137.07%);

                hr{
                    margin: 0;
                }

                .heading{
                    color: #000000;
                    text-align: center;
                    font-size: 2.2vh;
                    font-weight: bold;
                    margin-top: 2vh;
                }

                .no_report_type{
                    padding: 1vh;
                    border-radius: 5px;
                    background-color: #FEEADB;
                    margin: 2vh;
                    text-align: center;
                }

                .lops{
                    display: flex;
                    flex-direction: column;
                    // grid-template-columns: repeat(2, 1fr);
                    padding: 2vh;
                    gap: 10px;
                    // background: linear-gradient(133.46deg, #ECF4FF -3.86%, rgba(244, 244, 244, 0) 137.07%);
                    // .lops_wrapper{
                    //     display: flex;
                    //     gap: 0.8vw;
                    //     align-items: flex-start;
                    //     .bullet_lops{
                    //         width: 5px; /* Adjust the size of the circle */
                    //         height: 5.5px; /* Same as width for a perfect circle */
                    //         border-radius: 50%; /* Makes it a circle */
                    //         background-color: #317ae7; /* Bullet color */
                    //         display: inline-block; /* Place it inline with the text */
                    //         // margin-right: 8px;
                    //         margin-top: 3%;
                    //     }
                    // } 
                    li{
                        // border: 0.1px solid #848484;
                        // border-radius: 4px;
                        // background-color: rgba(255, 255, 255, 0.8);
                        // text-align: center;
                        // display: flex;
                        // justify-content: center;
                        // align-items: center;
                        // font-size: 2vh;
                        color: #000000;
                    }
                    li::marker{
                        color: #317ae7;
                    }
                }
            }


        }

    }

    //3333
    .related_articles_container{
        margin-top: 2vh;
        .related_articles_header{
            font-weight: bold;
        }
        .related_articles_cards{
            margin-top: 2vh;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            gap: 2%;
            .prev_next_button{
                cursor: pointer;
                height: 3vh;
                width: 2vw;
            }
            .prev_next_wrapper{
                padding: 0;
                background: white;
                border: none;
            }
            .prev_next_wrapper_hidden{
                display: None;
            }
            .next_button{
                transform: rotate(180deg);
            }
            .pre_next_faded{
                opacity: 0.5;
            }
            .all_cards{
                // display: flex;
                // align-items: stretch;
                // gap: 2%;
                display: grid;
                grid-template-columns: repeat(4, 1fr); /* Set the number of columns to 4 */
                gap: 2%;
                .article_card{
                    // flex: 1;
                    // flex: 0 0 20%;
                    // width: 40%;
                    // margin: 0 10px;
                    display: flex;   
                    flex-direction: column;
                    padding: 10px;
                    box-shadow: 0px 4px 24px 0px #0000001F;
                    border-radius: 8px;
                    gap: 2%;
                    cursor: pointer;
                    // .card_text{
                    //     display: flex;
                    //     flex-direction: column;
                    //     gap: 2%;
                    .card_title{
                        font-weight: 600;
                        margin-bottom: 10%;
                    }
                    .card_domain_name{
                        margin-top: auto;
                    }
                          
                        //   .card_published_date {
                        //     position: absolute;
                        //     bottom: 30px; /* Adjust the positioning as needed */
                        //     left: 10px; /* Adjust the positioning as needed */
                        //   }
                    // }
                }
                .article_card:hover{
                    transition: all 0.2s ease;
                    // cursor: pointer;
                    // box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 12px 30px 0px #0d6efd47;
                    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
                }
            }
        }
    }
}