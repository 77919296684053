@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.onDemandPdfRightView_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    .circle_top{
        // width: 1000px;
        // height: 780px;
        // // min-width: 1000px;
        // // min-height: 780px;
        // background: rgba(242, 245, 252, 1);
        // border-radius: 50%;
        // position: absolute;
        // top: -250px;
        // right: -250px;
        // overflow: hidden;
        width: 55vw;
        height: 84vh;
        background: #f2f5fc;
        border-radius: 50%;
        position: absolute;
        top: -28vh;
        right: -15vw;
        overflow: hidden;
    }
    .circle_bottom {
        // width: 34vw;
        // height: 34vw;
        // // min-width: 680px;
        // // min-height: 680px;
        // border: 92px solid rgba(87, 112, 254, 1);
        // border-radius: 50%;
        // position: absolute;
        // bottom: -275px; /* Move it slightly below the bottom */
        // right: -275px;
        // overflow: hidden;
        width: 34vw;
        height: 34vw;
        height: calc(34vw * (100vh / 100vw));
        border: 5vw solid #5770fe;
        border-radius: 50%;
        position: absolute;
        bottom: -15vw;
        right: -15vw;
        overflow: hidden;
        background-color: rgba(242, 245, 252, 0.7); /* Adjust the transparency */
            backdrop-filter: blur(10px); /* Adjust the blur amount */
    }
    .filter_container{
        display: flex;
        .filter_content_container{
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
            gap: 0.8vw;
            
            .articles_category{
                .articles{
                    font-family: "Inter";                   
                    background-color: rgba(250, 250, 250, 1);
                    color: #000000;
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
                    padding: 0.8vh 0.8vw 0.8vh 0.8vw;
                    outline: none;
                    border-radius: 11px;
                    border: 1px solid rgba(237, 237, 237, 1);
                    font-weight: 700;
                    .articles_dropdown{
                        margin-left: 4vw;
                        fill: black;
                    }
                    .articles_dropdown_close{
                        fill: black;
                        margin-left: 4vw;
                        transform: rotate(180deg)
                    }
                }
                // .articles_main{
                
                // }
                .dropdown_container{
                    position: absolute;
                    top: calc(100% + 10px); /* Adjust the top value as needed */
                    left: 0;
                    background: white;
                    border: 1px solid #ccc;
                    padding: 10px;
                    z-index: 2;
                    max-height: 30vh; /* Adjust the maximum height as needed */
                    overflow-y: auto;
                }
                .dropdown_container::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                  }
                .dropdown_main_category{
                    padding: 1vh 1vw 1vh 1vw;
                    text-align: center;
                    color: #000000;
                    background-color: #ffffff;
                    border: 1px solid #F1F1F1;
                    cursor: pointer;
                }
                .dropdown_main_category:hover{
                    box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                }
                .dropdown_main_category:active{
                    box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                }
            }

            // .date_filter_container{
            //     display: flex;
            //     align-items: center; 
            //     justify-content: center;
            //     margin-left: 2vw;
            //     margin-top: -1vh;
               
            //     .input_date {
                    
            //         legend{
            //             all: inherit;
            //             color: black;
            //             padding-left: 0.1vw;
            //             padding-right: 0.1vw;
            //             margin: auto;
            //             border: none;
            //             background: none;
            //             text-shadow: 0.2px 0.2px hsl(0, 0%, 50%);
            //         }
            //         fieldset{
            //             border: 1px solid #C8C8C8;
            //             background-color: #ffffff;
            //             border-radius: 6px;
            //         }
            //         .from_date_input::-webkit-calendar-picker-indicator,
            //         .from_date_input::-webkit-inner-spin-button,
            //         .from_date_input::-webkit-outer-spin-button {
            //                 cursor: pointer;
            //         }
            //     }
                
            //     .from_date_input, .to_date_input {
            //         padding: 0 2px 0 2px;
            //         border: none;
            //         outline: none;
            //         width: 100%;
            //         border-radius: 6px;
            //     } 
                
            //     .from_date{
            //         margin-right: 1vw;
            //     }

            // }
            .date_range{
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
                background-color: rgba(250, 250, 250, 1);
                margin-left: 2vw;
            }

            .prompt_toggler{
                font-weight: 700;
                margin-left: 2vw;
                gap: 0.5vw;
                margin-left: auto;
                display: flex;
                align-items: center; 
                // padding: 9px;
                input{
                    cursor: pointer;
                }
                .toggler_main{
                    background-color: #3B89FE;
                    // border: 1px solid;
                    border-radius: 25px;
                    // display: flex;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 12px 4px 12px 4px;

                    .toggles{
                        font-family: "Inter";
                        font-size: 1rem;
                        // padding: 1vh;
                        // padding-left: 0.8vw;
                        // background-color: #3B89FE;
                        color: #ffffff;

                        // border-radius: 25px;
                        transition: right 1s ease;
                        box-shadow: none;
                        flex: 1;
                        outline: none;
                        border-radius: 0;
                        padding-top: 0.2px;
                        padding-bottom: 0.2px;
                        position: relative;
                        .toggle_text{
                            text-align: left;
                            position: relative;
                            z-index: 1;
                        }
                    }
                
                    .left_toggle, .left_toggle:active, .left_toggle:focus{
                        border-right: 0.05px solid #ffffff6e;
                        border-left: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                        // border-top-right-radius: 10px ;
                        // border-bottom-right-radius: 10px;                        
                    }
                    .right_toggle, .right_toggle:active, .right_toggle:focus{
                        border-left: 0px;
                        border-right: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                        // border-top-left-radius: 10px;
                        // border-bottom-left-radius: 10px;
                    }
                                        
                    .right_toggle_circle::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 3px; /* Adjust the distance from the right edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease
                    }
                    .left_toggle_circle::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 3px; /* Adjust the distance from the left edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease;
                    }
                }
              
            }

            .export_pdf_container{
                margin-left: 2vw;
                .Export_button{
                    cursor: pointer; 
                }
            }
        }
    }

    .applied_filters_container{
        display: flex;
        margin-top: 2vh;
        width: 70vw;
        gap: 5vw;
        .expand_collapse{
            // display: flex;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // align-items: center;
            border: 0.5px solid rgba(178, 178, 178, 1);
            border-radius: 6px;
            align-items: center;
            // padding: 6px;
            .back_blue{
                background-color: rgba(49, 122, 231, 1);
            }
            .white_icon{
                filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
            }
            .black_icon{
                filter:  invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
            }
            .expan, .collaps{
                cursor: pointer;
                padding: 8px;
                padding-inline: 10px;
                height: 100%;
            }
        }
        .total_articles{
            font-weight: bold;
            margin-top: auto;
            padding: 1vh;
            font-size: medium;
            background: white;
            // color: #8080ab;
            border-radius: 6px;
            span{
                font-weight: 600;
                background: rgba(49, 122, 231, 1);
                padding: 6px;
                border-radius: 6px;
                color: #FFFFFF;
            }
        }
        .date_applied{
            margin-left: auto;
            padding: 2vh;
            border-radius: 2vh;
            background: #FFFFFF;
            box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
            .header_date{
                font-weight: 600;
            }
            .date_text{
                margin-top: 1vw;
                color: #3B89FE;
            }
        }
        // .apply_filter_button{
        //     // margin-left: 1vw;
        //     display: flex;
        //     align-items: center;
        //     margin-left: 1vw;
        //     // margin-left: auto;
        //     button{
        //         padding: 1.1vh;
        //         // width: 5vw;
        //     }
        // }
    }

    .table_wrapper{
        display: flex;
        margin-top: 2vh;
        .on_demand_table_container{
            width: 100%;
            // margin-top: 12vh;
            // z-index: 1;
            // background-color: transparent;

            .no_results_container{
                // border: 1px solid;
                display: flex;
                flex-direction: column;
                margin-top: 18vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    // margin: auto;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
            
            .arrow{
                height: 33px;
            }

            .dot {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.7vw;
            }
                
            .dot:before {
                content: "";
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #3B89FE;
                width: 0.8vw;
                height: 2.1vh;
                border: 5px solid #3B89FE;
            }
            .view_articles_heatmap{
                position: relative;
                z-index: 0;            
            }
            .pagination_container{
                // display: flex;
                // padding: 6vh 0 2vh 0;
                // justify-content: center;
                // align-items: center;
                background: transparent;
                backdrop-filter: blur(10px);
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    // margin-left: 12vw;
                    font-family: 'Inter';
                    position: absolute;
                    justify-content: center;
                    // font-weight: 600;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .quick_scroll{
            display: flex;
            flex-direction: column;
            // margin-top: 12vh;
            padding-left: 2vw;
            .bottom{
                // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                position: sticky;
                top: 1.5vh;
                // top: 40%;
                cursor: pointer;
            }
            .top{
                margin-top: auto;
                // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                position: sticky;
                bottom: 0;
                bottom: 1.5vh;
                // bottom: 30%;
                cursor: pointer;
            }
        }
    }
} 