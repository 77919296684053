.signUp_container {
  margin: auto;
  padding: 2vh;
}
.signUp_container .topic {
  padding: 4px;
  text-align: center;
  margin-bottom: 2vh;
  font-size: large;
  font-weight: bold;
}/*# sourceMappingURL=signUp.css.map */