.root_analytics_container{
    // border: 1px solid;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    .general_analytics_wrapper{
        border: 1px solid rgba(228, 228, 228, 1);
        background: linear-gradient(246.98deg, #FFFFFF 0%, rgba(232, 232, 232, 0.26) 100%);
        border-radius: 15px;
        // padding: 2.5vh 1vw;
        padding: 1.5vh 1vw;
        // border: 1px solid;
    }

    .no_results_container{
        display: flex;
        flex-direction: column;
        padding: 7vh 0px;
        width: 100%;
        .child_no_results{
            margin: auto;
        }
        .no_results{
            height: 25vh;
            background: #EFF5FF;
            border-radius: 25px;
            padding: 6vh;
            box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
        }
        .no_results_header{
            margin-top: 2vh;
            color: #8080A2;
            font-size: x-large;
        }
        .no_results_subheader{
            color: #848484;
            font-size: large;
        }
    }

    .title_analytics_data{
        text-align: center;
        font-weight: 600;
        font-size: large;
    }
    .heat_map_container{
        display: flex;
        justify-content: space-between;
        gap: 2vw;
        // .heat_map_container_child{
        //     padding: 0.5vh 1vw;
        // }
        .region_selector{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .region_dropdown_container{
                margin-bottom: 1vh;
                .region_selections{
                    display: flex;
                    gap: 0.5vw;
                    align-items: center;
                    position: relative;
                    padding: 0.5vh 1vw;
                    cursor: pointer;
                    border-radius: 6px;
                    border: 1px solid rgba(59, 137, 254, 1);
                    background: #FFFFFF;
                    .region_main_text{
                        color: rgba(59, 137, 254, 1);
                    }
                    .region_dropdown_close{
                        transform: rotate(180deg)
                    }
                    .region_dropdown_close,.region_dropdown{
                        filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
                        margin-left: auto;
                    }
                    .dropdown_container{
                        width: 100%;
                        position: absolute;
                        top: calc(100% + 10px); /* Adjust the top value as needed */
                        left: 0;
                        background: white;
                        border: 1px solid #ccc;
                        padding: 10px;
                        z-index: 6;
                        max-height: 30vh; /* Adjust the maximum height as needed */
                        overflow-y: auto;
                        box-shadow: 0px 20px 50px 0px #0000001a;
                        border-radius: 6px;
                    }
                    .dropdown_container::-webkit-scrollbar {
                        width: 0;
                        background: transparent;
                    }
                    .dropdown_main_category{
                        padding: 1vh 1vw 1vh 1vw;
                        text-align: center;
                        color: #3B89FE;
                        background-color: #ffffff;
                        border: 1px solid #F1F1F1;
                        cursor: pointer;
                    }
                    .dropdown_main_category:hover{
                        box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                    }
                    .dropdown_main_category:active{
                        box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                    }
                }
            }
            .map_range{
                margin-top: auto;
                // border: 1px solid;
                padding: 6px;
            }
        }

        .map_container{
            // flex: 6;
            .title_analytics_data{
                text-align: center;
                font-weight: 600;
                font-size: large;
            }
            .heatmap_container{
                margin-left: auto;
                // border: 1px solid #C8C8C8;
                // border-radius: 1.5vh;
                // background: #FFFFFF;
                // padding: 1vh;
                width: 41vw;
                // box-shadow: 0px 20px 50px 0px #0000001a;
                display: flex;
                align-items: center;
                justify-content: center;
    
                .state_codes_with_count{
                    border-radius: 3px;
                    box-shadow: 0px 20px 50px 0px #0000001a;
                }
    
                // .no_results_container{
                //     display: flex;
                //     flex-direction: column;
                //     padding: 7vh 0px;
                //     .child_no_results{
                //         margin: auto;
                //     }
                //     .no_results{
                //         height: 25vh;
                //         background: #EFF5FF;
                //         border-radius: 25px;
                //         padding: 6vh;
                //         box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                //     }
                //     .no_results_header{
                //         margin-top: 2vh;
                //         color: #8080A2;
                //         font-size: x-large;
                //     }
                //     .no_results_subheader{
                //         color: #848484;
                //         font-size: large;
                //     }
                // }
            }
        }

        .top_stats{
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 60px;
            gap: 2vh;
            .top_stats_header{
                font-weight: 600;
                font-size: large;
            }
            .states_name{
                display: flex;
                flex-direction: column;
                gap: 1vh;
                justify-content: center;
                .state_name{
                    display: flex;
                    gap: 0.5vw;
                    .serial{
                        color: rgba(128, 146, 162, 1);
                    }
                    .state{
                        font-weight: 600;
                        color: rgba(45, 45, 45, 1);
                    }
                }
            }
        }
        
    }

    .bubble_chart_container{
        display: flex;
        gap: 2%;
        .bubble_graph_container{
            // flex: 7;
            width: 70%;
            // padding: 41px 10px 10px 10px;
            border-radius: 6px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 60vh;          
        }
        .bubble_graph_stats{
            flex: 30%;
            padding: 5vh 2vw;
            display: flex;
            gap: 2vh;
            flex-direction: column;
            align-items: center;
            .header_bubble_graph_stats{
                text-align: center;
                font-size: large;
                font-weight: 600;
            }
            .bubble_graph_stats_main_content{
                display: flex;
                flex-direction: column;
                gap: 1vh;
                padding: 20% 0px;
                .bubble_graph_stats_main_content_child{
                    display: flex;
                    gap: 0.5vw;
                    .stat_field{
                        font-weight: 600;   
                    }
                }                
            }
            .get_all_cluster_articles{
                margin-top: auto;
            }
        }
    }

    .time_series_graph_container{
        display: flex;
        gap: 2%;
        backdrop-filter: blur(1px);
        .time_series_graph_wrapper{
            width: 70%;
            border-radius: 6px;
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            height: 60vh;          
        }
        .time_series_graph_stats{
            flex: 30%;
            padding: 5vh 2vw;
            display: flex;
            gap: 2vh;
            flex-direction: column;
            align-items: center;
            .header_time_series_graph_stats{
                text-align: center;
                font-size: large;
                font-weight: 600;
            }
            .time_series_graph_stats_main_content{
                display: flex;
                flex-direction: column;
                gap: 1vh;
                padding: 20% 0px;
                .time_series_graph_stats_main_content_child{
                    display: flex;
                    gap: 0.5vw;
                    .stat_field{
                        font-weight: 600;   
                    }
                }                
            }
            .get_all_cluster_articles{
                margin-top: auto;
            }
        }
    }
}

.article_cluster_content_from_root_bubble{
    .article_cluster_popup_header{
        font-weight: bold;
        text-align: center;
        font-size: large;
        span{
            color: #0C55B0;

        }
    }
    .articles_cluster_table{
        display: flex;
        margin-top: 2vh;
        .table_container{
            width: 100%;
    
            .no_results_container{
                display: flex;
                flex-direction: column;
                margin-top: 18vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
            
            .arrow{
                height: 33px;
            }
    
            .dot {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.7vw;
            }
                
            .dot:before {
                content: "";
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #3B89FE;
                width: 0.8vw;
                height: 2.1vh;
                border: 5px solid #3B89FE;
            }
            .view_articles_heatmap{
                position: relative;
                z-index: 0;            
            }
            .pagination_container{
                // display: flex;
                // padding: 6vh 0 2vh 0;
                // justify-content: center;
                // align-items: center;
                background: transparent;
                backdrop-filter: blur(10px);
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    // margin-left: 12vw;
                    font-family: 'Inter';
                    position: absolute;
                    justify-content: center;
                    // font-weight: 600;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}