@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_history_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_history_container .top_filter_container {
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.8vw;
}
.right_history_container .top_filter_container .report_history_header {
  font-family: "Inter";
  margin-right: 2vw;
  font-size: 25px;
  font-weight: bold;
  margin-top: 0;
  margin-top: 6px;
}
.right_history_container .top_filter_container .refresh_logo_container {
  cursor: pointer;
  padding: 1vh;
  margin-top: 4px;
  height: 4.5vh;
}
.right_history_container .top_filter_container .searchbar_container {
  margin-left: 5vw;
}
.right_history_container .top_filter_container .searchbar_container .input_container {
  border: 1px solid #E5E5E6;
  border-radius: 0.2rem;
  padding: 1vh;
  width: 22vw;
}
.right_history_container .top_filter_container .date_range_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.right_history_container .top_filter_container .date_range_container .custom-date-picker {
  width: 15vw;
}
.right_history_container .top_filter_container .apply_filter_container button {
  background: #0E4FA7;
  padding-inline: 2vw;
}
.right_history_container .top_filter_container .auto_manual_view {
  margin-left: auto;
  display: flex;
  gap: 1vw;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 8px 15px;
}
.right_history_container .top_filter_container .auto_manual_view .auto_manual_tags {
  display: flex;
  gap: 0.5vw;
}
.right_history_container .top_filter_container .auto_manual_view .color_auto {
  background: #0ABCB2;
}
.right_history_container .top_filter_container .auto_manual_view .color_manual {
  background: #D4D4D4;
}
.right_history_container .top_filter_container .auto_manual_view .color_auto, .right_history_container .top_filter_container .auto_manual_view .color_manual {
  padding: 8px;
}
.right_history_container .top_filter_container .auto_manual_view .tag_name {
  font-weight: 600;
}
.right_history_container .history_table_and_content {
  display: flex;
  margin-top: 4vh;
}
.right_history_container .history_table_and_content .history_table_container {
  width: 100%;
}
.right_history_container .history_table_and_content .history_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}
.right_history_container .history_table_and_content .history_table_container .no_results_container .child_no_results {
  margin: auto;
}
.right_history_container .history_table_and_content .history_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.right_history_container .history_table_and_content .history_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.right_history_container .history_table_and_content .history_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.right_history_container .history_table_and_content .history_table_container .table_btn {
  min-width: 6vw;
  align-items: center;
  display: flex;
}
.right_history_container .history_table_and_content .history_table_container .report_type_name {
  margin-left: 0.5vw;
  padding: 4px;
  border-radius: 30px;
  background-color: rgba(173, 173, 173, 0.6509803922);
  text-align: center;
}
.right_history_container .history_table_and_content .history_table_container .no_report_type {
  padding: 1vh;
  border-radius: 30px;
  background-color: #FEEADB;
  text-align: center;
}
.right_history_container .history_table_and_content .history_table_container .report_type {
  padding: 1vh;
  border-radius: 30px;
  background-color: rgba(85, 172, 245, 0.0901960784);
  text-align: center;
}
.right_history_container .history_table_and_content .history_table_container .pagination_container {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  /* top-left | top-right | bottom-right | bottom-left */
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
  position: relative;
}
.right_history_container .history_table_and_content .history_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: absolute;
  left: 1vw;
}
.right_history_container .history_table_and_content .history_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.right_history_container .history_table_and_content .history_table_container .pagination_container .nav_container {
  font-family: "Inter";
  width: auto;
  height: auto;
}/*# sourceMappingURL=rightHistory.css.map */