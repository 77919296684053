@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_keywords_report_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    // background-color: #ffffff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    .filters_container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2vw;
        .keywords_subheader{
            font-weight: 600;
            font-size: x-large;
        }
        .date_container{
            display: flex;
            gap: 1vw;
            align-items: center;
        }
        .date_labels{
            font-weight: 600;
            color: #3B89FE;
        }
        .from_date, .to_date{
            display: flex;
        }
        .from_date_popup_input, .to_date_popup_input{
            display: flex;
        }
        .input_search_filter_input_from_Date, .input_search_filter_input_to_Date{
            flex: auto;
            border: none;
            outline: none;
            color: hsl(0, 0%, 50%);
            background:rgba(250, 250, 250, 1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
            border-radius: 7px;
            padding: 10px;
        }
        .input_search_filter_input_from_Date::-webkit-calendar-picker-indicator, .input_search_filter_input_to_Date::-webkit-calendar-picker-indicator{
            filter: invert(45%);
            // color: #4C5C6B;
        }
        .to_text{
            font-size: large;
        }
    }
    .apply_button{
        margin-left: auto;
    }
    .preview_button{
        // position: absolute;
        background: rgba(14, 79, 167, 1);
        color: #FFFFFF;
        padding-inline: 4vh;
        padding-top: 0.5vh;
        padding-bottom: 0.5vh;
        font-size: larger;
        font-family: "Inter";
        // right: 2vw;
        // top: 45%;
    }
    .org_keywords_table_wrapper{
        display: flex;
        position: relative;
        margin-top: 4vh;
        gap: 6%;
        background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.28%) 77.37%);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
        border-radius: 2%;
        padding: 3% 4% 3% 4%;
        height: 72vh;
        background: transparent;
        backdrop-filter: blur(4px);
        .crud_org_keywords{           
            width: 70%;
            // margin: auto;
            // margin-top: 3vh;
            // padding: 20px;
            // padding: 3% 0 3% 0;
            // padding-inline: 1.5%;
            // padding-top: 2vh;
            // background: #FFFFFF;
            // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
            // border-radius: 2%;
            // border: 1px solid;
            .subheader_user_profile{
                font-weight: 600;
                font-size: larger;
                text-align: center;
                // margin-top: 3vh;
            }

            .crud_search{
                // gap: 1vw;
                display: flex;
                gap: 1vw;
                justify-content: center;
                // margin-top: 1vh; 
                border: 2px solid #E5E5E6;
                border-radius: 6px;
                padding: 1vh 1vw 1vh 1vw;
                background: #FFFFFF;
                // width: 100%;
                // .search_icon{
                    
                // }
                .total_keywords_select{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    padding: 10px;
                    background: #3B89FE;
                    color: #ffffff;
                    font-weight: 600;
                    margin-right: 2vw;
                }
                .crud_add{
                    width: 100%;
                    // padding: 0.5vh 0.5vw 0.5vh 0.5vw;
                    // padding-inline-start: 0.5vw;
                    font-weight: 400;
                    font-size: 16px;
                    // border: 2px solid #E5E5E6;
                    // border-radius: 6px;
                    color: rgba(0, 0, 0, 0.87);
                    border: none;
                    // outline: none;
                    // margin-left: auto;
                }
                .crud_add:focus{
                    outline: none;
                }
                // .add_org_keyword_icon{
                //     margin-left: auto;
                //     cursor: pointer;
                //     width: 5%;
                // }
                
            }
            // .crud_search:focus{
            //     box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
            // }
            .crud_table_org_keywords{
                margin-top: 2vh;
                .edit_button_profile, .cross_button_profile{
                    border: none;
                    padding: initial;
                    background: #ffffff;
                    cursor: pointer;
                }
                .edit_button_profile:focus, .cross_button_profile:focus{
                    outline: none;
                    // box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
                }
                .profile_edit{
                    width: 36%;
                }
                .concern_cell{
                    padding: 4px;
                    border:  1px solid #C8C8C8;
                    border-radius: 6px;
                    text-align: center;
                    cursor: pointer;
                }
                .concern_cell:focus{
                    outline: None;
                }
                .unique_checkbox{
                    cursor : pointer;
                    padding : 6px;
                    width: 2.7rem;
                    height: 2.4vh;
                    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                }
                .unique_checkbox:hover{
                    background-color: rgba(25, 118, 210, 0.04);
                }
            }
            // .display_pages_org_keywords{
            //     // border: 1px solid #E5E5E6;
            //     // border-radius: 6px;
            //     // width: fit-content;
            //     // padding: 2px;
            //     // margin-top: 2%;
            //     color: rgba(133, 133, 133, 1);
            //     justify-content: center;
            //     display: flex;
            //     padding-top: 1%;
            //     float: left;
            //     position: absolute;
            //     .pages_pagination{
            //         font-weight: bold;
            //     }
            // }
            // .prev_next_org_keywords{
            //     justify-content: center;
            //     display: flex;
            //     gap: 2%;
            //     padding-top: 3%;
            //     .prev_next_buttons{
            //         box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.14);
            //         border-radius: 8px;
            //         border: None;
            //         padding: 1% 1.5%;
            //     }
            //     .prev{
            //         background: #3B89FE;
            //         color: #FFFFFF;
            //     }
            //     .next{
            //         border: 2px solid #BDBDBD;
            //     }
            // }
        }
        .show_applied_filters{
            // height: "55vh";
            display: flex;
            flex-direction: column;
            gap: 2.5%;
            padding: 25px;
            border-radius: 6px;
            text-align: left;
            width: 30%;
            background: linear-gradient(175.05deg, #EEF7FF -47.67%, #E9ECFE 121.75%);
            overflow: auto;
            .subheader_user_profile{
                font-weight: 600;
                font-size: x-large;
                // text-align: left;
            }
            .total_selections{
                font-weight: 600;
                display: flex;
                gap: 4%;
                align-items: center;
                font-size: larger;
                .total_number_none{
                    font-size: smaller;
                    color: rgba(28, 112, 200, 1);
                    border: 1px solid rgba(28, 112, 200, 1);
                    border-radius: 4px;
                    padding: 0px 12px;
                }
                .total_number{
                    font-size: smaller;
                    color: #FFFFFF;
                    border-radius: 4px;
                    padding: 0px 12px;
                    background: rgba(14, 79, 167, 1);
                }
            }
            .selections{
                display: flex;
                flex-direction: column;
                gap: 8px;
                .selections_keys{
                    display: flex;
                    gap: 6%;
                    font-weight: 700;
                    .value_key{
                        color: rgba(28, 112, 200, 1);
                    }
                }
            }
        }
    }
    .display_pages_org_keywords{
        // border: 1px solid #E5E5E6;
        // border-radius: 6px;
        // width: fit-content;
        // padding: 2px;
        // margin-top: 2%;
        color: rgba(133, 133, 133, 1);
        justify-content: center;
        display: flex;
        padding-top: 1%;
        float: left;
        position: absolute;
        .pages_pagination{
            font-weight: bold;
        }
    }
    .prev_next_org_keywords{
        justify-content: center;
        display: flex;
        gap: 2%;
        padding-top: 3%;
        .prev_next_buttons{
            box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.14);
            border-radius: 8px;
            border: None;
            padding: 1% 1.5%;
        }
        .prev{
            background: #3B89FE;
            color: #FFFFFF;
        }
        .next{
            border: 2px solid #BDBDBD;
        }
    }
}

.keywords_popup_filter_popup_content{
    // border: 1px solid;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    .dashboard_filters_header_Container{

        display: flex;
        justify-content: center;
        h2{
            all:unset;
            font-weight: bold;
            font-size: 2rem;
        }
        .clear_dashboard_filter{
            margin-left: auto;
            cursor: pointer;
            width: 5%;
        }
    }
    
    .input_search_filters{
        margin-top: 2vh;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        .input_search_filter_labels{
            font-weight: bold;
        }
        .input_search_filter_input{

            box-shadow: 0px 0px 4px rgba(23, 25, 28, 0.05);
            border-radius: 8px;
            min-height: 5vh;
        }
        .from_date_popup_input, .to_date_popup_input{
            background: #FFFFFF;
            border: 1px solid #D8DDE3;
            box-shadow: 0px 0px 4px rgba(23, 25, 28, 0.05);
            display: flex;
            padding-left: 0.7vw;
            padding-right: 0.7vw;
        }
        .input_search_filter_input_from_Date, .input_search_filter_input_to_Date{
            flex: auto;
            border: none;
            outline: none;
            color: hsl(0, 0%, 50%);
        }
        .input_search_filter_input_from_Date::-webkit-calendar-picker-indicator, .input_search_filter_input_to_Date::-webkit-calendar-picker-indicator{
            filter: invert(45%);
            // color: #4C5C6B;
        }
    }
    .apply_cancel_input_search_filters{
        margin-top: 4vh;
        display: flex;
        justify-content: center;
        gap: 2vw;
    }
    .error_container{
        color: red;
        margin-top: 4vh;
        text-align: center;
        font-weight: bold;
    }
}