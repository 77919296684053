@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_details_keywords_report_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .tab_details{
        display: flex;
        gap: 2vw;
        font-weight: 600;
        font-size: x-large;
        .highlighted_tab{
            color:#3b89fe;
            border-bottom: 1px solid #3b89fe;
            border-width: 60%;
        }
        .tab_1, .tab_2{
            cursor: pointer;
        }
    }
    .generated_reports_table_container{
        margin-top: 5vh;    
        .download_reports{
            cursor: pointer;
        }
        .pagination_container{
            background: transparent;
            backdrop-filter: blur(10px);
            display: flex;
            background: #ffffff;
            border-radius: 8px ;
            box-shadow: 0px 20px 50px 0px #0000001a;
            border-radius: 0px 0px 6px 6px;
            padding: 2vh;
            border-top: 1px solid #C8C8C8;
            justify-content: center;
            align-items: center;
            .display_pages{
                font-family: 'Inter';
                display: flex;
                align-items: center;
                margin-right: auto;
                span{
                    font-weight: 600;       
                }
            }
            .nav_container{
                font-family: 'Inter';
                position: absolute;
                justify-content: center;
                display: flex;
                align-items: center;
            }
        }
    }
    
    .schedule_info_container{
        display: flex;
        flex-direction: column;
        gap: 5vh;
        background: transparent;
        backdrop-filter: blur(10px);
        margin-top: 5vh;    
        .schedule_info_tracker, .keywords_display{
            border: 0.5px solid #82ACEB;
            border-radius: 22px;
            background: linear-gradient(222.05deg, rgba(197, 225, 254, 0.12) -17.6%, rgba(208, 227, 255, 0.35) 76.29%);
            padding: 25px 40px;
            box-shadow: 0px 4px 46px 0px #0000001C;
        }
        .schedule_info_tracker{
            display: flex;
            gap: 4%;
            justify-content: space-between;
            .info_sections{
                display: flex;
                flex-direction: column;
                gap: 1vh;
                align-items: center;
                justify-content: center;
                .info_icon{
                    // height: 28%;
                    background: #3285C2;
                    padding: 5px;
                    border-radius: 50%;
                }
                .info_name{
                    font-weight: 600;
                    font-size: large;
                }
                .info_val{
                    font-weight: 600;
                }
            }
        }
        .keywords_display{
            display: flex;
            flex-direction: column;
            gap: 3.5vh;
            .keywords_header{
                display: flex;
                align-items: center;
                .tag_header{
                    font-weight: 600;
                    font-size: large;
                }
                .crud_search{   
                    margin-left: auto;         
                    display: flex;
                    gap: 1vw;
                    justify-content: center;
                    border: 2px solid #E4E4E4;
                    border-radius: 9px;
                    padding: 1vh 1vw 1vh 1vw;
                    background: rgba(250, 250, 250, 0.81);
                    width: 30vw;
                    .crud_add{
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                        border: none;
                        background: rgba(250, 250, 250, 0.81);
                    }
                    .crud_add:focus{
                        outline: none;
                    }
                }
            }
            .keywords_display_main_data{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 13.5vw;
                grid-row-gap: 0.5vh;
                .keywords_main_content{
                    border: 1px solid #D6D6D6;
                    font-size: large;
                    padding: 12px;
                    border-radius: 10px;
                    background: #FFFFFF;
                    // font-weight: bold;
                    color: #202020;
                }
            }
            .keywords_pagination_container{
                display: flex;
                justify-content: center;
                align-items: center;
                // .display_pages{
                //     font-family: 'Inter';
                //     display: flex;
                //     align-items: center;
                //     margin-right: auto;
                //     span{
                //         font-weight: 600;       
                //     }
                // }
                .nav_2_container{
                    font-family: 'Inter';
                    // position: absolute;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}