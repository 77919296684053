@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto&display=swap');

.Login_container{
    background: linear-gradient(219.39deg, #3B89FE 25.64%, #102A5F 132.49%);
    position: relative;
    width: 100vw; 
    height: 100vh;
    // font-family: 'DM Sans', sans-serif;
    font-family: 'Roboto', sans-serif;


    .corner {
        min-width: 10vw; 
        min-height: 20vh; 
        position: absolute;
    }

    .top-right {
        top: 0;
        right: 0;
        background:#FFFFFF;
        opacity: 0.2;
        border-radius: 0vh 0vh 0vh 1.8vh;
    }

    .bottom-left {
        bottom: 0;
        left: 0;
        background: #FFFFFF;
        opacity: 0.2;
        border-radius: 0vh 1.8vh 0vh 0vh;
    }

    .center {
        padding: 5vh;
        min-width: 28vw;
        // height: 62vh;
        min-height: 35vh;
        position: absolute;
        top: 50%; /* move to the center vertically */
        left: 50%; /* move to the center horizontally */
        transform: translate(-50%, -50%); /* adjust the position */
        background: #FFFFFF;
        text-align: center;
        border: 2px solid #DBDBDB;
        box-shadow: 34px -34px 0px 0px #dbdbdb52, 17px -17px 2px 0px #dbdbdb52;
        border-radius: 2.8vh;

        .header_auth{
            font-size: 35px;
            color: #3B89FE;
            font-weight: bold;
        }

        .auth_form_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2vh;
            padding: 1vh;
            .form-group{
                min-width: 20vw;
                text-align: left;
                position: relative;
                .eye_icon_container{
                    position: absolute;
                    right: 2vh;
                    top: 55%;
                    transform: translateY(50%);
                    cursor: pointer;
                }
                label{
                    font-family: 'Roboto', sans-serif;
                    margin-bottom: 0.8vh;
                    font-weight: 600;
                }
                .form-control{
                    min-height: 7vh;
                    background: #F6F6F6;
                    border: none;
                }
                input{
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            .error_container{
                color: #ff0000;
                font-weight: 600;
                display: none;
            }

            .forgot_password{
                margin-top: 2vh;
                text-align: left;
            }
        }
        
    }
}