.Error404_container
{
    height: fit-content;
    width: fit-content;
    position: relative;
    // padding-left: 24;
    margin: auto;
    img{
        // position: absolute;
        width: 70vw;
        height: 70vh;
        padding-top: 25vh;
    }
}