@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');

.right_stats_container{
    width: 84vw;
    padding: 2vh 2vw 2vh 2vw;
    height: 100vh;
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    font-family: "Inter";
    background-color: #ffffff;
    font-family: "Inter";
    position: relative;
    overflow: hidden;
   .main_stats_wrapper{
        display: flex;
        gap: 1vw;
        // width: 60vw;
        align-items: center;
        z-index: 1;
        background: transparent;
        backdrop-filter: blur(4px);
        .stat_section_wrapper{
            display: flex;
            flex-direction: column;
            gap: 1vw;
            .stat{
                border-radius: 6px;
                background: linear-gradient(180deg, rgba(59, 137, 254, 0.3) 0%, rgba(208, 226, 250, 0.3) 100%);
                .stat_content_wrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;
                    padding: 25px;
                    .title_stat{
                        font-size: 16px;
                        font-weight: 600;
                        // padding-right: 3vw;
                    }
                    .data_stat{
                        display: flex;
                        gap: 2vw;
                        align-items: center;
                        .desc{
                            font-size: 16px;
                            font-weight: 600;
                            // padding-right: 16%;
                        }
                        .figure{
                            font-size: 35px;
                            font-weight: 700;
                        }
                        .data_stat_right{
                            margin-left: auto;
                        }

                    }
                }
            }
        }
   }
}