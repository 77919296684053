.right_fact_check_container{
    width: 84vw;
    padding: 2vh 6vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    gap: 2vh;
    overflow: hidden;
    .prompt_container{
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        border: 1px solid #D0E7FE;
        box-shadow: 0px 9px 38px 0px #BDD6FB4F;
        background: linear-gradient(180deg, rgba(226, 239, 252, 0.1512) 0%, rgba(226, 239, 252, 0.378) 84.58%, rgba(226, 239, 252, 0.2457) 91.24%);
        padding: 2.5vh 1vw;
        border-radius: 15px;
        .prompt_header{
            text-align: center;
            color: black;
            font-weight: bold;
            font-size: larger;
        }
        .prompt_container_main{
            display: flex;
            flex-direction: column;
            gap: 2vh;
            width: 100%;
            .input_prompt{
                border: 1px solid hsl(0, 0%, 70%);
                margin: 0px 10vw;
                border-radius: 4px;
                outline: None;
                font-size: 19.8px;
                height: auto;
                padding-top: 5.8px;
                padding-bottom: 5.8px;
                padding-inline: 8px;
            }
            .input_prompt::placeholder{
                font-weight: 100;
                font-size: medium;
                color: #BDBDBD;
            }
            .input_prompt:focus{
                border: 2.5px solid #3B89FE;
                outline: none;
            }
            .check_button{
                width: 20%;
                margin: auto;
            }
        }
    }
    .fact_check_result_display{
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        border: 1px solid #D0E7FE;
        box-shadow: 0px 9px 38px 0px #BDD6FB4F;
        background: linear-gradient(180deg, rgba(226, 239, 252, 0.1512) 0%, rgba(226, 239, 252, 0.378) 84.58%, rgba(226, 239, 252, 0.2457) 91.24%);
        padding: 2.5vh 1vw;
        border-radius: 15px;
        align-items: center;
        .fact_check_result{
            border: 1px solid hsl(0, 0%, 70%);
            margin: 0px 10vw;
            border-radius: 4px;
            outline: None;
            font-size: 19.8px;
            height: auto;
            padding-top: 5.8px;
            padding-bottom: 5.8px;
            padding-inline: 8px;
            display: flex;
            gap: 2%;
            width: 50%;
            justify-content: center;
            background: #FFFFFF;
        }
    }
    .fact_articles_container{
        .table_container{
            position: relative;
            z-index: 0;
            @media (max-width:768px){
                td{
                   font-size: smaller;
                }
             }         
    
            .translate_text_icon{
                width: 3.8%;
                cursor: pointer;
            }
            
            .no_results_container{
                display: flex;
                flex-direction: column;
                margin-top: 10vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
    
            .dot {
                color: green;
            }
                
            .dot_negative {
                color: red;
            }
                
            .pagination_container{
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 1vw;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}