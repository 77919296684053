@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_draft_container {
  width: 84vw;
  padding: 2vh 2vw 2vh 2vw;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_draft_container .draft_header_bar {
  display: flex;
  gap: 2vw;
  align-items: center;
}
.right_draft_container .draft_header_bar .draft_header {
  font-size: x-large;
  font-weight: bold;
  color: black;
  display: flex;
  gap: 0.5vw;
}
.right_draft_container .draft_header_bar .draft_header img {
  cursor: pointer;
}
.right_draft_container .draft_header_bar .sort_wrapper {
  margin-left: auto;
}
.right_draft_container .draft_header_bar .sort_wrapper .sort_main_container {
  display: flex;
  gap: 0.5vw;
  cursor: pointer;
  position: relative;
}
.right_draft_container .draft_header_bar .sort_wrapper .sort_main_container .sort_tag {
  font-size: large;
  font-weight: bold;
}
.right_draft_container .draft_header_bar .sort_wrapper .sort_main_container .sort_dropdown_logo_close {
  transform: rotate(180deg);
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 6px;
  left: -300%;
  min-width: 12vw;
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_main_category {
  font-size: large;
  padding: 1.4vh 1vw 1.4vh 1vw;
  text-align: center;
  color: #3A3A49;
  background: #F9F9F9;
  border: 1px solid #DBD2D2;
  cursor: pointer;
  display: flex;
  gap: 0.4vw;
  align-items: center;
  justify-content: center;
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_main_category .sort_up_down_logo {
  display: flex;
  flex-direction: column;
  gap: 0.3vh;
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.right_draft_container .draft_header_bar .sort_wrapper .dropdown_main_category_selected {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_draft_container .draft_header_bar .crud_search {
  display: flex;
  gap: 1vw;
  justify-content: center;
  border: 2px solid #E4E4E4;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: rgba(250, 250, 250, 0.81);
  width: 30vw;
}
.right_draft_container .draft_header_bar .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  background: rgba(250, 250, 250, 0.81);
}
.right_draft_container .draft_header_bar .crud_search .crud_add:focus {
  outline: none;
}
.right_draft_container .draft_cards_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Four cards in each row */
  gap: 25px; /* Gap between cards */
  max-height: 80%;
  margin-top: 3vh;
}
.right_draft_container .draft_cards_container .draft_card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  border: 0.5px solid #5DBDE7;
  background: linear-gradient(222.05deg, rgba(255, 255, 255, 0.12) -17.6%, rgba(214, 230, 252, 0.3) 76.29%);
  box-shadow: 4px 15px 31px rgba(28, 112, 200, 0.14);
  border-radius: 22px;
}
.right_draft_container .draft_cards_container .draft_card span {
  font-weight: bold;
}
.right_draft_container .draft_cards_container .draft_card .card_header {
  font-size: larger;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5%;
  color: #000000;
  position: relative;
  display: flex;
  justify-content: center;
}
.right_draft_container .draft_cards_container .draft_card .card_header .draft_rename {
  position: absolute;
  right: 0;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 0.8vh;
  flex: auto;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .draft_article_title {
  display: flex;
  align-items: flex-start;
  gap: 0.5vw;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .draft_article_title img {
  padding-top: 2%;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .draft_article_title span {
  color: #1C70C8;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .view_card_content {
  margin-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .view_card_content img {
  margin-left: auto;
  cursor: pointer;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .view_card_content .draft_type {
  margin-left: auto;
  color: #1C70C8;
  font-size: small;
  background: #E2F0FF;
  border-radius: 24px;
  padding: 3px 12px;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .view_card_content .view_card_content_button {
  color: #064281;
  border-color: #0B56A4;
  background: #FFFFFF;
}
.right_draft_container .draft_cards_container .draft_card .middle_card_content .bottom_content {
  margin-top: auto;
}
.right_draft_container .draft_cards_container .draft_card:hover {
  transition: all 0.2s ease;
  box-shadow: 0px 12px 30px 0px rgba(13, 110, 253, 0.2784313725);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.right_draft_container .draft_pagination_container {
  margin-top: auto;
  display: flex;
  padding-top: 2vh;
  justify-content: center;
  align-items: center;
}
.right_draft_container .draft_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  position: absolute;
  left: 2.5vw;
}
.right_draft_container .draft_pagination_container .display_pages span {
  font-weight: 600;
}
.right_draft_container .draft_pagination_container .nav_container {
  font-family: "Inter";
  display: flex;
  align-items: center;
}
.right_draft_container .draft_pagination_container .createNewDraft {
  cursor: pointer;
  position: absolute;
  right: 2vw;
}

.new_draft_add_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
}
.new_draft_add_popup_content .article_draft_popup_header {
  font-weight: 600;
  font-size: larger;
}
.new_draft_add_popup_content .article_draft_search_bar_container {
  width: 100%;
  margin-top: 10%;
}
.new_draft_add_popup_content .article_draft_search_bar_container .new_draft_inp {
  border: 1px solid #D4D4D4;
  border-radius: 9px;
  outline: none;
  padding: 8px 8px;
  width: 100%;
}
.new_draft_add_popup_content .article_add_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 25%;
  width: 100%;
  justify-content: center;
}
.new_draft_add_popup_content .article_add_draft_button .button_main {
  background: #0E4FA7;
}/*# sourceMappingURL=right_draft.css.map */