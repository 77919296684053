.right_admin_users_panel_container{
    width: 84vw;
    padding: 6vh;
    position: relative;
    .admin_panel_data_container{
        // border: 1px solid black;
        min-width: 40vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        padding: 2%;
        .admin_panel_filters_container{
            margin-bottom: 2vh;
            display: flex;
            align-items: center;
            .toggle_buttons{
                padding-inline: 1vw;
            }
            .search_admin_panel{
                border: 1px solid #E5E5E6;
                border-radius: 6px;
                margin-left: 0.4vw;
                margin-inline: 0.4vw;
                padding: 0.5vw;
            }
            .search_admin_panel:focus{
                outline: none;
            }
            .add_organzation{
                margin-left: auto;
            }
        }
        // .admin_panel_table_container{
        //     td{
        //         padding: inherit;
        //     }
        // }

        .add_organization_container{
            display: flex;
            flex-direction: column;
            gap: 2vh;
            // width: 50%;
            justify-content: center;
            align-items: center;

            form{
                display: contents;
            }

            .new_user_inputs{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                .new_inputs{
                    display: flex;
                    flex-direction: column;
                    label{
                        margin-bottom: 0;
                    }
                }

                .input_organization{
                    border: 1px solid #E5E5E6;
                    border-radius: 6px;
                    padding: 0.5vw;
                }
                .input_organization:focus{
                    outline: none;
                }
            }

            // .common_input_add{
            //     width: 50%;
            // }

            // .input_organization{
            //     border: 1px solid #E5E5E6;
            //     border-radius: 6px;
            //     padding: 0.5vw;
            // }
            // .input_organization:focus{
            //     outline: none;
            // }
            // .add_input_organization{
            //     width: 50%;
            // }
        }
    }
}