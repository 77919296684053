@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.onDemandPdfRightView_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.onDemandPdfRightView_container .circle_top {
  width: 55vw;
  height: 84vh;
  background: #f2f5fc;
  border-radius: 50%;
  position: absolute;
  top: -28vh;
  right: -15vw;
  overflow: hidden;
}
.onDemandPdfRightView_container .circle_bottom {
  width: 34vw;
  height: 34vw;
  height: 34vh;
  border: 5vw solid #5770fe;
  border-radius: 50%;
  position: absolute;
  bottom: -15vw;
  right: -15vw;
  overflow: hidden;
  background-color: rgba(242, 245, 252, 0.7); /* Adjust the transparency */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Adjust the blur amount */
}
.onDemandPdfRightView_container .filter_container {
  display: flex;
}
.onDemandPdfRightView_container .filter_container .filter_content_container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 0.8vw;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .articles {
  font-family: "Inter";
  background-color: rgb(250, 250, 250);
  color: #000000;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
  padding: 0.8vh 0.8vw 0.8vh 0.8vw;
  outline: none;
  border-radius: 11px;
  border: 1px solid rgb(237, 237, 237);
  font-weight: 700;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .articles .articles_dropdown {
  margin-left: 4vw;
  fill: black;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .articles .articles_dropdown_close {
  fill: black;
  margin-left: 4vw;
  transform: rotate(180deg);
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.onDemandPdfRightView_container .filter_container .filter_content_container .articles_category .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.onDemandPdfRightView_container .filter_container .filter_content_container .date_range {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
  background-color: rgb(250, 250, 250);
  margin-left: 2vw;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler {
  font-weight: 700;
  margin-left: 2vw;
  gap: 0.5vw;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler input {
  cursor: pointer;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main {
  background-color: #3B89FE;
  border-radius: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px 4px 12px 4px;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles {
  font-family: "Inter";
  font-size: 1rem;
  color: #ffffff;
  transition: right 1s ease;
  box-shadow: none;
  flex: 1;
  outline: none;
  border-radius: 0;
  padding-top: 0.2px;
  padding-bottom: 0.2px;
  position: relative;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles .toggle_text {
  text-align: left;
  position: relative;
  z-index: 1;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle, .onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:active, .onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:focus {
  border-right: 0.05px solid rgba(255, 255, 255, 0.431372549);
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle, .onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:active, .onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:focus {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle_circle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 3px; /* Adjust the distance from the right edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle_circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px; /* Adjust the distance from the left edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .export_pdf_container {
  margin-left: 2vw;
}
.onDemandPdfRightView_container .filter_container .filter_content_container .export_pdf_container .Export_button {
  cursor: pointer;
}
.onDemandPdfRightView_container .applied_filters_container {
  display: flex;
  margin-top: 2vh;
  width: 70vw;
  gap: 5vw;
}
.onDemandPdfRightView_container .applied_filters_container .expand_collapse {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid rgb(178, 178, 178);
  border-radius: 6px;
  align-items: center;
}
.onDemandPdfRightView_container .applied_filters_container .expand_collapse .back_blue {
  background-color: rgb(49, 122, 231);
}
.onDemandPdfRightView_container .applied_filters_container .expand_collapse .white_icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
}
.onDemandPdfRightView_container .applied_filters_container .expand_collapse .black_icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
}
.onDemandPdfRightView_container .applied_filters_container .expand_collapse .expan, .onDemandPdfRightView_container .applied_filters_container .expand_collapse .collaps {
  cursor: pointer;
  padding: 8px;
  padding-inline: 10px;
  height: 100%;
}
.onDemandPdfRightView_container .applied_filters_container .total_articles {
  font-weight: bold;
  margin-top: auto;
  padding: 1vh;
  font-size: medium;
  background: white;
  border-radius: 6px;
}
.onDemandPdfRightView_container .applied_filters_container .total_articles span {
  font-weight: 600;
  background: rgb(49, 122, 231);
  padding: 6px;
  border-radius: 6px;
  color: #FFFFFF;
}
.onDemandPdfRightView_container .applied_filters_container .date_applied {
  margin-left: auto;
  padding: 2vh;
  border-radius: 2vh;
  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
}
.onDemandPdfRightView_container .applied_filters_container .date_applied .header_date {
  font-weight: 600;
}
.onDemandPdfRightView_container .applied_filters_container .date_applied .date_text {
  margin-top: 1vw;
  color: #3B89FE;
}
.onDemandPdfRightView_container .table_wrapper {
  display: flex;
  margin-top: 2vh;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container {
  width: 100%;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .no_results_container .child_no_results {
  margin: auto;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .arrow {
  height: 33px;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.7vw;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .dot:before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #3B89FE;
  width: 0.8vw;
  height: 2.1vh;
  border: 5px solid #3B89FE;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .view_articles_heatmap {
  position: relative;
  z-index: 0;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .pagination_container {
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  margin-right: auto;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.onDemandPdfRightView_container .table_wrapper .on_demand_table_container .pagination_container .nav_container {
  font-family: "Inter";
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}
.onDemandPdfRightView_container .table_wrapper .quick_scroll {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
}
.onDemandPdfRightView_container .table_wrapper .quick_scroll .bottom {
  position: sticky;
  top: 1.5vh;
  cursor: pointer;
}
.onDemandPdfRightView_container .table_wrapper .quick_scroll .top {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  bottom: 1.5vh;
  cursor: pointer;
}/*# sourceMappingURL=OnDemandPdfRightView.css.map */