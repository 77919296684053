@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');

.right_root_dashboard_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    .root_dashboard_header{
        display: flex;
        align-items: center;
        z-index: 1;
        .home_tag{
            font-size: x-large;
            font-weight: bold;
            color: black;
        }
        .prompt_toggler{
            margin-left: auto;
            .toggler_main_ele{
                display: flex;
                gap: 0.5vw;
            }
            .toggler_left_ele{
                font-weight: 600;
                font-size: larger;
                color: black;
            }
            .toggle_input{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .root_dashboard_filters{
        display: flex;
        flex-direction: column;
        gap: 4vh;
        border: 1px solid #D0E7FE;
        box-shadow: 0px 9px 38px 0px #BDD6FB4F;
        background: linear-gradient(180deg, rgba(226, 239, 252, 0.1512) 0%, rgba(226, 239, 252, 0.378) 84.58%, rgba(226, 239, 252, 0.2457) 91.24%);
        padding: 2.5vh 1vw;
        border-radius: 15px;
        .filter_header{
            display: flex;
            // align-items: left;
            // position: relative;
            margin-inline: 1vw;
            .header{
                // text-align: left;
                color: black;
                font-weight: bold;
                // position: absolute;
                // width: 100%;
                font-size: larger;
            }
            .prompt_toggler{
                margin-left: auto;
                .toggler_main_ele{
                    display: flex;
                    gap: 0.5vw;
                    align-items: center;
                }
                .toggler_left_ele{
                    font-weight: 600;
                    font-size: larger;
                    color: black;
                }
                .toggle_input_switch{
                    display: flex;
                    align-items: center;
                }
                .toggle_input{
                    cursor: pointer;
                }
            }   
        }
        .main_filters_wrapper{
            // padding: 0px 4vw;
            .prompt_container{
                display: flex;
                flex-direction: column;
                gap: 2vh;
                width: 100%;
                .input_prompt{
                    border: 1px solid hsl(0, 0%, 70%);
                    margin: 0px 10vw;
                    border-radius: 4px;
                    outline: None;
                    font-size: 19.8px;
                    height: auto;
                    padding-top: 5.8px;
                    padding-bottom: 5.8px;
                    padding-inline: 8px;
                }
                .input_prompt::placeholder{
                    font-weight: 100;
                    font-size: medium;
                    color: #BDBDBD;
                }
                .input_prompt:focus{
                    border: 2.5px solid #3B89FE;
                    outline: none;
                }
                // .prompt_submit_containers{
                    
                // }
            }
            .filters_new_container{
                display: flex;
                flex-direction: column;
                gap: 1vh;
                .filter_layer_1{
                    padding: 0px 1vw;
                    display: flex;
                    gap: 2%;
                    .category_new, .date_range_new{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        span{
                            font-weight: 600;
                        }
                        .date_range_value_new, .category_value_new{
                            flex: 1;
                            z-index: 2;
                        }
                    }
                    .category_new{
                        gap: 13%;
                        z-index: 5;
                    }
                    .date_range_new{
                        gap: 2%;
                    }
                }
                .filter_layer_2{
                    padding: 2vh 1vw;
                    display: flex;
                    flex-direction: column;
                    gap: 2vh;
                    border-radius: 5px;
                    background: linear-gradient(90.94deg, #E9F3FC 0.1%, #E3F1FF 99.2%);
                    span{
                        font-weight: 600;
                    }
                    .keywords_new{
                        align-items: center;
                        display: flex;
                        gap: 6.8%;
                        .keywords_value_new{
                            display: flex;
                            gap: 2%;
                            width: 100%;
                            z-index: 4;
                            .title_body_value_new{
                                flex: 2;
                                display: flex;
                                align-items: center;
                                // gap: 0.5vw;
                                position: relative;
                                padding: 0.5vh 1vw;
                                cursor: pointer;
                                border: 1px solid #D2DBE6;
                                border-radius: 6px;
                                background: #FAFCFF;
                                .keywords_main_text{
                                    color: #000000;
                                }
                                .keywords_dropdown_close{
                                    transform: rotate(180deg)
                                }
                                .keywords_dropdown_close,.keywords_dropdown{
                                    filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
                                    margin-left: auto;
                                }
                                .dropdown_container{
                                    position: absolute;
                                    top: calc(100% + 10px); /* Adjust the top value as needed */
                                    left: 0;
                                    background: white;
                                    border: 1px solid #ccc;
                                    padding: 10px;
                                    z-index: 6;
                                    max-height: 30vh; /* Adjust the maximum height as needed */
                                    overflow-y: auto;
                                    box-shadow: 0px 20px 50px 0px #0000001a;
                                    border-radius: 6px;
                                    width: 100%;
                                }
                                .dropdown_container::-webkit-scrollbar {
                                    width: 0;
                                    background: transparent;
                                }
                                .dropdown_main_category{
                                    padding: 1vh 1vw 1vh 1vw;
                                    text-align: center;
                                    color: #3B89FE;
                                    background-color: #ffffff;
                                    border: 1px solid #F1F1F1;
                                    cursor: pointer;
                                }
                                .dropdown_main_category:hover{
                                    box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                                }
                                .dropdown_main_category:active{
                                    box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                                }
                            }
                            .keywords_root_value_new{
                                flex: 8;
                            }
                        }
                    }
                    .conditions_new{
                        align-items: center;
                        display: flex;
                        gap: 2.7%;
                        span{
                            background: #3B89FE38;
                            border-radius: 16px;
                            padding-inline: 1vw;
                        }
                        .conditions_value_new{
                            display: flex;
                            gap: 5%;
                            justify-content: space-between;
                            width: 100%;
                            .conditions_value_new_main{
                                display: flex;
                                gap: 2%;
                                width: 100%;
                                background: #FAFCFF;
                                border: 0.5px solid #2275F04A;
                                border-radius: 11px;
                                padding-inline: 0.5vw;
                                align-items: center;
                                .left_new{
                                    justify-content: center;
                                }
                                .right_new{
                                    justify-content: center;
                                }
                                .radio_wrapper{
                                    display: flex;
                                    gap: 5%;
                                    flex: 1;
                                }
                            }
                            .condition_1_value_new{
                                flex:2  ;
                            }   
                            .condition_2_value_new{
                                flex: 5;
                            }
                            .condition_3_value_new{
                                flex: 5;
                            }
                        }
                    }
                }
                .filter_layer_3{
                    padding: 0px 1vw;
                    display: flex;
                    gap: 2%;
                    .domains_new, .languages_new{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        span{
                            font-weight: 600;
                        }
                        .domains_value_new, .languages_value_new{
                            flex: 1;
                            z-index: 3;
                        }
                    }
                    .domains_new{
                        gap: 15%;
                        z-index: 3;
                    }
                    .languages_new{
                        gap: 2%;
                        z-index: 3;
                    }
                }
                .filter_layer_4{
                    padding: 0px 1vw;
                    display: flex;
                    gap: 2%;

                    .map{
                        width: 100%;
                    }
                    .leaflet-container{
                        // width: 316px;
                        height: 174px;                
                    }

                    .sentiment_new, .location_new{
                        flex: 1;
                        display: flex;
                        span{
                            font-weight: 600;
                        }
                        .location_value_new, .sentiment_value_new{
                            flex: 1;
                            z-index: 2;
                        }
                    }
                    .sentiment_new{
                        gap: 12%;
                    }
                    .sentiment_label_new, .location_label_new{
                        height: fit-content;
                        padding: 6px 0px;
                    }
                    .location_new{
                        gap: 3.5%;
                        .location_popup_input{
                            // border: 1px solid hsl(0, 0%, 50%);
                            display: flex;
                            flex-direction: column;
                            gap: 6px;
                            .verify_location_inputs_container{
                                display: flex;
                                gap: 6px;
                                .location_inputs{
                                    border-radius: 6px;
                                    outline: none;
                                    padding-inline: 0.5vw;
                                    border-color: hsl(0, 0%, 50%);
                                    width: 90%;
                                }
                                // .verify_location_btn{
                                //     width: 10%;
                                // }
                            }
                            .verify_coordinates_inputs_container{
                                display: flex;
                                gap: 6px;
                                .coordinates_inputs{
                                    border-radius: 6px;
                                    outline: none;
                                    padding-inline: 0.5vw;
                                    border-color: hsl(0, 0%, 50%);
                                    width: 45%;
                                }
                                // .verify_location_btn{
                                //     width: 10%;
                                // }
                            }
                            .radius_selector{
                                display: flex;
                                gap: 6px;
                                align-items: center;
                                justify-content: center;
                            }
                            .Map_approval_button{
                                .approval_locaion_wrapper{
                                    display: flex;
                                    margin-top: 1vh;
                                    .approval_tick{
                                        width: 3%;
                                    }
                                    .approval_locaion_wrapper{
                                        margin-left: auto;
                                        background: #114DA8;
                                    }
                                }
                            }
                        }
                    }
                }
                .filter_layer_5{
                    padding: 0px 1vw;
                    .unique_articles_new{
                        display: flex;
                        align-items: center;
                        gap: 3%;
                        span{
                            font-weight: 600;
                        }
                    }
                }
            }
            .left_root_filters{
                width: 70%;
                display: grid;
                grid-template-columns: 20% 1fr;
                grid-row-gap: 1.2vh;
                .filter_field{
                    padding-top: 0.5vh;
                    color: #000000;
                    font-weight: 600;
                }
                .filter_value{
                    .filter_value_items{
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        border-radius: 2px;
                        background: #FFFFFF;
                    }
                    .filter_value_items_text{
                        color: rgba(45, 45, 45, 1);
                        font-weight: 600;
                    }
                    .view_filter_field_value_text{
                        color: rgba(45, 45, 45, 1);
                        font-weight: 600;
                    }
                    .view_filter_field_value_selected{
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        border-radius: 2px;
                        background: #FFFFFF;
                        padding: 0.5vh 1vw;
                    }
                    .view_filter_field_value_not_selected{
                        border-radius: 2px;
                        background: #FFFFFF;
                        padding: 0.5vh 1vw;
                        border-bottom: 1.25px solid rgba(128, 146, 162, 1)
                    }
                }

                .dropdown_container{
                    position: absolute;
                    top: calc(100% + 10px); /* Adjust the top value as needed */
                    left: 0;
                    background: white;
                    border: 1px solid #ccc;
                    padding: 10px;
                    z-index: 6;
                    max-height: 30vh; /* Adjust the maximum height as needed */
                    overflow-y: auto;
                    box-shadow: 0px 20px 50px 0px #0000001a;
                    border-radius: 6px;
                    width: 100%;
                }
                .dropdown_container::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                }
                .dropdown_main_category{
                    padding: 1vh 1vw 1vh 1vw;
                    text-align: center;
                    color: #3B89FE;
                    background-color: #ffffff;
                    border: 1px solid #F1F1F1;
                    cursor: pointer;
                }
                .dropdown_main_category:hover{
                    box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                }
                .dropdown_main_category:active{
                    box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                }
                .category_filter_value{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 1%;
                    height: fit-content;
                    .filter_category_value_items_text{
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        border-radius: 2px;
                    }

                    .articles_category{
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 0.5vh 1vw;
                        cursor: pointer;
                        // border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        // border-radius: 2px;
                        background: #FFFFFF;
                        .articles_main_text{
                            color: #000000;
                        }
                        .articles_dropdown_close{
                            transform: rotate(180deg)
                        }
                        .articles_dropdown_close,.articles_dropdown{
                            filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
                            margin-left: auto;
                        }
                       
                    }
                    .news_corpus_category{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0.5vh 1vw;
                        cursor: pointer;
                        background: #FFFFFF;
                    }
                }

                .view_domain_keywords_field{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 0.5vw;
                    .view_domain_keywords{
                        padding: 2px;
                        padding-inline: 8px;
                        background: rgba(128, 146, 162, 0.5);
                        border-radius: 2px;
                    }
                    .no_multi_filter_text{
                        padding: 2px;
                        padding-inline: 8px;
                        background: rgba(128, 146, 162, 0.5);
                        border-radius: 2px;
                    }
                }

                .keywords_filter_value{
                    display: grid;
                    grid-template-columns: 20% 1fr;
                    gap: 1%;
                    .keywords_selections{
                        display: flex;
                        align-items: center;
                        // gap: 0.5vw;
                        position: relative;
                        padding: 0.5vh 1vw;
                        cursor: pointer;
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        border-radius: 2px;
                        background: #FFFFFF;
                        .keywords_main_text{
                            color: #000000;
                        }
                        .keywords_dropdown_close{
                            transform: rotate(180deg)
                        }
                        .keywords_dropdown_close,.keywords_dropdown{
                            filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
                            margin-left: auto;
                        }
                    }
                    // .view_domain_keywords_field{
                    //     display: flex;
                    //     align-items: center;
                    //     flex-wrap: wrap;
                    //     gap: 0.5vw;
                    //     .view_domain_keywords{
                    //         padding: 2px;
                    //         background: rgba(128, 146, 162, 0.5);
                    //         border-radius: 2px;
                    //     }
                    // }
                }

                .keywords_filter_value_view_mode{
                    display: flex;
                    align-items: flex-start;
                    gap: 1%;
                    .view_keywords_selections{
                        flex: 2;
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding: 0.5vh 1vw;
                        cursor: pointer;
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        border-radius: 2px;
                        background: #FFFFFF;
                        .selections_text{
                            padding: 2px;
                        }
                        .keywords_main_text{
                            color: #000000;
                        }
                        .keywords_dropdown_close{
                            transform: rotate(180deg)
                        }
                        .keywords_dropdown_close,.keywords_dropdown{
                            filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
                            margin-left: auto;
                        }
                    }
                    .view_keywords_field{
                        flex: 8;
                    }
                }

                .conditions_filter_value{
                    .toggle_filters, .toggle_filters_view{
                        margin-top: 2vh;
                        display: flex;
                        gap: 2vw;
                        .all_togglers_wrapper{
                            display: flex;
                            .toggler_main_ele{
                                margin-left: 0.55vw;
                            }
                            .toggler_left_ele, .toggler_right_ele{
                                font-weight: bold;
                            }
                        }
                    }
                    .toggle_filters{
                        justify-content: space-between;
                    }
                }
            }
            .right_root_filters{
                // border: 1px solid;
                width: 30%;
                padding: 6px;

                .map{
                    width: 100%;
                }
                .leaflet-container{
                    // width: 316px;
                    height: 174px;                
                }

                .edit_mode_location_filter{
                    .Location_tag{
                        padding-top: 0.5vh;
                        color: #000000;
                        font-weight: 600;
                    }

                    .location_popup_input{
                        // border: 1px solid hsl(0, 0%, 50%);
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        .verify_location_inputs_container{
                            display: flex;
                            gap: 6px;
                            .location_inputs{
                                border-radius: 6px;
                                outline: none;
                                padding-inline: 0.5vw;
                                border-color: hsl(0, 0%, 50%);
                                width: 90%;
                            }
                            // .verify_location_btn{
                            //     width: 10%;
                            // }
                        }
                        .verify_coordinates_inputs_container{
                            display: flex;
                            gap: 6px;
                            .coordinates_inputs{
                                border-radius: 6px;
                                outline: none;
                                padding-inline: 0.5vw;
                                border-color: hsl(0, 0%, 50%);
                                width: 45%;
                            }
                            // .verify_location_btn{
                            //     width: 10%;
                            // }
                        }
                        .radius_selector{
                            display: flex;
                            gap: 6px;
                            align-items: center;
                            justify-content: center;
                        }
                        .Map_approval_button{
                            .approval_locaion_wrapper{
                                display: flex;
                                .approval_tick{
                                    width: 3%;
                                }
                                .approval_locaion_wrapper{
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                    // .map{
                    //     width: 100%;
                    // }
                    // .leaflet-container{
                    //     // width: 316px;
                    //     height: 174px;                
                    // }
                }
                .view_mode_location_filter{
                    display: flex;
                    flex-direction: column;
                    .Location_tag{
                        padding-top: 0.5vh;
                        color: #000000;
                        font-weight: 600;
                    }
                    .location_not_applied_header{
                        background: #FFFFFF;
                        color: rgba(128, 146, 162, 1);
                        border-bottom: 1.25px solid rgba(59, 137, 254, 1);
                        padding: 6px;
                        text-align: center;
                        // display: flex;
                    }
                    .location_applied_header{
                        background: #FFFFFF;
                        color: rgba(128, 146, 162, 1);
                        padding: 6px;
                        text-align: center;
                    }
                    .not_selected_map{
                        background: #FFFFFF;
                        position: relative;
                        .no_location_map_text{
                            background: #FFFFFF;
                            font-weight: 600;
                            padding: 6px;
                            text-align: center;
                            position: absolute;
                            width: 100%;
                            top: 40%;
                        }
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    .root_dashboard_article_analytics_tabs_container{
        display: flex;
        // padding-left: 1vw;
        padding-top: 2vh;
        .left_tabs{
            display: grid;
            grid-template-columns: 1fr 1fr;
            text-align: center;
            gap: 2.5vw;
            z-index: 1;
            .tabs_value_items_text{
                font-size: larger;
                font-weight: 600;
            }
            .left_tabs_content{
                cursor: pointer;
                padding: 0.5vh 0px;
            }
            .left_tab_highlight{
                // border-bottom: 2px solid #48A1FE;
                // border-width: 3px;
                color: black;
                position: relative;
                img {
                    filter: brightness(0) saturate(100%);
                }
            }
            .left_tab_highlight::before{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 35%;
                    width: 50%; /* Adjust this value to control the length of the border */
                    height: 2px; /* Border thickness */
                    background-color: #48A1FE; /* Border color */
            }
            .left_tab_normal{
                color: #797979;
            }
        }
        .right_tabs{
            margin-left: auto;
        }
    }
}

.article_draft_popup_content_from_root{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    justify-content: center;
    .article_draft_popup_header{
        font-weight: 600;
        font-size: large;
    }
    .root_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 25%;
        width: 100%;
        justify-content: center;  
        .button_main{
            background: #0E4FA7;
        }  
        .cancel{
            background: #C8C8C8;
        }
    }
}