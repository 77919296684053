.map-component{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
}
.map{
    border: 2px solid rgb(59, 137, 254);
    border-radius: 5px;
}

.popup_container{
    p{
        margin: 0px;
    }
}