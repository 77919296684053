.root_articles_container .table_container {
  position: relative;
  z-index: 0;
}
@media (max-width: 768px) {
  .root_articles_container .table_container td {
    font-size: smaller;
  }
}
.root_articles_container .table_container .translate_text_icon {
  width: 3.8%;
  cursor: pointer;
}
.root_articles_container .table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}
.root_articles_container .table_container .no_results_container .child_no_results {
  margin: auto;
}
.root_articles_container .table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.root_articles_container .table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.root_articles_container .table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.root_articles_container .table_container .dot {
  color: green;
}
.root_articles_container .table_container .dot_negative {
  color: red;
}
.root_articles_container .table_container .pagination_container {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.root_articles_container .table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: absolute;
  left: 1vw;
}
.root_articles_container .table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.root_articles_container .table_container .pagination_container .nav_container {
  font-family: "Inter";
  display: flex;
  align-items: center;
}/*# sourceMappingURL=rootArticles.css.map */