.page_animations_container{
    .circle_top{
        width: 55vw;
        height: 84vh;
        background: #f2f5fc;
        border-radius: 50%;
        position: absolute;
        top: -28vh;
        right: -15vw;
        overflow: hidden;
    }
    .circle_bottom {
        width: 34vw;
        // height: 50vw;
        height: calc(60vw * (100vh / 100vw));
        border: 5vw solid #5770fe;
        border-radius: 57%;
        position: absolute;
        bottom: -14vw;
        right: -15vw;
        overflow: hidden;
    }
}