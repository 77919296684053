@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_report_gen_tracker_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_report_gen_tracker_container .main_header {
  font-weight: 600;
  font-size: x-large;
}
.right_report_gen_tracker_container .report_gen_cards_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  max-height: 80%;
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card {
  display: flex;
  flex-direction: column;
  padding: 10px 25px 25px 25px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow: 0 3px 10px rgba(13, 110, 253, 0.2784313725);
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card span {
  font-weight: bold;
  margin-left: 0.5vw;
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card .card_header {
  font-size: larger;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5%;
  color: #0d6efd;
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card .middle_card_content {
  margin-top: auto;
  display: flex;
  gap: 1vw;
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card .middle_card_content .middle_card_content_left, .right_report_gen_tracker_container .report_gen_cards_container .report_gen_card .middle_card_content .middle_card_content_right {
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
}
.right_report_gen_tracker_container .report_gen_cards_container .report_gen_card:hover {
  transition: all 0.2s ease;
  box-shadow: 0px 12px 30px 0px rgba(13, 110, 253, 0.2784313725);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.right_report_gen_tracker_container .report_gen_table_table_and_content {
  display: flex;
  margin-top: 4vh;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container {
  width: 100%;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_results_container .child_no_results {
  margin: auto;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .table_btn {
  min-width: 6vw;
  align-items: center;
  display: flex;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .report_type_name {
  margin-left: 0.5vw;
  padding: 4px;
  border-radius: 30px;
  background-color: rgba(173, 173, 173, 0.6509803922);
  text-align: center;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .no_report_type {
  padding: 1vh;
  border-radius: 30px;
  background-color: #FEEADB;
  text-align: center;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .report_type {
  padding: 1vh;
  border-radius: 30px;
  background-color: rgba(85, 172, 245, 0.0901960784);
  text-align: center;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .pagination_container {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  /* top-left | top-right | bottom-right | bottom-left */
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
  position: relative;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  position: absolute;
  left: 1vw;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.right_report_gen_tracker_container .report_gen_table_table_and_content .report_gen_table_table_container .pagination_container .nav_container {
  font-family: "Inter";
  width: auto;
  height: auto;
}
.right_report_gen_tracker_container .report_gen_pagination_container {
  margin-top: auto;
  display: flex;
  padding: 2vh;
  justify-content: center;
  align-items: center;
}
.right_report_gen_tracker_container .report_gen_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  position: absolute;
  left: 2.5vw;
}
.right_report_gen_tracker_container .report_gen_pagination_container .display_pages span {
  font-weight: 600;
}
.right_report_gen_tracker_container .report_gen_pagination_container .nav_container {
  font-family: "Inter";
  display: flex;
  align-items: center;
}

.scheduled_reports_popup_content {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.scheduled_reports_popup_content span {
  font-weight: bold;
  margin-left: 0.5vw;
}
.scheduled_reports_popup_content .middle_card_content_child_keywords {
  display: flex;
  flex-wrap: wrap;
}
.scheduled_reports_popup_content .middle_card_content_child_keywords .middle_card_content_child_keyword {
  padding: 0.5vh 1vh 0.5vh 1vh;
  border-radius: 5px;
  background-color: #DBEBFE;
  text-align: center;
  font-weight: bold;
  margin-left: 0.3vw;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scheduled_reports_popup_content .card_header {
  font-size: larger;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3%;
  color: #0d6efd;
}
.scheduled_reports_popup_content .middle_card_content {
  margin-top: auto;
  display: flex;
  gap: 1vw;
}
.scheduled_reports_popup_content .middle_card_content .middle_card_content_left, .scheduled_reports_popup_content .middle_card_content .middle_card_content_right {
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
}
.scheduled_reports_popup_content .middle_card_content .middle_card_content_left {
  width: 25%;
}
.scheduled_reports_popup_content .middle_card_content .middle_card_content_right {
  width: 75%;
}/*# sourceMappingURL=rightReportGenTracker.css.map */