  // html, body {
  //     width: 100%;
  //     height: 100%;
  //     font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  //     color: rgba(0,0,0,.87);
  //   }
  // body, html {
  //     margin: 0;
  //     padding: 0;
  //   }
    
  //   #root {
  //     height: 100%;
  //     width: 100%;
  //   }
    
  //   .full-width-height {
  //     height: 100vh;
  //     width: 100%;
  //   }
    
    .container {
      // width: calc(100% - 32px);
      height: calc(100% - 200px);
      // padding: 16px;
      padding-top: 0;
      cursor: pointer;
    }

  //   html {
  //     font-family: system-ui, sans-serif;
  //     font-size: 1.25rem;
  //   }
    
  //   body {
  //     margin: 0;
  //   }
  .full-width-height.container {
      width: 100%; /* Set the width of the container */
      /* Other CSS styles for the container */
    }

    
    .no-margin {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }
    
    .display-flex {
      display: flex;
    }
    
    .fill {
      flex: 1;
    }
    
    .center {
      text-align: center;
    }
    
  //   .mt16 {
  //     margin-top: 16px;
  //   }
    
    .tooltip_container{
      padding: 1vh;
      border-radius: 1px solid black;
      // background:'';
    }

    g{
      transform: none;
    }
  //  g >.rsm-zoomable-group{
  //     position: absolute;
  //     display: block;
  //  }