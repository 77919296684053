.unique_reset_container {
  background: linear-gradient(219.39deg, #3B89FE 25.64%, #102A5F 132.49%);
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}
.unique_reset_container .corner {
  min-width: 10vw;
  min-height: 20vh;
  position: absolute;
}
.unique_reset_container .top-right {
  top: 0;
  right: 0;
  background: #FFFFFF;
  opacity: 0.2;
  border-radius: 0vh 0vh 0vh 1.8vh;
}
.unique_reset_container .bottom-left {
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  opacity: 0.2;
  border-radius: 0vh 1.8vh 0vh 0vh;
}
.unique_reset_container .center {
  padding: 5vh;
  min-width: 28vw;
  min-height: 35vh;
  position: absolute;
  top: 50%; /* move to the center vertically */
  left: 50%; /* move to the center horizontally */
  transform: translate(-50%, -50%); /* adjust the position */
  background: #FFFFFF;
  text-align: center;
  border: 2px solid #DBDBDB;
  box-shadow: 34px -34px 0px 0px rgba(219, 219, 219, 0.3215686275), 17px -17px 2px 0px rgba(219, 219, 219, 0.3215686275);
  border-radius: 2.8vh;
}
.unique_reset_container .center .header_unique_reset {
  font-size: 35px;
  color: #3B89FE;
  font-weight: bold;
  margin-bottom: 1vh;
}
.unique_reset_container .center .reset_success_logo {
  width: 26%;
  margin-bottom: 1vh;
}
.unique_reset_container .center .sub_header_unique_reset {
  font-size: 22px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 1vh;
}
.unique_reset_container .center .sub_header_unique_reset_content {
  font-size: 16px;
  color: #7C7C7A;
  font-weight: bold;
  width: 23vw;
  margin-bottom: 4vh;
}
.unique_reset_container .center .unique_reset_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  padding: 1vh;
}
.unique_reset_container .center .unique_reset_form_container .form-group {
  min-width: 20vw;
  text-align: left;
  position: relative;
}
.unique_reset_container .center .unique_reset_form_container .form-group .eye_icon_container {
  position: absolute;
  right: 2vh;
  top: 55%;
  transform: translateY(50%);
  cursor: pointer;
}
.unique_reset_container .center .unique_reset_form_container .form-group label {
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.8vh;
  font-weight: 600;
}
.unique_reset_container .center .unique_reset_form_container .form-group .form-control {
  min-height: 7vh;
  background: #F6F6F6;
  border: none;
}
.unique_reset_container .center .unique_reset_form_container .form-group input {
  font-weight: bold;
  font-size: 18px;
}
.unique_reset_container .center .unique_reset_form_container .error_container {
  color: #ff0000;
  font-weight: 600;
  display: none;
}/*# sourceMappingURL=uniqueReset.css.map */