@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_draft_container{
    width: 84vw;
    // height: 100vh;
    padding: 2vh 2vw 2vh 2vw;
    // padding-top: 4vh;
    // padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    // gap: 2vh;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    
    .draft_header_bar{
        display: flex;
        gap: 2vw;
        align-items: center;
        // z-index: 1;
        // backdrop-filter: blur(4px);
        .draft_header{  
            font-size: x-large;
            font-weight: bold;
            color: black;
            display: flex;
            gap: 0.5vw;
            img{
                cursor: pointer;
            }
        }
        .sort_wrapper{
            // position: relative;
            margin-left: auto;
            .sort_main_container{
                display: flex;
                gap: 0.5vw;
                cursor: pointer;
                position: relative;
                .sort_tag{
                    font-size: large;
                    font-weight: bold;                
                }
                .sort_dropdown_logo_close{
                    transform: rotate(180deg)
                }
            }
            .dropdown_container{
                position: absolute;
                top: calc(100% + 10px); /* Adjust the top value as needed */
                left: 0;
                background: white;
                border: 1px solid #ccc;
                // padding: 10px;
                // z-index: 1;
                max-height: 30vh; /* Adjust the maximum height as needed */
                overflow-y: auto;
                box-shadow: 2px 4px 4px 0px #0000001F;
                border-radius: 6px;
                left: -300%;
                min-width: 12vw;
            }
            .dropdown_container::-webkit-scrollbar {
                width: 0;
                background: transparent;
              }
            .dropdown_main_category{
                font-size: large;
                padding: 1.4vh 1vw 1.4vh 1vw;
                text-align: center;
                color: #3A3A49;
                background: #F9F9F9;
                border: 1px solid #DBD2D2;
                cursor: pointer;
                display: flex;
                gap: 0.4vw;
                align-items: center;
                justify-content: center;
                .sort_up_down_logo{
                    display: flex;
                    flex-direction: column;
                    gap: 0.3vh;
                }
            }
            .dropdown_main_category:hover{
                box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
            }
            .dropdown_main_category:active{
                box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            }
            .dropdown_main_category_selected{
                box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
            }
        }
        .crud_search{            
            display: flex;
            gap: 1vw;
            justify-content: center;
            border: 2px solid #E4E4E4;
            border-radius: 9px;
            padding: 1vh 1vw 1vh 1vw;
            background: rgba(250, 250, 250, 0.81);
            width: 30vw;
            .crud_add{
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
                border: none;
                background: rgba(250, 250, 250, 0.81);
            }
            .crud_add:focus{
                outline: none;
            }
        }
    }
    
    .draft_cards_container{
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Four cards in each row */
        gap: 25px; /* Gap between cards */
        max-height: 80%;
        margin-top: 3vh;
        // z-index: 1;
        // backdrop-filter: blur(4px);
        // margin-bottom: 10%;
        .draft_card{
            cursor: pointer;
            // width: 20%;
            display: flex;
            flex-direction: column;
            padding: 25px 15px;
            // border: 1px solid rgba(0,0,0,.125);
            border: 0.5px solid #5DBDE7;
            background: linear-gradient(222.05deg, rgba(255, 255, 255, 0.12) -17.6%, rgba(214, 230, 252, 0.3) 76.29%);
            box-shadow: 4px 15px 31px rgba(28, 112, 200, 0.14);
            border-radius: 22px;
            // box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            span{
                font-weight: bold;
            }
            .card_header{
                font-size: larger;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5%;
                color: #000000;
                position: relative;
                display: flex;
                justify-content: center;
                .draft_rename{
                    position: absolute;
                    right: 0;
                }
            }
            .middle_card_content{
                margin-top : auto ;
                display: flex;
                flex-direction: column;
                gap: 0.8vh;
                flex: auto;
                .draft_article_title{
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5vw;  
                    img{
                        padding-top: 2%;
                    }
                    span{
                        color: #1C70C8;
                    }              
                }
                .view_card_content{
                    margin-top: 1%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin-left: auto;
                        cursor: pointer;
                    }
                    .draft_type{
                        margin-left: auto;
                        color: #1C70C8;
                        font-size: small;
                        background: #E2F0FF;
                        border-radius:24px;
                        padding: 3px 12px;
                    }
                    .view_card_content_button{
                        color: #064281;
                        border-color: #0B56A4;
                        background: #FFFFFF;
                    }
                }
                .bottom_content{
                    margin-top: auto;
                }
            }
        }
        .draft_card:hover{
            transition: all 0.2s ease;
            // cursor: pointer;
            // box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 12px 30px 0px #0d6efd47;
            transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    
    .draft_pagination_container{
        margin-top: auto;
        display: flex;
        padding-top: 2vh;
        justify-content: center;
        align-items: center;
        // z-index: 1;
        // position: relative;
        .display_pages{
            font-family: 'Inter';
            display: flex;
            // align-items: center;
            position: absolute;
            left: 2.5vw;
            span{
                font-weight: 600;       
            }
        }
        .nav_container{
            // margin-left: 12vw;
            font-family: 'Inter';
            // font-weight: 600;
            display: flex;
            align-items: center;
        }
        .createNewDraft{
            cursor: pointer;
            position: absolute;
            right: 2vw;
        }
    }       
}

.new_draft_add_popup_content{
    // border: 1px solid;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    .article_draft_popup_header{
        font-weight: 600;
        font-size: larger;
    }
    .article_draft_search_bar_container{
        width: 100%;
        margin-top: 10%;
        .new_draft_inp{
            border: 1px solid #D4D4D4;
            border-radius: 9px;  
            outline: none; 
            padding: 8px 8px;
            width: 100%;
        }

    }
    .article_add_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 25%;
        width: 100%;
        justify-content: center;    
        .button_main{
            background: #0E4FA7;
        }
    }
}