@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_report_gen_articles_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_report_gen_articles_container .filter_container {
  display: flex;
}
.right_report_gen_articles_container .filter_container .filter_content_container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 2vw;
  position: relative;
}
.right_report_gen_articles_container .filter_container .filter_content_container .rep_gen_header {
  font-weight: 600;
  font-size: x-large;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter {
  display: flex;
  margin-left: auto;
  gap: 15%;
  cursor: pointer;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .filter_title {
  font-weight: 600;
  font-size: large;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main {
  position: absolute;
  top: 110%; /* Position below the parent div */
  right: 0;
  width: 50%; /* Full width of the parent */
  background-color: #F9F9F9; /* Sample background color */
  z-index: 2; /* Ensure a higher z-index for it to appear over other elements */
  border: 1px solid #B2D5FA;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .header_section {
  padding: 5px 10px 2vh 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E3E3E3;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .header_section .header_title {
  font-weight: 700;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .header_section .cross_modal {
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report {
  display: flex;
  border-bottom: 1px solid #E3E3E3;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .left_main_filters_report {
  display: flex;
  flex-direction: column;
  border: 1px solid #E9E9E9;
  background: #F4F3F3;
  box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.06);
  flex: 3;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .left_main_filters_report .indi_filter {
  padding: 15px 20px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .left_main_filters_report .indi_filter .selected_filter_temporary {
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .left_main_filters_report .highlight_indi_filter {
  color: #1C5BB9;
  background: #FFFFFF;
  border-left: 3px solid #1C5BB9;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .left_main_filters_report .indi_filter:hover {
  background: #FFFFFF;
  border-left: 3px solid #1C5BB9;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report {
  padding: 10px;
  flex: 7;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_1 {
  display: flex;
  justify-content: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_1 .filter_value_1_input {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_2 {
  display: flex;
  justify-content: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_2 .filter_value_2_input {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 100%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_selector {
  display: flex;
  gap: 10%;
  background: #E8F3FF;
  border-radius: 6px;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_selector .search_types {
  flex: 1;
  display: flex;
  gap: 5%;
  align-items: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_selector .selector_text {
  font-weight: 700;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_selector .selector_text_highlight {
  color: #11569F;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_selector .selector_text_no_highlight {
  color: #989898;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_filters_selections {
  display: flex;
  gap: 2%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_filters_selections .search_filters_selections_first {
  flex: 4;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_filters_selections .search_filters_selections_second {
  flex: 6;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_keyword_conditions {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_keyword_conditions .header_condition {
  font-weight: bold;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_keyword_conditions .conditions_main {
  display: flex;
  flex-direction: column;
  gap: 2.5vh;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_keyword_conditions .conditions_main .condition_wrapper {
  display: flex;
  gap: 2%;
  width: 80%;
  background: #FAFCFF;
  border: 0.5px solid rgba(34, 117, 240, 0.2901960784);
  border-radius: 11px;
  padding-inline: 1.5vw;
  align-items: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .search_keyword_conditions .conditions_main .condition_wrapper .radio_wrapper {
  display: flex;
  gap: 5%;
  flex: 1;
  color: #2D2D2D;
  font-weight: 600;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords {
  display: flex;
  flex-direction: column;
  gap: 1.2vh;
  height: 100%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_search {
  display: flex;
  gap: 1vw;
  justify-content: center;
  border: 2px solid #E5E5E6;
  border-radius: 6px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_search .crud_add:focus {
  outline: none;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .edit_button_profile, .right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .edit_button_profile:focus, .right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .cross_button_profile:focus {
  outline: none;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .profile_edit {
  width: 36%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .concern_cell {
  padding: 4px;
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .concern_cell:focus {
  outline: None;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .unique_checkbox {
  cursor: pointer;
  padding: 6px;
  width: 2.7rem;
  height: 2.4vh;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .crud_table_org_keywords .unique_checkbox:hover {
  background-color: rgba(25, 118, 210, 0.04);
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .nav_container {
  justify-content: center;
  display: flex;
  gap: 2%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .selected_count {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .selected_count .total_selected_display {
  font-weight: 700;
  border-radius: 9px;
  border: 1px solid #EDEDED;
  background: linear-gradient(182deg, #EEF7FF -54.29%, #E9ECFE 142.37%);
  padding: 3px 6px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .selected_count .total_selected_display span {
  color: #0F69E3;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_3 .crud_org_keywords .selected_count .reset_feature {
  background: #FFFFFF;
  color: black;
  border: 1px solid #0F69E3;
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new {
  flex: 1;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .select_location_dropdown {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .verify_location_inputs_container {
  display: flex;
  gap: 6px;
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .verify_location_inputs_container .location_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  flex: 8;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .verify_coordinates_inputs_container {
  display: flex;
  gap: 6px;
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .verify_coordinates_inputs_container .coordinates_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  width: 45%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .verify_states_inputs_container {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .radius_selector {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .map {
  width: 100%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .leaflet-container {
  height: 174px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .Map_approval_button {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .Map_approval_button .approval_locaion_wrapper {
  display: flex;
  margin-top: 1vh;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_tick {
  width: 3%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_4 .location_value_new .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_locaion_wrapper {
  margin-left: auto;
  background: #114DA8;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_5 {
  display: flex;
  justify-content: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_5 .filter_value_5_input {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_6 {
  display: flex;
  justify-content: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_6 .filter_value_6_input {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_7 {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_7 .filter_value_7_value_1 {
  display: flex;
  gap: 2%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_7 .filter_value_7_value_1 span {
  font-weight: 600;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_7 .filter_value_7_value_2 {
  border-radius: 6px;
  border: 0.5px solid #238FFF;
  background: #FAFCFF;
  padding: 4px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_7 .filter_value_7_value_2 span {
  color: #1C70C8;
  font-weight: 600;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_8 {
  display: flex;
  justify-content: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .main_filters_report .right_main_filters_report .filter_value_8 .filter_value_8_input {
  width: 80%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .cancel_apply_filters {
  display: flex;
  padding: 10px 25px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .cancel_apply_filters .buttons_container {
  display: flex;
  gap: 1.5vw;
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .cancel_apply_filters .cancel {
  border: 1px solid #0E4FA7;
  color: #000000;
  background-color: #FFFFFF;
}
.right_report_gen_articles_container .filter_container .filter_content_container .report_gen_filter .absolute_filters_main .cancel_apply_filters .apply {
  background-color: #0E4FA7;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category {
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .articles {
  font-family: "Inter";
  background-color: rgb(250, 250, 250);
  color: #000000;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
  padding: 0.8vh 0.8vw 0.8vh 0.8vw;
  outline: none;
  border-radius: 11px;
  border: 1px solid rgb(237, 237, 237);
  font-weight: 700;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .articles .articles_dropdown {
  margin-left: 4vw;
  fill: black;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .articles .articles_dropdown_close {
  fill: black;
  margin-left: 4vw;
  transform: rotate(180deg);
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_report_gen_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.right_report_gen_articles_container .filter_container .filter_content_container .date_range {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
  background-color: rgb(250, 250, 250);
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler {
  font-weight: 700;
  gap: 0.5vw;
  display: flex;
  align-items: center;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler input {
  cursor: pointer;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main {
  background-color: #3B89FE;
  border-radius: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px 4px 12px 4px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles {
  font-family: "Inter";
  font-size: 1rem;
  color: #ffffff;
  transition: right 1s ease;
  box-shadow: none;
  flex: 1;
  outline: none;
  border-radius: 0;
  padding-top: 0.2px;
  padding-bottom: 0.2px;
  position: relative;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles .toggle_text {
  text-align: left;
  position: relative;
  z-index: 1;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle, .right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:active, .right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:focus {
  border-right: 0.05px solid rgba(255, 255, 255, 0.431372549);
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle, .right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:active, .right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:focus {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle_circle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 3px; /* Adjust the distance from the right edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.right_report_gen_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle_circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px; /* Adjust the distance from the left edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .Export_button {
  cursor: pointer;
  background: #1C5BB9;
  display: flex;
  align-items: center;
  gap: 5%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .Export_button .export_image {
  margin-left: 0.5vw;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters {
  position: absolute;
  top: 110%; /* Position below the parent div */
  right: 0;
  width: 25%; /* Full width of the parent */
  background: linear-gradient(247deg, #F1F7FD 8.58%, #F9FCFF 66.71%); /* Sample background color */
  box-shadow: 0px 15px 19px 0px rgba(0, 0, 0, 0.09);
  -webkit-backdrop-filter: blur(15.5px);
          backdrop-filter: blur(15.5px);
  z-index: 2; /* Ensure a higher z-index for it to appear over other elements */
  border-radius: 10px;
  border: 1px solid #C3C3C3;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
  padding: 10px;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .cross_modal {
  cursor: pointer;
  margin-left: auto;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .details_container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding-inline: 1vw;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .details_container .TotalArticles, .right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .details_container .ReportFormat {
  font-weight: 700;
  font-size: small;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .details_container .TotalArticles span, .right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .details_container .ReportFormat span {
  float: right;
  color: #0E4FA7;
  font-size: larger;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .verify_buttons {
  display: flex;
  justify-content: center;
  padding-inline: 1vw;
  gap: 25%;
}
.right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .verify_buttons .verify, .right_report_gen_articles_container .filter_container .filter_content_container .export_pdf_container .absolute_verify_filters .verify_buttons .proceed {
  color: #0E4FA7;
  background-color: #FFFFFF;
  border-color: #0E4FA7;
}
.right_report_gen_articles_container .tabs_section_wrapper {
  display: flex;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container {
  display: flex;
  gap: 2%;
  flex: 1;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid rgb(178, 178, 178);
  border-radius: 6px;
  align-items: center;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse .back_blue {
  background-color: rgb(49, 122, 231);
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse .white_icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse .black_icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse .expan, .right_report_gen_articles_container .tabs_section_wrapper .tabs_container .expand_collapse .collaps {
  cursor: pointer;
  padding: 8px;
  padding-inline: 10px;
  height: 100%;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .selected, .right_report_gen_articles_container .tabs_section_wrapper .tabs_container .not_selected {
  padding: 6px;
  cursor: pointer;
  font-weight: 700;
  font-size: large;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .selected {
  color: #1C70C8;
  position: relative;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .not_selected {
  color: #7A7A7A;
}
.right_report_gen_articles_container .tabs_section_wrapper .tabs_container .selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 50%; /* Adjust this value to control the length of the border */
  height: 3px; /* Border thickness */
  background-color: #48A1FE;
}
.right_report_gen_articles_container .info_text_for_selected_articles {
  text-align: center;
  font-weight: 700;
  padding: 4px;
  margin-top: 2vh;
  border: 1px solid #1C70C8;
  z-index: 1;
  border-radius: 4px;
  width: 80%;
  margin-inline: auto;
}
.right_report_gen_articles_container .applied_filters_container {
  display: flex;
  margin-top: 2vh;
  width: 70vw;
  gap: 5vw;
}
.right_report_gen_articles_container .applied_filters_container .expand_collapse {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid rgb(178, 178, 178);
  border-radius: 6px;
  align-items: center;
}
.right_report_gen_articles_container .applied_filters_container .expand_collapse .back_blue {
  background-color: rgb(49, 122, 231);
}
.right_report_gen_articles_container .applied_filters_container .expand_collapse .white_icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
}
.right_report_gen_articles_container .applied_filters_container .expand_collapse .black_icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
}
.right_report_gen_articles_container .applied_filters_container .expand_collapse .expan, .right_report_gen_articles_container .applied_filters_container .expand_collapse .collaps {
  cursor: pointer;
  padding: 8px;
  padding-inline: 10px;
  height: 100%;
}
.right_report_gen_articles_container .applied_filters_container .total_articles {
  font-weight: bold;
  margin-top: auto;
  padding: 1vh;
  font-size: medium;
  background: white;
  border-radius: 6px;
}
.right_report_gen_articles_container .applied_filters_container .total_articles span {
  font-weight: 600;
  background: rgb(49, 122, 231);
  padding: 6px;
  border-radius: 6px;
  color: #FFFFFF;
}
.right_report_gen_articles_container .applied_filters_container .date_applied {
  margin-left: auto;
  padding: 2vh;
  border-radius: 2vh;
  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
}
.right_report_gen_articles_container .applied_filters_container .date_applied .header_date {
  font-weight: 600;
}
.right_report_gen_articles_container .applied_filters_container .date_applied .date_text {
  margin-top: 1vw;
  color: #3B89FE;
}
.right_report_gen_articles_container .table_wrapper {
  display: flex;
  margin-top: 2vh;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container {
  width: 100%;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_filters_logo_wrapper {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_filters_logo_wrapper .no_filters_text {
  font-size: x-large;
  font-weight: 600;
  color: #1C70C8;
  text-align: center;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_results_container .child_no_results {
  margin: auto;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .arrow {
  height: 33px;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.7vw;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .dot:before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #3B89FE;
  width: 0.8vw;
  height: 2.1vh;
  border: 5px solid #3B89FE;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .view_articles_heatmap {
  position: relative;
  z-index: 0;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .pagination_container {
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  margin-right: auto;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.right_report_gen_articles_container .table_wrapper .on_demand_table_container .pagination_container .nav_container {
  font-family: "Inter";
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}
.right_report_gen_articles_container .table_wrapper .quick_scroll {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
}
.right_report_gen_articles_container .table_wrapper .quick_scroll .bottom {
  position: sticky;
  top: 1.5vh;
  cursor: pointer;
}
.right_report_gen_articles_container .table_wrapper .quick_scroll .top {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  bottom: 1.5vh;
  cursor: pointer;
}
.right_report_gen_articles_container .selected_articles_table_wrapper {
  display: flex;
  margin-top: 2vh;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container {
  width: 100%;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_articles_logo_wrapper {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_articles_logo_wrapper .no_articles_text {
  font-size: x-large;
  font-weight: 600;
  color: #1C70C8;
  text-align: center;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_results_container .child_no_results {
  margin: auto;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .arrow {
  height: 33px;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.7vw;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .dot:before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #3B89FE;
  width: 0.8vw;
  height: 2.1vh;
  border: 5px solid #3B89FE;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .view_articles_heatmap {
  position: relative;
  z-index: 0;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .pagination_container {
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  margin-right: auto;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .on_demand_table_container .pagination_container .nav_container {
  font-family: "Inter";
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .quick_scroll {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .quick_scroll .bottom {
  position: sticky;
  top: 1.5vh;
  cursor: pointer;
}
.right_report_gen_articles_container .selected_articles_table_wrapper .quick_scroll .top {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  bottom: 1.5vh;
  cursor: pointer;
}/*# sourceMappingURL=rightReportGenArticles.css.map */