@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.left_dashboard_container{
    align-items: center;
    width: 16vw;
    justify-content: space-between;
    border-right: 1px solid rgba(59, 137, 254, 0.1);
    border: 1px solid #3B89FE1A;
    box-shadow: 4px 8px 16px 0px #0000001F;
    background: white;
    height: 100vh;
    top: 0;
    position: sticky;

    .topic_container{
        margin-top: 3vh;
        text-align: center;
        h3{
            font-size: 30px;
            font-family: 'DM Sans', sans-serif;
            color: #3B89FE;
            font-weight: bold;
        }
    }
    .elements_wrapper{

        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'DM Sans', sans-serif;
        font-weight: bold;

        .elements_container{
            margin-top: 6vh;
            width: 15vw;
            text-align: left;
            .elements{
                margin-bottom: 1vh;
                border-radius: 0.3rem;
                padding: 1vh;
                padding-left: 2vw;
                padding-top: 2vh;
                padding-bottom: 2vh;
                cursor: pointer;
                position: relative;

                .open_icon{
                    margin-left: auto;
                    filter: brightness(0) saturate(100%);
                    position: absolute;
                    right: 8%;
                    top: 46%;
                }
                .close_icon{
                    transform: rotate(180deg);
                    filter: brightness(0) saturate(100%);
                    position: absolute;
                    right: 8%;
                    top: 46%;
                }

                // .popup {
                //     width: 100%;
                //     // position: absolute;
                //     top: 100%;
                //     left: 50%;
                //     transform: translateX(-50%);
                //     background-color: #333;
                //     color: #fff;
                //     padding: 20px 10px;
                //     border-radius: 5px;
                //     z-index: 1;
                //     display: flex;
                //     flex-direction: column;
                //     gap: 1vh;
                //     background: linear-gradient(135deg, rgba(81, 170, 234, 0.9) 0%, rgba(28, 100, 208, 0.9) 100%);
                //     .dropdown_content{
                //         display: flex;
                //         justify-content: center;
                //         color: #FFFFFF;
                //         font-weight: 100;
                //         font-size: larger;
                //     }
                //     .dropdown_1:hover, .dropdown_2:hover, .dropdown_3:hover, .dropdown_4:hover{
                //         background-color: #FFFFFF;
                //         color:rgba(36, 113, 229, 1);
                //         font-weight: bold;
                //         border-radius: 6px;
                //     }   
                //   }

                .icons{
                    height: 2.5vh;
                    margin-right: 0.7vw;
                }

                
                .icon1, .icon2, .icon3{
                    filter: invert(0%) sepia(94%) saturate(2%) hue-rotate(104deg) brightness(103%) contrast(97%);
                }
            }
            .popup {
                width: 100%;
                // position: absolute;
                padding: 20px 10px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                gap: 1vh;
                background: linear-gradient(135deg, rgba(81, 170, 234, 0.9) 0%, rgba(28, 100, 208, 0.9) 100%);
                .dropdown_content{
                    display: flex;
                    justify-content: center;
                    color: #FFFFFF;
                    font-weight: 100;
                    font-size: larger;
                }
                .dropdown_1:hover, .dropdown_2:hover, .dropdown_3:hover, .dropdown_4:hover{
                    background-color: #FFFFFF;
                    color:rgba(36, 113, 229, 1);
                    font-weight: bold;
                    border-radius: 6px;
                }   
              }
        }

        // .button_container{
        //     margin-top: 30vh;
        //     button{
        //         width: 14vw;
        //     }
        // }
    }
    .button_container{
        // margin-top: 30vh;
        position: absolute;
        bottom: 10%;
        button{
            width: 16vw;
            color: black;
            font-weight: 600;
            background: #EEF6FF;
            border: 2px solid rgba(59, 137, 254, 0.20);
            box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.09);
        }
        .button:hover{
            background: initial;
        }
        .btn.active, 
        .btn:active {
            background: #EEF6FF;
            color: initial;
            box-shadow: none;
        }
        .btn:active:focus{
            box-shadow: initial;
        }
    }
}
