@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_draft_articles_container{
    width: 100%;
    padding: 6vh;
    padding-top: 2VH;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    .filter_container{
        display: flex;
        .filter_content_container{
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
            gap: 0.8vw;
            // .filter_parts{
            //     flex: 1;
            // }
            .delete_draft{
                cursor: pointer;
            }
            .DraftName{
                font-size: large;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                gap: 0.5vh;
                // flex: 1;
                // span{
                //     font-weight: 600;
                //     background: rgba(49, 122, 231, 1);
                //     padding: 6px;
                //     border-radius: 6px;
                //     color: #FFFFFF;
                //     font-size: large;
                // }
                .draft_type{
                    width: fit-content;
                    color: #1C70C8;
                    font-size: small;
                    background: #E2F0FF;
                    border-radius:24px;
                    padding: 3px 12px;
                }
                .tags{
                    display: flex;
                    gap: 1%;
                }
            }
            .articles_category{
                .articles{
                    font-family: "Inter";
                    // font-size: 1.2rem;
                    // padding: 1vh;
                    // padding-left: 1vw;
                    // padding-right: 1vw;
                    background-color: #3B89FE;
                    color: #ffffff;
                    // border-radius: 25px;
                    // transition: right 1s ease;
                }
                .articles_main{
                    .articles_dropdown{
                        margin-left: 1vw;
                    }
                    .articles_dropdown_close{
                        margin-left: 1vw;
                        transform: rotate(180deg)
                    }
                }
                .dropdown_container{
                    position: absolute;
                    top: calc(100% + 10px); /* Adjust the top value as needed */
                    left: 0;
                    background: white;
                    border: 1px solid #ccc;
                    padding: 10px;
                    z-index: 2;
                    max-height: 30vh; /* Adjust the maximum height as needed */
                    overflow-y: auto;
                }
                .dropdown_container::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                  }
                .dropdown_main_category{
                    padding: 1vh 1vw 1vh 1vw;
                    text-align: center;
                    color: #3B89FE;
                    background-color: #ffffff;
                    border: 1px solid #F1F1F1;
                    cursor: pointer;
                }
                // .ct_1, .ct_2{
                    // border: 1px solid #F1F1F1;
                    // cursor: pointer;
                    // border-top: 0;
                    // border-color:#F1F1F1;
                // }
                .dropdown_main_category:hover{
                    box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                }
                .dropdown_main_category:active{
                    box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                }
            }

            .date_filter_container{
                display: flex;
                align-items: center; 
                justify-content: center;
                margin-left: 2vw;
                gap: 0.5vw;
                .from_to_text{
                    color:rgba(81, 81, 81, 1);
                }
            }
            .from_to_text_read_only{
                    display: flex;
                    gap: 0.5vw;
                    border: 1px solid rgba(1, 79, 188, 0.27);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
                    border-radius: 7px;
                    padding: 7px;
                    .date_time_text{
                        border-bottom   : 1px solid rgba(197, 197, 197, 1)
                    }
            }
        
            
            .crud_search{            
                display: flex;
                gap: 1vw;
                justify-content: center;
                border: 2px solid #E4E4E4;
                border-radius: 9px;
                padding: 1vh 1vw 1vh 1vw;
                background: rgba(250, 250, 250, 0.81);
                width: 30%;
                margin-left: auto;
                .crud_add{
                    width: 100%;
                    font-weight: 400;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.87);
                    border: none;
                    background: rgba(250, 250, 250, 0.81);
                }
                .crud_add:focus{
                    outline: none;
                }
            }

            .searchbar_container{
                // .abcabc{
                //     width: 20vw;
                // }
                // margin-left: 1vw;
                height: auto;
                .react-select {
                    font-size: 18px;
                    padding: 2vh;
                }
                .abcabc{
                    width: 20vw;
                    font-size: 19.8px;
                    z-index: 2;
                    // padding: 2vh;
                    .css-1jqq78o-placeholder{
                        font-weight: 100;
                        font-size: medium;
                        color:#BDBDBD;
                    }
                }
            }

            .reset_filter_container{
                // border: 1px solid #C8C8C8;
                // font-size: 0.8rem;
                // background: #FFFFFF;
                // display: flex;
                // gap: 0.4vw;
                // border-radius: 0.2rem;
                display: flex;
                // flex-wrap: wrap; /* it will wrap the children if they overflow the parent */
                align-items: center; /* aligns child elements along the vertical (cross) axis */
                justify-content: center; /* aligns child elements along the horizontal (main) axis */

                background-color: white;
                // margin-left: 1vw;
                border: 1px solid #C8C8C8;
                border-radius: 0.2rem;
                width: auto;
                padding-top: 0.6vh;
                padding-bottom: 0.6vh;
                cursor: pointer;
                .content{
                    // font-size:15px;
                    align-items: center;
                     margin: 5px;
                }
                .ref_logo_container{
                    height: 16px;
                    // margin-left: 1vw;;
                    // display: flex;
                    // align-items: center;
                    // transform: rotate(-180deg);
                }
            }

            // .prompt_toggler{
            //     margin-left: 2vw;
            //     margin-left: auto;
            //     display: flex;
            //     align-items: center; 
            //     // position: relative;
            //     padding: 9px;
            //     input{
            //         cursor: pointer;
            //     }
            //     // .toggler_main_ele {
            //     //     flex-grow: 0;
            //     //     flex-basis: 200px;
            //     // }
                  
            //     // .toggler_right_ele {
            //     //     flex-grow: 1;
            //     //     // margin-left: -100%;
            //     // }
            // }
            .prompt_toggler{
                font-weight: 700;
                margin-left: 2vw;
                gap: 0.5vw;
                // margin-left: auto;
                display: flex;
                align-items: center; 
                // padding: 9px;
                input{
                    cursor: pointer;
                }
                .toggler_main{
                    background-color: #3B89FE;
                    // border: 1px solid;
                    border-radius: 25px;
                    // display: flex;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 12px 4px 12px 4px;

                    .toggles{
                        font-family: "Inter";
                        font-size: 1rem;
                        // padding: 1vh;
                        // padding-left: 0.8vw;
                        // background-color: #3B89FE;
                        color: #ffffff;

                        // border-radius: 25px;
                        transition: right 1s ease;
                        box-shadow: none;
                        flex: 1;
                        outline: none;
                        border-radius: 0;
                        padding-top: 0.2px;
                        padding-bottom: 0.2px;
                        position: relative;
                        .toggle_text{
                            text-align: left;
                            position: relative;
                            z-index: 1;
                        }
                    }
                
                    .left_toggle, .left_toggle:active, .left_toggle:focus{
                        border-right: 0.05px solid #ffffff6e;
                        border-left: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                        // border-top-right-radius: 10px ;
                        // border-bottom-right-radius: 10px;                        
                    }
                    .right_toggle, .right_toggle:active, .right_toggle:focus{
                        border-left: 0px;
                        border-right: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                        // border-top-left-radius: 10px;
                        // border-bottom-left-radius: 10px;
                    }
                                        
                    .right_toggle_circle::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 3px; /* Adjust the distance from the right edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease
                    }
                    .left_toggle_circle::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 3px; /* Adjust the distance from the left edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease;
                    }
                }
              
            }

            .export_pdf_container{
                margin-left: 2vw;
                .Export_button{
                    cursor: pointer;
                    background:#1C5BB9; 
                    display: flex;
                    align-items: center;
                    gap: 5%;
                    .export_image{
                        margin-left: 0.5vw;
                    }
                }
                
            }
            .apply_filter_button{
                // margin-left: 1vw;
                display: flex;
                align-items: center;
                // margin-left: 1vw;
                // margin-left: auto;
                // button{
                //     padding: 1.1vh;
                //     // width: 5vw;
                // }
            }
        }
    }

    .applied_filters_container{
        display: flex;
        margin-top: 2vh;
        gap: 3vw;
        width: 100%;
        align-items: center;
        .expand_collapse{
            // display: flex;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // align-items: center;
            border: 0.5px solid rgba(178, 178, 178, 1);
            border-radius: 6px;
            align-items: center;
            // padding: 6px;
            .back_blue{
                background-color: rgba(49, 122, 231, 1);
            }
            .white_icon{
                filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
            }
            .black_icon{
                filter:  invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
            }
            .expan, .collaps{
                cursor: pointer;
                padding: 8px;
                padding-inline: 10px;
            }
        }
        .total_articles{
            font-weight: bold;
            margin-top: auto;
            padding: 1vh;
            font-size: medium;
            // background: white;
            // color: #8080ab;
            z-index: 1;
            border-radius: 6px;
            span{
                font-weight: 600;
                background: rgba(49, 122, 231, 1);
                padding: 6px;
                border-radius: 6px;
                color: #FFFFFF;
            }
        }
        // .total_articles{
        //     margin-top: auto;
        //     padding: 1vh;
        //     font-size: medium;
        //     background: white;
        //     color: #8080ab;
        //     border-radius: 6px;
        //     span{
        //         font-weight: 600;
        //     }
        // }
        .delete_articles{
            cursor: pointer;
            // margin-left: auto;
        }
        .date_applied{
            margin-left: auto;
            padding: 2vh;
            border-radius: 2vh;
            background: #FFFFFF;
            box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
            .header_date{
                font-weight: 600;
            }
            .date_text{
                margin-top: 1vw;
                color: #3B89FE;
            }
        }
        // .apply_filter_button{
        //     // margin-left: 1vw;
        //     display: flex;
        //     align-items: center;
        //     margin-left: 1vw;
        //     // margin-left: auto;
        //     button{
        //         padding: 1.1vh;
        //         // width: 5vw;
        //     }
        // }
        .right_cells{
            z-index: 1;
        }
        .clone_draft{
            // margin-left: auto;
            z-index: 1;
        }
        .clone_draft, .rearrange_draft{
            color: #064281;
            border-color: #0B56A4;
            background: #FFFFFF;
        }
    }
// 
    .table_wrapper{
        display: flex;
        margin-top: 2vh;
        .on_demand_table_container{
            width: 100%;
            // margin-top: 12vh;
            z-index: 1; 

            .translate_text_icon{
                width: 3.8%;
                cursor: pointer;
            }
            
            .draft_Article_delete_icon{
                width: 0.9vw;
            }
            .no_results_container{
                // border: 1px solid;
                display: flex;
                flex-direction: column;
                margin-top: 18vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    // margin: auto;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
            
            .arrow{
                height: 33px;
            }

            .dot {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.7vw;
            }
                
            .dot:before {
                content: "";
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #3B89FE;
                width: 0.8vw;
                height: 2.1vh;
                border: 5px solid #3B89FE;
            }
            .view_articles_heatmap{
                position: relative;
                z-index: 0;       
                .article_topic_container{
                    font-size: xx-large;
                }     
            }
            .pagination_container{
                // display: flex;
                // padding: 6vh 0 2vh 0;
                // justify-content: center;
                // align-items: center;
                background: transparent;
                backdrop-filter: blur(10px);
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    // margin-left: 12vw;
                    font-family: 'Inter';
                    position: absolute;
                    justify-content: center;
                    // font-weight: 600;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .quick_scroll{
            display: flex;
            flex-direction: column;
            // margin-top: 12vh;
            padding-left: 2vw;
            .bottom{
                // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                position: sticky;
                top: 1.5vh;
                // top: 40%;
                cursor: pointer;
            }
            .top{
                margin-top: auto;
                // box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                position: sticky;
                bottom: 0;
                bottom: 1.5vh;
                // bottom: 30%;
                cursor: pointer;
            }
        }
    }
}

.article_draft_popup_content{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    font-family: "Inter";
    align-items: baseline;
    .draft_keywords_header{
        color: #000000;
        font-size: large;
        font-weight: 600;
    }
    .draft_keywords{
        display: flex;
        flex-direction: column;
        gap: 1vh;
        text-align: left;
        .draft_keywords_index{
            color: #000000;
            font-size: larger;
            font-weight: 500;
        }
        span{
            margin-left: 1vw;
        }
    }
}

.draft_delete_popup_content{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    justify-content: center;
    .draft_delete_popup_header{
        font-weight: 600;
        font-size: large;
    }
    .draft_delete_input_bar_container{
        width: 100%;
        margin-top: 10%;
        .draft_delete_input{
            border: 1px solid #D4D4D4;
            border-radius: 9px;  
            outline: none; 
            padding: 8px 8px;
            width: 100%;
        }

    }
    .draft_delete_add_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 25%;
        width: 100%;
        justify-content: center;    
        .cancel{
            background: #C8C8C8;
        }
    }
}

.draft_delete_article_popup_content{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    justify-content: center;
    .draft_delete_article_popup_header{
        font-weight: 600;
        font-size: large;
    }
    .draft_delete_article_add_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 25%;
        width: 100%;
        justify-content: center;    
        .cancel{
            background: #C8C8C8;
        }
    }
}

.export_articles_popup_content{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    justify-content: center;
    .export_articles_popup_header{
        font-weight: 600;
        font-size: large;
    }
    .export_articles_add_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 2%;
        width: 100%;
        justify-content: center;  
        .button_main{
            background: #0E4FA7;
        }  
        .cancel{
            background: #C8C8C8;
        }
    }
}

.draft_rearrange_popup{
    width: 100%;
}