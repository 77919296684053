@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_profile_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 4vh;
  font-family: "Inter";
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}
.right_profile_container .headers {
  display: flex;
  gap: 5vw;
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_profile_container .headers .header_container {
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 1vh;
  cursor: pointer;
}
.right_profile_container .headers .activeHeader {
  box-shadow: inset 0px -2px 0px #2B6ED3;
}
.right_profile_container .profile_page_wrapper {
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_profile_container .profile_page_wrapper .right_profile_1 {
  display: flex;
  margin-top: 6vh;
  padding: 15px 15px 45px 15px;
  border: 1px solid;
  gap: 15px;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0) 77.37%);
  border: 1px solid #ECECEC;
  border-radius: 22px;
  min-height: 67vh;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .subheader_user_profile {
  font-weight: 700;
  font-size: 28px;
  text-align: center;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  flex: 3.5;
  align-items: center;
  gap: 2vh;
  border: 1px solid #D0E7FE;
  box-shadow: 0px 15px 34px 0px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px;
  background: linear-gradient(168deg, rgba(147, 192, 239, 0.12) 0%, rgba(226, 239, 252, 0.425) 84.58%, rgba(226, 239, 252, 0.41) 91.24%);
  padding: 20px 10px;
  justify-content: space-between;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container .user_profile_logo .ellipse_logo {
  height: 128px;
  width: 128px;
  border-radius: 50%;
  border: 1px solid #2E46CD;
  background: #2E46CD;
  text-align: center;
  color: #FFFFFF;
  font-size: 45px;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  border: 7px solid #FFFFFF;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container .user_details {
  display: flex;
  align-items: center;
  padding: 14px 10px;
  background: rgba(132, 182, 235, 0.19);
  border-radius: 8px;
  width: 100%;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container .user_details .user_details_main {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container .user_details .user_details_main .user_details_content {
  display: flex;
  gap: 0.5vw;
  font-size: larger;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .user_details_container .user_details .user_details_main .user_details_content span {
  font-weight: bold;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile {
  border: 1px solid;
  flex: 6.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  border: 1px solid #D0E7FE;
  box-shadow: 0px 15px 34px 0px rgba(0, 0, 0, 0.0705882353);
  border-radius: 5px;
  background: linear-gradient(168deg, rgba(147, 192, 239, 0.12) 0%, rgba(226, 239, 252, 0.425) 84.58%, rgba(226, 239, 252, 0.41) 91.24%);
  padding: 20px 10px;
  justify-content: space-between;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container {
  width: 100%;
  padding-inline: 5vw;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .curr_pass_profile, .right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .new_pass_profile {
  position: relative;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .curr_pass_profile .eye_icon_container, .right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .new_pass_profile .eye_icon_container {
  position: absolute;
  right: 0.5vw;
  top: 58%;
  transform: translateY(50%);
  cursor: pointer;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .elems {
  padding-top: 1vh;
  margin-right: 18px;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .grid_password .profile_inputs {
  border: 1px solid rgba(28, 112, 200, 0.2);
  background: #FFFFFF;
  border-radius: 8px;
  height: 7vh;
  font-size: 20px;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .submit_container {
  display: flex;
  gap: 1vw;
  justify-content: center;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .submit_container button {
  width: 11vw;
  padding: 7px;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .submit_container .cancel {
  background: #E9E9E9;
}
.right_profile_container .profile_page_wrapper .right_profile_1 .first_layer_profile .pass_reset_container .submit_container .update {
  background: #0E4FA7;
}
.right_profile_container .profile_page_wrapper .right_profile_2 {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords {
  width: auto;
  padding: 3% 0 3% 0;
  padding-inline: 6%;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2%;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .subheader_user_profile {
  font-weight: 600;
  font-size: large;
  text-align: center;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_search {
  display: flex;
  gap: 1vw;
  margin-top: 1vh;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_search .crud_add {
  padding-inline-start: 0.5vw;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid #E5E5E6;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_search .crud_add:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_table {
  margin-top: 2vh;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_table .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .crud_table .cross_button_profile:focus {
  outline: none;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .total_count_container {
  justify-content: right;
  display: flex;
  padding-top: 3%;
}
.right_profile_container .profile_page_wrapper .right_profile_2 .crud_keywords .total_count_container .total_count {
  border: 1px solid #E5E5E6;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5%;
  padding-inline: 3%;
  /* color: #E5E5E6; */
  border-radius: 6px;
}
.right_profile_container .sources_page_wrapper {
  margin-top: 6vh;
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_profile_container .sources_page_wrapper .crud_sources {
  width: 90%;
  padding: 3vh 5vw;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
  border: 1px solid #ECECEC;
  border-radius: 22px;
}
.right_profile_container .sources_page_wrapper .crud_sources .sources_header {
  display: flex;
  align-items: center;
}
.right_profile_container .sources_page_wrapper .crud_sources .subheader_user_profile {
  font-weight: 600;
  font-size: large;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_search {
  display: flex;
  gap: 1vw;
  border: 2px solid #E5E5E6;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
  margin-left: auto;
  width: 50%;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_search .total_keywords_select {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #3B89FE;
  color: #ffffff;
  font-weight: 600;
  margin-right: 2vw;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_search .crud_add:focus {
  outline: none;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources {
  margin-top: 2vh;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .cross_button_profile:focus {
  outline: none;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .concern_cell {
  padding: 4px;
  border: 1px solid #C8C8C8;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.0784313725);
  border: 0.5px solid #4C94E0;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  width: 50%;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .concern_cell:focus {
  outline: None;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .whitelist_concern_cell {
  background: #0E4FA7;
  color: #FFFFFF;
}
.right_profile_container .sources_page_wrapper .crud_sources .crud_table_sources .blacklist_concern_cell {
  background: #FFFFFF;
  color: #0E4FA7;
}
.right_profile_container .sources_page_wrapper .sources_pagination_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2vh;
  width: 90%;
}
.right_profile_container .sources_page_wrapper .sources_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  left: 1vw;
  color: #858585;
}
.right_profile_container .sources_page_wrapper .sources_pagination_container .display_pages span {
  font-weight: 600;
}
.right_profile_container .sources_page_wrapper .sources_pagination_container .nav_container {
  display: flex;
  justify-content: center;
  font-family: "Inter";
  width: auto;
  height: auto;
}
.right_profile_container .categories_page_wrapper {
  margin-top: 6vh;
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_profile_container .categories_page_wrapper .crud_categories {
  width: 90%;
  padding: 3vh 5vw;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
  border: 1px solid #ECECEC;
  border-radius: 22px;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header {
  display: flex;
  align-items: center;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header .subheader_user_profile {
  font-weight: 600;
  font-size: large;
  text-align: center;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header .crud_search {
  display: flex;
  gap: 1vw;
  border: 2px solid #E5E5E6;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
  margin-left: auto;
  width: 50%;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header .crud_search .total_keywords_select {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #3B89FE;
  color: #ffffff;
  font-weight: 600;
  margin-right: 2vw;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_profile_container .categories_page_wrapper .crud_categories .categories_header .crud_search .crud_add:focus {
  outline: none;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories {
  margin-top: 2vh;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories .concern_cell {
  padding: 4px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.0784313725);
  border: 0.5px solid #4C94E0;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories .interested_concern_cell {
  background: #0E4FA7;
  color: #FFFFFF;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories .makeinterested_concern_cell {
  background: #FFFFFF;
  color: #0E4FA7;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories .default_concern_cell {
  background: #0E4FA7;
  color: #FFFFFF;
}
.right_profile_container .categories_page_wrapper .crud_categories .crud_table_categories .makedefault_concern_cell {
  background: #FFFFFF;
  color: #0E4FA7;
}
.right_profile_container .categories_page_wrapper .crud_categories .total_count_container {
  justify-content: right;
  display: flex;
  padding-top: 3%;
}
.right_profile_container .categories_page_wrapper .crud_categories .total_count_container .total_count {
  border: 1px solid #E5E5E6;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5%;
  padding-inline: 3%;
  /* color: #E5E5E6; */
  border-radius: 6px;
}
.right_profile_container .categories_page_wrapper .categories_pagination_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2vh;
  width: 90%;
}
.right_profile_container .categories_page_wrapper .categories_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  left: 1vw;
  color: #858585;
}
.right_profile_container .categories_page_wrapper .categories_pagination_container .display_pages span {
  font-weight: 600;
}
.right_profile_container .categories_page_wrapper .categories_pagination_container .nav_container {
  display: flex;
  justify-content: center;
  font-family: "Inter";
  width: auto;
  height: auto;
}
.right_profile_container .keywords_page_wrapper {
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  margin-top: 6vh;
}
.right_profile_container .keywords_page_wrapper .crud_keywords {
  width: 64%;
  padding: 3vh 5vw;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
  border: 1px solid hsl(0, 0%, 93%);
  border-radius: 22px;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header {
  display: flex;
  align-items: center;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header .subheader_user_profile {
  font-weight: 600;
  font-size: large;
  text-align: center;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header .crud_search {
  display: flex;
  gap: 1vw;
  border: 2px solid #E5E5E6;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
  margin-left: auto;
  width: 70%;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header .crud_search .total_keywords_select {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #3B89FE;
  color: #ffffff;
  font-weight: 600;
  margin-right: 2vw;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .keywords_header .crud_search .crud_add:focus {
  outline: none;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .crud_table {
  margin-top: 2vh;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .crud_table .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .crud_table .cross_button_profile:focus {
  outline: none;
}
.right_profile_container .keywords_page_wrapper .crud_keywords .genrateReport {
  margin-top: 2vh;
}
.right_profile_container .keywords_page_wrapper .keywords_pagination_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2vh;
  width: 64%;
}
.right_profile_container .keywords_page_wrapper .keywords_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  left: 1vw;
  color: #858585;
}
.right_profile_container .keywords_page_wrapper .keywords_pagination_container .display_pages span {
  font-weight: 600;
}
.right_profile_container .keywords_page_wrapper .keywords_pagination_container .nav_container {
  display: flex;
  justify-content: center;
  font-family: "Inter";
  width: auto;
  height: auto;
}
.right_profile_container .org_keywords_page_wrapper {
  margin-top: 6vh;
  position: relative;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords {
  width: 64%;
  padding: 3vh 5vw;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
  border: 1px solid #ECECEC;
  border-radius: 22px;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header {
  display: flex;
  align-items: center;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header .subheader_user_profile {
  font-weight: 600;
  font-size: large;
  text-align: center;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header .crud_search {
  display: flex;
  gap: 1vw;
  border: 2px solid #E5E5E6;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
  margin-left: auto;
  width: 70%;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header .crud_search .total_keywords_select {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #3B89FE;
  color: #ffffff;
  font-weight: 600;
  margin-right: 2vw;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .org_keywords_header .crud_search .crud_add:focus {
  outline: none;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords {
  margin-top: 2vh;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords .add_org_keyword_icon {
  width: 6%;
  cursor: pointer;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords .edit_button_profile, .right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords .edit_button_profile:focus, .right_profile_container .org_keywords_page_wrapper .crud_org_keywords .crud_table_org_keywords .cross_button_profile:focus {
  outline: none;
}
.right_profile_container .org_keywords_page_wrapper .org_keywords_pagination_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2vh;
  width: 64%;
}
.right_profile_container .org_keywords_page_wrapper .org_keywords_pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  left: 1vw;
  color: #858585;
}
.right_profile_container .org_keywords_page_wrapper .org_keywords_pagination_container .display_pages span {
  font-weight: 600;
}
.right_profile_container .org_keywords_page_wrapper .org_keywords_pagination_container .nav_container {
  display: flex;
  justify-content: center;
  font-family: "Inter";
  width: auto;
  height: auto;
}

.profile_org_keyword_update_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_popup_header {
  font-weight: 600;
  font-size: larger;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_input_bar_container {
  width: 100%;
  margin-top: 10%;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_input_bar_container .profile_org_keyword_update_input {
  border: 1px solid #D4D4D4;
  border-radius: 9px;
  outline: none;
  padding: 8px 8px;
  width: 100%;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_add_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 25%;
  width: 100%;
  justify-content: center;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_add_draft_button .button_main {
  background: #0E4FA7;
}
.profile_org_keyword_update_popup_content .profile_org_keyword_update_add_draft_button .cancel {
  background: #C8C8C8;
}/*# sourceMappingURL=right_profile.css.map */