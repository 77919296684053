@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_draft_articles_container {
  width: 100%;
  padding: 6vh;
  padding-top: 2VH;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_draft_articles_container .filter_container {
  display: flex;
}
.right_draft_articles_container .filter_container .filter_content_container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 0.8vw;
}
.right_draft_articles_container .filter_container .filter_content_container .delete_draft {
  cursor: pointer;
}
.right_draft_articles_container .filter_container .filter_content_container .DraftName {
  font-size: large;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}
.right_draft_articles_container .filter_container .filter_content_container .DraftName .draft_type {
  width: -moz-fit-content;
  width: fit-content;
  color: #1C70C8;
  font-size: small;
  background: #E2F0FF;
  border-radius: 24px;
  padding: 3px 12px;
}
.right_draft_articles_container .filter_container .filter_content_container .DraftName .tags {
  display: flex;
  gap: 1%;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .articles {
  font-family: "Inter";
  background-color: #3B89FE;
  color: #ffffff;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .articles_main .articles_dropdown {
  margin-left: 1vw;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .articles_main .articles_dropdown_close {
  margin-left: 1vw;
  transform: rotate(180deg);
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 2;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #3B89FE;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_draft_articles_container .filter_container .filter_content_container .articles_category .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.right_draft_articles_container .filter_container .filter_content_container .date_filter_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2vw;
  gap: 0.5vw;
}
.right_draft_articles_container .filter_container .filter_content_container .date_filter_container .from_to_text {
  color: rgb(81, 81, 81);
}
.right_draft_articles_container .filter_container .filter_content_container .from_to_text_read_only {
  display: flex;
  gap: 0.5vw;
  border: 1px solid rgba(1, 79, 188, 0.27);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: 7px;
}
.right_draft_articles_container .filter_container .filter_content_container .from_to_text_read_only .date_time_text {
  border-bottom: 1px solid rgb(197, 197, 197);
}
.right_draft_articles_container .filter_container .filter_content_container .crud_search {
  display: flex;
  gap: 1vw;
  justify-content: center;
  border: 2px solid #E4E4E4;
  border-radius: 9px;
  padding: 1vh 1vw 1vh 1vw;
  background: rgba(250, 250, 250, 0.81);
  width: 30%;
  margin-left: auto;
}
.right_draft_articles_container .filter_container .filter_content_container .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  background: rgba(250, 250, 250, 0.81);
}
.right_draft_articles_container .filter_container .filter_content_container .crud_search .crud_add:focus {
  outline: none;
}
.right_draft_articles_container .filter_container .filter_content_container .searchbar_container {
  height: auto;
}
.right_draft_articles_container .filter_container .filter_content_container .searchbar_container .react-select {
  font-size: 18px;
  padding: 2vh;
}
.right_draft_articles_container .filter_container .filter_content_container .searchbar_container .abcabc {
  width: 20vw;
  font-size: 19.8px;
  z-index: 2;
}
.right_draft_articles_container .filter_container .filter_content_container .searchbar_container .abcabc .css-1jqq78o-placeholder {
  font-weight: 100;
  font-size: medium;
  color: #BDBDBD;
}
.right_draft_articles_container .filter_container .filter_content_container .reset_filter_container {
  display: flex;
  align-items: center; /* aligns child elements along the vertical (cross) axis */
  justify-content: center; /* aligns child elements along the horizontal (main) axis */
  background-color: white;
  border: 1px solid #C8C8C8;
  border-radius: 0.2rem;
  width: auto;
  padding-top: 0.6vh;
  padding-bottom: 0.6vh;
  cursor: pointer;
}
.right_draft_articles_container .filter_container .filter_content_container .reset_filter_container .content {
  align-items: center;
  margin: 5px;
}
.right_draft_articles_container .filter_container .filter_content_container .reset_filter_container .ref_logo_container {
  height: 16px;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler {
  font-weight: 700;
  margin-left: 2vw;
  gap: 0.5vw;
  display: flex;
  align-items: center;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler input {
  cursor: pointer;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main {
  background-color: #3B89FE;
  border-radius: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 12px 4px 12px 4px;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles {
  font-family: "Inter";
  font-size: 1rem;
  color: #ffffff;
  transition: right 1s ease;
  box-shadow: none;
  flex: 1;
  outline: none;
  border-radius: 0;
  padding-top: 0.2px;
  padding-bottom: 0.2px;
  position: relative;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .toggles .toggle_text {
  text-align: left;
  position: relative;
  z-index: 1;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle, .right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:active, .right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle:focus {
  border-right: 0.05px solid rgba(255, 255, 255, 0.431372549);
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle, .right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:active, .right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle:focus {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .right_toggle_circle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 3px; /* Adjust the distance from the right edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.right_draft_articles_container .filter_container .filter_content_container .prompt_toggler .toggler_main .left_toggle_circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px; /* Adjust the distance from the left edge as needed */
  transform: translate(0, -50%);
  width: 35px; /* Adjust the size of the circle as needed */
  height: 35px;
  background-color: rgb(22, 78, 160); /* Adjust the color of the circle as needed */
  border-radius: 50%; /* Create a circular shape */
  transition: transform 0.3s ease;
}
.right_draft_articles_container .filter_container .filter_content_container .export_pdf_container {
  margin-left: 2vw;
}
.right_draft_articles_container .filter_container .filter_content_container .export_pdf_container .Export_button {
  cursor: pointer;
  background: #1C5BB9;
  display: flex;
  align-items: center;
  gap: 5%;
}
.right_draft_articles_container .filter_container .filter_content_container .export_pdf_container .Export_button .export_image {
  margin-left: 0.5vw;
}
.right_draft_articles_container .filter_container .filter_content_container .apply_filter_button {
  display: flex;
  align-items: center;
}
.right_draft_articles_container .applied_filters_container {
  display: flex;
  margin-top: 2vh;
  gap: 3vw;
  width: 100%;
  align-items: center;
}
.right_draft_articles_container .applied_filters_container .expand_collapse {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 0.5px solid rgb(178, 178, 178);
  border-radius: 6px;
  align-items: center;
}
.right_draft_articles_container .applied_filters_container .expand_collapse .back_blue {
  background-color: rgb(49, 122, 231);
}
.right_draft_articles_container .applied_filters_container .expand_collapse .white_icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
}
.right_draft_articles_container .applied_filters_container .expand_collapse .black_icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
}
.right_draft_articles_container .applied_filters_container .expand_collapse .expan, .right_draft_articles_container .applied_filters_container .expand_collapse .collaps {
  cursor: pointer;
  padding: 8px;
  padding-inline: 10px;
}
.right_draft_articles_container .applied_filters_container .total_articles {
  font-weight: bold;
  margin-top: auto;
  padding: 1vh;
  font-size: medium;
  z-index: 1;
  border-radius: 6px;
}
.right_draft_articles_container .applied_filters_container .total_articles span {
  font-weight: 600;
  background: rgb(49, 122, 231);
  padding: 6px;
  border-radius: 6px;
  color: #FFFFFF;
}
.right_draft_articles_container .applied_filters_container .delete_articles {
  cursor: pointer;
}
.right_draft_articles_container .applied_filters_container .date_applied {
  margin-left: auto;
  padding: 2vh;
  border-radius: 2vh;
  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
}
.right_draft_articles_container .applied_filters_container .date_applied .header_date {
  font-weight: 600;
}
.right_draft_articles_container .applied_filters_container .date_applied .date_text {
  margin-top: 1vw;
  color: #3B89FE;
}
.right_draft_articles_container .applied_filters_container .right_cells {
  z-index: 1;
}
.right_draft_articles_container .applied_filters_container .clone_draft {
  z-index: 1;
}
.right_draft_articles_container .applied_filters_container .clone_draft, .right_draft_articles_container .applied_filters_container .rearrange_draft {
  color: #064281;
  border-color: #0B56A4;
  background: #FFFFFF;
}
.right_draft_articles_container .table_wrapper {
  display: flex;
  margin-top: 2vh;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container {
  width: 100%;
  z-index: 1;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .translate_text_icon {
  width: 3.8%;
  cursor: pointer;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .draft_Article_delete_icon {
  width: 0.9vw;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .no_results_container .child_no_results {
  margin: auto;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .arrow {
  height: 33px;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.7vw;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .dot:before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #3B89FE;
  width: 0.8vw;
  height: 2.1vh;
  border: 5px solid #3B89FE;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .view_articles_heatmap {
  position: relative;
  z-index: 0;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .view_articles_heatmap .article_topic_container {
  font-size: xx-large;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .pagination_container {
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  margin-right: auto;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.right_draft_articles_container .table_wrapper .on_demand_table_container .pagination_container .nav_container {
  font-family: "Inter";
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}
.right_draft_articles_container .table_wrapper .quick_scroll {
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
}
.right_draft_articles_container .table_wrapper .quick_scroll .bottom {
  position: sticky;
  top: 1.5vh;
  cursor: pointer;
}
.right_draft_articles_container .table_wrapper .quick_scroll .top {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  bottom: 1.5vh;
  cursor: pointer;
}

.article_draft_popup_content {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  font-family: "Inter";
  align-items: baseline;
}
.article_draft_popup_content .draft_keywords_header {
  color: #000000;
  font-size: large;
  font-weight: 600;
}
.article_draft_popup_content .draft_keywords {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  text-align: left;
}
.article_draft_popup_content .draft_keywords .draft_keywords_index {
  color: #000000;
  font-size: larger;
  font-weight: 500;
}
.article_draft_popup_content .draft_keywords span {
  margin-left: 1vw;
}

.draft_delete_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
}
.draft_delete_popup_content .draft_delete_popup_header {
  font-weight: 600;
  font-size: large;
}
.draft_delete_popup_content .draft_delete_input_bar_container {
  width: 100%;
  margin-top: 10%;
}
.draft_delete_popup_content .draft_delete_input_bar_container .draft_delete_input {
  border: 1px solid #D4D4D4;
  border-radius: 9px;
  outline: none;
  padding: 8px 8px;
  width: 100%;
}
.draft_delete_popup_content .draft_delete_add_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 25%;
  width: 100%;
  justify-content: center;
}
.draft_delete_popup_content .draft_delete_add_draft_button .cancel {
  background: #C8C8C8;
}

.draft_delete_article_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
}
.draft_delete_article_popup_content .draft_delete_article_popup_header {
  font-weight: 600;
  font-size: large;
}
.draft_delete_article_popup_content .draft_delete_article_add_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 25%;
  width: 100%;
  justify-content: center;
}
.draft_delete_article_popup_content .draft_delete_article_add_draft_button .cancel {
  background: #C8C8C8;
}

.export_articles_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
}
.export_articles_popup_content .export_articles_popup_header {
  font-weight: 600;
  font-size: large;
}
.export_articles_popup_content .export_articles_add_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 2%;
  width: 100%;
  justify-content: center;
}
.export_articles_popup_content .export_articles_add_draft_button .button_main {
  background: #0E4FA7;
}
.export_articles_popup_content .export_articles_add_draft_button .cancel {
  background: #C8C8C8;
}

.draft_rearrange_popup {
  width: 100%;
}/*# sourceMappingURL=rightDraftArticles.css.map */