.logout{
    margin-left: 90vw;
    margin-top: 5vh;
}

.profiles{
    border: 2px solid blue;
    background: lightblue;
    padding: 16vh 1vh 16vh 2vh;
    margin: auto;
    text-align: center;
    margin-top: 23vh;
    font-weight: bold;
    font-style: italic;
}