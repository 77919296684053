@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_report_gen_tracker_container{
    width: 84vw;
    // height: 100vh;
    padding: 6vh;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;

    .main_header{
        font-weight: 600;
        font-size: x-large;
    }

    .report_gen_cards_container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px; 
        max-height: 80%;
        .report_gen_card{
            display: flex;
            flex-direction: column;
            padding: 10px 25px 25px 25px;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: 6px;
            box-shadow: 0 3px 10px #0d6efd47;
            span{
                font-weight: bold;
                margin-left: 0.5vw;
            }
            .card_header{
                font-size: larger;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5%;
                color: #0d6efd;
            }
            .middle_card_content{
                margin-top : auto ;
                display: flex;
                gap: 1vw;
                .middle_card_content_left, .middle_card_content_right{
                    display: flex;
                    flex-direction: column;
                    gap: 0.2vh;
                }
            }
        }
        .report_gen_card:hover{
            transition: all 0.2s ease;
            box-shadow: 0px 12px 30px 0px #0d6efd47;
            transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        }
    }

    .report_gen_table_table_and_content{
        display: flex;
        margin-top: 4vh;
        .report_gen_table_table_container{
            width: 100%;
            // width: 55vw;
            // padding: 1vh 4vw 1vh 4vw;
            // background-color: #ffffff;
            // box-shadow: '2px 4px 36px rgba(0, 0, 0, 0.08)';
            // border-radius: '2vh';

            .no_results_container{
                // border: 1px solid;
                display: flex;
                flex-direction: column;
                margin-top: 10vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    // margin: auto;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }

            .table_btn{
                min-width: 6vw;
                align-items: center;
                display: flex;
            }

            .report_type_name{
                margin-left: 0.5vw;
                padding: 4px;
                border-radius: 30px;
                background-color: #adadada6;
                text-align: center;
                // font-weight: bold;
            }

            .no_report_type{
                padding: 1vh;
                border-radius: 30px;
                background-color: #FEEADB;
                text-align: center;
            }
            .report_type{
                padding: 1vh;
                border-radius: 30px;
                background-color: #55ACF517;
                text-align: center;
            }

            

            .pagination_container{
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                /* top-left | top-right | bottom-right | bottom-left */
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                // justify-content: center;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                position: relative;
                .display_pages{
                    // font-family: 'Inter';
                    // height: auto;
                    // position: absolute;
                    // left: 1vw;
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 1vw;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    // margin-left: 10vw;
                    font-family: 'Inter';
                    width: auto;
                    height: auto;
    
                }
            }

        }
    }

    
    .report_gen_pagination_container{
        margin-top: auto;
        display: flex;
        padding: 2vh;
        justify-content: center;
        align-items: center;
        // position: relative;
        .display_pages{
            font-family: 'Inter';
            display: flex;
            // align-items: center;
            position: absolute;
            left: 2.5vw;
            span{
                font-weight: 600;       
            }
        }
        .nav_container{
            // margin-left: 12vw;
            font-family: 'Inter';
            // font-weight: 600;
            display: flex;
            align-items: center;
        }
    } 
}

.scheduled_reports_popup_content{
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;

    span{
        font-weight: bold;
        margin-left: 0.5vw;
    }
    .middle_card_content_child_keywords{
        display: flex;
        flex-wrap: wrap;
        .middle_card_content_child_keyword{
            padding: 0.5vh 1vh 0.5vh 1vh; 
            border-radius: 5px;
            background-color: #DBEBFE;
            text-align: center;
            font-weight: bold;
            margin-left: 0.3vw;
            margin-bottom: 1vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .card_header{
        font-size: larger;
        font-weight: bold;
        text-align: center;
        margin-bottom: 3%;
        color: #0d6efd;
    }
    .middle_card_content{
        margin-top : auto ;
        display: flex;
        gap: 1vw;
        .middle_card_content_left, .middle_card_content_right{
            display: flex;
            flex-direction: column;
            gap: 0.2vh;
        }
        .middle_card_content_left{
            width: 25%;
        }
        .middle_card_content_right{
            width: 75%;
        }
    }
}