html, body {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.container {
  height: calc(100% - 32px);
  padding-top: 0;
  cursor: pointer;
}

.no-margin {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.display-flex {
  display: flex;
}

.fill {
  flex: 1;
}

.center {
  text-align: center;
}

.mt16 {
  margin-top: 16px;
}

.footer_container {
  margin-top: auto;
  min-height: 3vh;
}

g {
  transform: none;
}/*# sourceMappingURL=test.css.map */