.right_admin_panel_container {
  width: 84vw;
  padding: 6vh;
  position: relative;
}
.right_admin_panel_container .admin_panel_data_container {
  min-width: 40vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 2%;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_filters_container {
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_filters_container .toggle_buttons {
  padding-inline: 1vw;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_filters_container .search_admin_panel {
  border: 1px solid #E5E5E6;
  border-radius: 6px;
  margin-left: 0.4vw;
  margin-inline: 0.4vw;
  padding: 0.5vw;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_filters_container .search_admin_panel:focus {
  outline: none;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_filters_container .add_organzation {
  margin-left: auto;
}
.right_admin_panel_container .admin_panel_data_container .admin_panel_table_container td {
  padding: inherit;
}
.right_admin_panel_container .admin_panel_data_container .add_organization_container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  justify-content: center;
  align-items: center;
}
.right_admin_panel_container .admin_panel_data_container .add_organization_container .common_input_add {
  width: 50%;
}
.right_admin_panel_container .admin_panel_data_container .add_organization_container .input_organization {
  border: 1px solid #E5E5E6;
  border-radius: 6px;
  padding: 0.5vw;
}
.right_admin_panel_container .admin_panel_data_container .add_organization_container .input_organization:focus {
  outline: none;
}/*# sourceMappingURL=rightAdminPanel.css.map */