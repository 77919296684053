.container {
  height: calc(100% - 200px);
  padding-top: 0;
  cursor: pointer;
}

.full-width-height.container {
  width: 100%; /* Set the width of the container */
  /* Other CSS styles for the container */
}

.no-margin {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.display-flex {
  display: flex;
}

.fill {
  flex: 1;
}

.center {
  text-align: center;
}

.tooltip_container {
  padding: 1vh;
  border-radius: 1px solid black;
}

g {
  transform: none;
}/*# sourceMappingURL=worldMap.css.map */