.Error404_container {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: auto;
}
.Error404_container img {
  width: 70vw;
  height: 70vh;
  padding-top: 25vh;
}/*# sourceMappingURL=error.css.map */