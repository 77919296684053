.page_animations_container .circle_top {
  width: 55vw;
  height: 84vh;
  background: #f2f5fc;
  border-radius: 50%;
  position: absolute;
  top: -28vh;
  right: -15vw;
  overflow: hidden;
}
.page_animations_container .circle_bottom {
  width: 34vw;
  height: 60vh;
  border: 5vw solid #5770fe;
  border-radius: 57%;
  position: absolute;
  bottom: -14vw;
  right: -15vw;
  overflow: hidden;
}/*# sourceMappingURL=pageAnimations.css.map */