@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_root_dashboard_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_root_dashboard_container .root_dashboard_header {
  display: flex;
  align-items: center;
  z-index: 1;
}
.right_root_dashboard_container .root_dashboard_header .home_tag {
  font-size: x-large;
  font-weight: bold;
  color: black;
}
.right_root_dashboard_container .root_dashboard_header .prompt_toggler {
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_header .prompt_toggler .toggler_main_ele {
  display: flex;
  gap: 0.5vw;
}
.right_root_dashboard_container .root_dashboard_header .prompt_toggler .toggler_left_ele {
  font-weight: 600;
  font-size: larger;
  color: black;
}
.right_root_dashboard_container .root_dashboard_header .prompt_toggler .toggle_input {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.right_root_dashboard_container .root_dashboard_filters {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  border: 1px solid #D0E7FE;
  box-shadow: 0px 9px 38px 0px rgba(189, 214, 251, 0.3098039216);
  background: linear-gradient(180deg, rgba(226, 239, 252, 0.1512) 0%, rgba(226, 239, 252, 0.378) 84.58%, rgba(226, 239, 252, 0.2457) 91.24%);
  padding: 2.5vh 1vw;
  border-radius: 15px;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header {
  display: flex;
  margin-inline: 1vw;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .header {
  color: black;
  font-weight: bold;
  font-size: larger;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .prompt_toggler {
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .prompt_toggler .toggler_main_ele {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .prompt_toggler .toggler_left_ele {
  font-weight: 600;
  font-size: larger;
  color: black;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .prompt_toggler .toggle_input_switch {
  display: flex;
  align-items: center;
}
.right_root_dashboard_container .root_dashboard_filters .filter_header .prompt_toggler .toggle_input {
  cursor: pointer;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .prompt_container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .prompt_container .input_prompt {
  border: 1px solid hsl(0, 0%, 70%);
  margin: 0px 10vw;
  border-radius: 4px;
  outline: None;
  font-size: 19.8px;
  height: auto;
  padding-top: 5.8px;
  padding-bottom: 5.8px;
  padding-inline: 8px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .prompt_container .input_prompt::-moz-placeholder {
  font-weight: 100;
  font-size: medium;
  color: #BDBDBD;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .prompt_container .input_prompt::placeholder {
  font-weight: 100;
  font-size: medium;
  color: #BDBDBD;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .prompt_container .input_prompt:focus {
  border: 2.5px solid #3B89FE;
  outline: none;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 {
  padding: 0px 1vw;
  display: flex;
  gap: 2%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .category_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .date_range_new {
  flex: 1;
  display: flex;
  align-items: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .category_new span, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .date_range_new span {
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .category_new .date_range_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .category_new .category_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .date_range_new .date_range_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .date_range_new .category_value_new {
  flex: 1;
  z-index: 2;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .category_new {
  gap: 13%;
  z-index: 5;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_1 .date_range_new {
  gap: 2%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 {
  padding: 2vh 1vw;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  border-radius: 5px;
  background: linear-gradient(90.94deg, #E9F3FC 0.1%, #E3F1FF 99.2%);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 span {
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new {
  align-items: center;
  display: flex;
  gap: 6.8%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new {
  display: flex;
  gap: 2%;
  width: 100%;
  z-index: 4;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new {
  flex: 2;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5vh 1vw;
  cursor: pointer;
  border: 1px solid #D2DBE6;
  border-radius: 6px;
  background: #FAFCFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .keywords_main_text {
  color: #000000;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .keywords_dropdown_close {
  transform: rotate(180deg);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .keywords_dropdown_close, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .keywords_dropdown {
  filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 6;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #3B89FE;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .title_body_value_new .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .keywords_new .keywords_value_new .keywords_root_value_new {
  flex: 8;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new {
  align-items: center;
  display: flex;
  gap: 2.7%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new span {
  background: rgba(59, 137, 254, 0.2196078431);
  border-radius: 16px;
  padding-inline: 1vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new {
  display: flex;
  gap: 5%;
  justify-content: space-between;
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .conditions_value_new_main {
  display: flex;
  gap: 2%;
  width: 100%;
  background: #FAFCFF;
  border: 0.5px solid rgba(34, 117, 240, 0.2901960784);
  border-radius: 11px;
  padding-inline: 0.5vw;
  align-items: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .conditions_value_new_main .left_new {
  justify-content: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .conditions_value_new_main .right_new {
  justify-content: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .conditions_value_new_main .radio_wrapper {
  display: flex;
  gap: 5%;
  flex: 1;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .condition_1_value_new {
  flex: 2;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .condition_2_value_new {
  flex: 5;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_2 .conditions_new .conditions_value_new .condition_3_value_new {
  flex: 5;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 {
  padding: 0px 1vw;
  display: flex;
  gap: 2%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .domains_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .languages_new {
  flex: 1;
  display: flex;
  align-items: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .domains_new span, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .languages_new span {
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .domains_new .domains_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .domains_new .languages_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .languages_new .domains_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .languages_new .languages_value_new {
  flex: 1;
  z-index: 3;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .domains_new {
  gap: 15%;
  z-index: 3;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_3 .languages_new {
  gap: 2%;
  z-index: 3;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 {
  padding: 0px 1vw;
  display: flex;
  gap: 2%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .map {
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .leaflet-container {
  height: 174px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new {
  flex: 1;
  display: flex;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_new span, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new span {
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_new .location_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_new .sentiment_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_value_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .sentiment_value_new {
  flex: 1;
  z-index: 2;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_new {
  gap: 12%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .sentiment_label_new, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_label_new {
  height: -moz-fit-content;
  height: fit-content;
  padding: 6px 0px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new {
  gap: 3.5%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .verify_location_inputs_container {
  display: flex;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .verify_location_inputs_container .location_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  width: 90%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .verify_coordinates_inputs_container {
  display: flex;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .verify_coordinates_inputs_container .coordinates_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  width: 45%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .radius_selector {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .Map_approval_button .approval_locaion_wrapper {
  display: flex;
  margin-top: 1vh;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_tick {
  width: 3%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_4 .location_new .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_locaion_wrapper {
  margin-left: auto;
  background: #114DA8;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_5 {
  padding: 0px 1vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_5 .unique_articles_new {
  display: flex;
  align-items: center;
  gap: 3%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .filters_new_container .filter_layer_5 .unique_articles_new span {
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters {
  width: 70%;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-row-gap: 1.2vh;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_field {
  padding-top: 0.5vh;
  color: #000000;
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_value .filter_value_items {
  border-bottom: 1.25px solid rgb(59, 137, 254);
  border-radius: 2px;
  background: #FFFFFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_value .filter_value_items_text {
  color: rgb(45, 45, 45);
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_value .view_filter_field_value_text {
  color: rgb(45, 45, 45);
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_value .view_filter_field_value_selected {
  border-bottom: 1.25px solid rgb(59, 137, 254);
  border-radius: 2px;
  background: #FFFFFF;
  padding: 0.5vh 1vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .filter_value .view_filter_field_value_not_selected {
  border-radius: 2px;
  background: #FFFFFF;
  padding: 0.5vh 1vw;
  border-bottom: 1.25px solid rgb(128, 146, 162);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .dropdown_container {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 6;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #3B89FE;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1%;
  height: -moz-fit-content;
  height: fit-content;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .filter_category_value_items_text {
  border-bottom: 1.25px solid rgb(59, 137, 254);
  border-radius: 2px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .articles_category {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5vh 1vw;
  cursor: pointer;
  background: #FFFFFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .articles_category .articles_main_text {
  color: #000000;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .articles_category .articles_dropdown_close {
  transform: rotate(180deg);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .articles_category .articles_dropdown_close, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .articles_category .articles_dropdown {
  filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .category_filter_value .news_corpus_category {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh 1vw;
  cursor: pointer;
  background: #FFFFFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .view_domain_keywords_field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .view_domain_keywords_field .view_domain_keywords {
  padding: 2px;
  padding-inline: 8px;
  background: rgba(128, 146, 162, 0.5);
  border-radius: 2px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .view_domain_keywords_field .no_multi_filter_text {
  padding: 2px;
  padding-inline: 8px;
  background: rgba(128, 146, 162, 0.5);
  border-radius: 2px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value {
  display: grid;
  grid-template-columns: 20% 1fr;
  gap: 1%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value .keywords_selections {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5vh 1vw;
  cursor: pointer;
  border-bottom: 1.25px solid rgb(59, 137, 254);
  border-radius: 2px;
  background: #FFFFFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value .keywords_selections .keywords_main_text {
  color: #000000;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value .keywords_selections .keywords_dropdown_close {
  transform: rotate(180deg);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value .keywords_selections .keywords_dropdown_close, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value .keywords_selections .keywords_dropdown {
  filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode {
  display: flex;
  align-items: flex-start;
  gap: 1%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections {
  flex: 2;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5vh 1vw;
  cursor: pointer;
  border-bottom: 1.25px solid rgb(59, 137, 254);
  border-radius: 2px;
  background: #FFFFFF;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections .selections_text {
  padding: 2px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections .keywords_main_text {
  color: #000000;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections .keywords_dropdown_close {
  transform: rotate(180deg);
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections .keywords_dropdown_close, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_selections .keywords_dropdown {
  filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .keywords_filter_value_view_mode .view_keywords_field {
  flex: 8;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters_view {
  margin-top: 2vh;
  display: flex;
  gap: 2vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters .all_togglers_wrapper, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters_view .all_togglers_wrapper {
  display: flex;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters .all_togglers_wrapper .toggler_main_ele, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters_view .all_togglers_wrapper .toggler_main_ele {
  margin-left: 0.55vw;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters .all_togglers_wrapper .toggler_left_ele, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters .all_togglers_wrapper .toggler_right_ele, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters_view .all_togglers_wrapper .toggler_left_ele, .right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters_view .all_togglers_wrapper .toggler_right_ele {
  font-weight: bold;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .left_root_filters .conditions_filter_value .toggle_filters {
  justify-content: space-between;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters {
  width: 30%;
  padding: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .map {
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .leaflet-container {
  height: 174px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .Location_tag {
  padding-top: 0.5vh;
  color: #000000;
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .verify_location_inputs_container {
  display: flex;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .verify_location_inputs_container .location_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  width: 90%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .verify_coordinates_inputs_container {
  display: flex;
  gap: 6px;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .verify_coordinates_inputs_container .coordinates_inputs {
  border-radius: 6px;
  outline: none;
  padding-inline: 0.5vw;
  border-color: hsl(0, 0%, 50%);
  width: 45%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .radius_selector {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .Map_approval_button .approval_locaion_wrapper {
  display: flex;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_tick {
  width: 3%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .edit_mode_location_filter .location_popup_input .Map_approval_button .approval_locaion_wrapper .approval_locaion_wrapper {
  margin-left: auto;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter {
  display: flex;
  flex-direction: column;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .Location_tag {
  padding-top: 0.5vh;
  color: #000000;
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .location_not_applied_header {
  background: #FFFFFF;
  color: rgb(128, 146, 162);
  border-bottom: 1.25px solid rgb(59, 137, 254);
  padding: 6px;
  text-align: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .location_applied_header {
  background: #FFFFFF;
  color: rgb(128, 146, 162);
  padding: 6px;
  text-align: center;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .not_selected_map {
  background: #FFFFFF;
  position: relative;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .not_selected_map .no_location_map_text {
  background: #FFFFFF;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 40%;
}
.right_root_dashboard_container .root_dashboard_filters .main_filters_wrapper .right_root_filters .view_mode_location_filter .not_selected_map img {
  width: 100%;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container {
  display: flex;
  padding-top: 2vh;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  gap: 2.5vw;
  z-index: 1;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .tabs_value_items_text {
  font-size: larger;
  font-weight: 600;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .left_tabs_content {
  cursor: pointer;
  padding: 0.5vh 0px;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .left_tab_highlight {
  color: black;
  position: relative;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .left_tab_highlight img {
  filter: brightness(0) saturate(100%);
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .left_tab_highlight::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  width: 50%; /* Adjust this value to control the length of the border */
  height: 2px; /* Border thickness */
  background-color: #48A1FE; /* Border color */
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .left_tabs .left_tab_normal {
  color: #797979;
}
.right_root_dashboard_container .root_dashboard_article_analytics_tabs_container .right_tabs {
  margin-left: auto;
}

.article_draft_popup_content_from_root {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
  justify-content: center;
}
.article_draft_popup_content_from_root .article_draft_popup_header {
  font-weight: 600;
  font-size: large;
}
.article_draft_popup_content_from_root .root_draft_button {
  margin-top: 10%;
  display: flex;
  gap: 25%;
  width: 100%;
  justify-content: center;
}
.article_draft_popup_content_from_root .root_draft_button .button_main {
  background: #0E4FA7;
}
.article_draft_popup_content_from_root .root_draft_button .cancel {
  background: #C8C8C8;
}/*# sourceMappingURL=rightRootDashboard.css.map */