@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_stats_container {
  width: 84vw;
  padding: 2vh 2vw 2vh 2vw;
  height: 100vh;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  font-family: "Inter";
  background-color: #ffffff;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_stats_container .main_stats_wrapper {
  display: flex;
  gap: 1vw;
  align-items: center;
  z-index: 1;
  background: transparent;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat {
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(59, 137, 254, 0.3) 0%, rgba(208, 226, 250, 0.3) 100%);
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  padding: 25px;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper .title_stat {
  font-size: 16px;
  font-weight: 600;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper .data_stat {
  display: flex;
  gap: 2vw;
  align-items: center;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper .data_stat .desc {
  font-size: 16px;
  font-weight: 600;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper .data_stat .figure {
  font-size: 35px;
  font-weight: 700;
}
.right_stats_container .main_stats_wrapper .stat_section_wrapper .stat .stat_content_wrapper .data_stat .data_stat_right {
  margin-left: auto;
}/*# sourceMappingURL=statsRightView.css.map */