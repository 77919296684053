.root_analytics_container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.root_analytics_container .general_analytics_wrapper {
  border: 1px solid rgb(228, 228, 228);
  background: linear-gradient(246.98deg, #FFFFFF 0%, rgba(232, 232, 232, 0.26) 100%);
  border-radius: 15px;
  padding: 1.5vh 1vw;
}
.root_analytics_container .no_results_container {
  display: flex;
  flex-direction: column;
  padding: 7vh 0px;
  width: 100%;
}
.root_analytics_container .no_results_container .child_no_results {
  margin: auto;
}
.root_analytics_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.root_analytics_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.root_analytics_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.root_analytics_container .title_analytics_data {
  text-align: center;
  font-weight: 600;
  font-size: large;
}
.root_analytics_container .heat_map_container {
  display: flex;
  justify-content: space-between;
  gap: 2vw;
}
.root_analytics_container .heat_map_container .region_selector {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container {
  margin-bottom: 1vh;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections {
  display: flex;
  gap: 0.5vw;
  align-items: center;
  position: relative;
  padding: 0.5vh 1vw;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgb(59, 137, 254);
  background: #FFFFFF;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .region_main_text {
  color: rgb(59, 137, 254);
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .region_dropdown_close {
  transform: rotate(180deg);
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .region_dropdown_close, .root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .region_dropdown {
  filter: invert(47%) sepia(89%) saturate(1817%) hue-rotate(197deg) brightness(95%) contrast(113%);
  margin-left: auto;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .dropdown_container {
  width: 100%;
  position: absolute;
  top: calc(100% + 10px); /* Adjust the top value as needed */
  left: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 6;
  max-height: 30vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .dropdown_container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .dropdown_main_category {
  padding: 1vh 1vw 1vh 1vw;
  text-align: center;
  color: #3B89FE;
  background-color: #ffffff;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .dropdown_main_category:hover {
  box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
}
.root_analytics_container .heat_map_container .region_selector .region_dropdown_container .region_selections .dropdown_main_category:active {
  box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.root_analytics_container .heat_map_container .region_selector .map_range {
  margin-top: auto;
  padding: 6px;
}
.root_analytics_container .heat_map_container .map_container .title_analytics_data {
  text-align: center;
  font-weight: 600;
  font-size: large;
}
.root_analytics_container .heat_map_container .map_container .heatmap_container {
  margin-left: auto;
  width: 41vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.root_analytics_container .heat_map_container .map_container .heatmap_container .state_codes_with_count {
  border-radius: 3px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
}
.root_analytics_container .heat_map_container .top_stats {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  gap: 2vh;
}
.root_analytics_container .heat_map_container .top_stats .top_stats_header {
  font-weight: 600;
  font-size: large;
}
.root_analytics_container .heat_map_container .top_stats .states_name {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
}
.root_analytics_container .heat_map_container .top_stats .states_name .state_name {
  display: flex;
  gap: 0.5vw;
}
.root_analytics_container .heat_map_container .top_stats .states_name .state_name .serial {
  color: rgb(128, 146, 162);
}
.root_analytics_container .heat_map_container .top_stats .states_name .state_name .state {
  font-weight: 600;
  color: rgb(45, 45, 45);
}
.root_analytics_container .bubble_chart_container {
  display: flex;
  gap: 2%;
}
.root_analytics_container .bubble_chart_container .bubble_graph_container {
  width: 70%;
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats {
  flex: 30%;
  padding: 5vh 2vw;
  display: flex;
  gap: 2vh;
  flex-direction: column;
  align-items: center;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats .header_bubble_graph_stats {
  text-align: center;
  font-size: large;
  font-weight: 600;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats .bubble_graph_stats_main_content {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 20% 0px;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats .bubble_graph_stats_main_content .bubble_graph_stats_main_content_child {
  display: flex;
  gap: 0.5vw;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats .bubble_graph_stats_main_content .bubble_graph_stats_main_content_child .stat_field {
  font-weight: 600;
}
.root_analytics_container .bubble_chart_container .bubble_graph_stats .get_all_cluster_articles {
  margin-top: auto;
}
.root_analytics_container .time_series_graph_container {
  display: flex;
  gap: 2%;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}
.root_analytics_container .time_series_graph_container .time_series_graph_wrapper {
  width: 70%;
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats {
  flex: 30%;
  padding: 5vh 2vw;
  display: flex;
  gap: 2vh;
  flex-direction: column;
  align-items: center;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats .header_time_series_graph_stats {
  text-align: center;
  font-size: large;
  font-weight: 600;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats .time_series_graph_stats_main_content {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: 20% 0px;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats .time_series_graph_stats_main_content .time_series_graph_stats_main_content_child {
  display: flex;
  gap: 0.5vw;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats .time_series_graph_stats_main_content .time_series_graph_stats_main_content_child .stat_field {
  font-weight: 600;
}
.root_analytics_container .time_series_graph_container .time_series_graph_stats .get_all_cluster_articles {
  margin-top: auto;
}

.article_cluster_content_from_root_bubble .article_cluster_popup_header {
  font-weight: bold;
  text-align: center;
  font-size: large;
}
.article_cluster_content_from_root_bubble .article_cluster_popup_header span {
  color: #0C55B0;
}
.article_cluster_content_from_root_bubble .articles_cluster_table {
  display: flex;
  margin-top: 2vh;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container {
  width: 100%;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .no_results_container {
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .no_results_container .child_no_results {
  margin: auto;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .no_results_container .no_results {
  height: 25vh;
  background: #EFF5FF;
  border-radius: 25px;
  padding: 6vh;
  box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .no_results_container .no_results_header {
  margin-top: 2vh;
  color: #8080A2;
  font-size: x-large;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .no_results_container .no_results_subheader {
  color: #848484;
  font-size: large;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .arrow {
  height: 33px;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .dot {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.7vw;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .dot:before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #3B89FE;
  width: 0.8vw;
  height: 2.1vh;
  border: 5px solid #3B89FE;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .view_articles_heatmap {
  position: relative;
  z-index: 0;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .pagination_container {
  background: transparent;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 0px 0px 6px 6px;
  padding: 2vh;
  border-top: 1px solid #C8C8C8;
  justify-content: center;
  align-items: center;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .pagination_container .display_pages {
  font-family: "Inter";
  display: flex;
  align-items: center;
  margin-right: auto;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .pagination_container .display_pages span {
  font-weight: 600;
}
.article_cluster_content_from_root_bubble .articles_cluster_table .table_container .pagination_container .nav_container {
  font-family: "Inter";
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}/*# sourceMappingURL=rootAnalytics.css.map */