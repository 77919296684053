@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_keywords_report_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 3vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.right_keywords_report_container .filters_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.right_keywords_report_container .filters_container .keywords_subheader {
  font-weight: 600;
  font-size: x-large;
}
.right_keywords_report_container .filters_container .date_container {
  display: flex;
  gap: 1vw;
  align-items: center;
}
.right_keywords_report_container .filters_container .date_labels {
  font-weight: 600;
  color: #3B89FE;
}
.right_keywords_report_container .filters_container .from_date, .right_keywords_report_container .filters_container .to_date {
  display: flex;
}
.right_keywords_report_container .filters_container .from_date_popup_input, .right_keywords_report_container .filters_container .to_date_popup_input {
  display: flex;
}
.right_keywords_report_container .filters_container .input_search_filter_input_from_Date, .right_keywords_report_container .filters_container .input_search_filter_input_to_Date {
  flex: auto;
  border: none;
  outline: none;
  color: hsl(0, 0%, 50%);
  background: rgb(250, 250, 250);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: 10px;
}
.right_keywords_report_container .filters_container .input_search_filter_input_from_Date::-webkit-calendar-picker-indicator, .right_keywords_report_container .filters_container .input_search_filter_input_to_Date::-webkit-calendar-picker-indicator {
  filter: invert(45%);
}
.right_keywords_report_container .filters_container .to_text {
  font-size: large;
}
.right_keywords_report_container .apply_button {
  margin-left: auto;
}
.right_keywords_report_container .preview_button {
  background: rgb(14, 79, 167);
  color: #FFFFFF;
  padding-inline: 4vh;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  font-size: larger;
  font-family: "Inter";
}
.right_keywords_report_container .org_keywords_table_wrapper {
  display: flex;
  position: relative;
  margin-top: 4vh;
  gap: 6%;
  background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.0028) 77.37%);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2%;
  padding: 3% 4% 3% 4%;
  height: 72vh;
  background: transparent;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords {
  width: 70%;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .subheader_user_profile {
  font-weight: 600;
  font-size: larger;
  text-align: center;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_search {
  display: flex;
  gap: 1vw;
  justify-content: center;
  border: 2px solid #E5E5E6;
  border-radius: 6px;
  padding: 1vh 1vw 1vh 1vw;
  background: #FFFFFF;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_search .total_keywords_select {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #3B89FE;
  color: #ffffff;
  font-weight: 600;
  margin-right: 2vw;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_search .crud_add {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border: none;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_search .crud_add:focus {
  outline: none;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords {
  margin-top: 2vh;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .edit_button_profile, .right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .cross_button_profile {
  border: none;
  padding: initial;
  background: #ffffff;
  cursor: pointer;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .edit_button_profile:focus, .right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .cross_button_profile:focus {
  outline: none;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .profile_edit {
  width: 36%;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .concern_cell {
  padding: 4px;
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .concern_cell:focus {
  outline: None;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .unique_checkbox {
  cursor: pointer;
  padding: 6px;
  width: 2.7rem;
  height: 2.4vh;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.right_keywords_report_container .org_keywords_table_wrapper .crud_org_keywords .crud_table_org_keywords .unique_checkbox:hover {
  background-color: rgba(25, 118, 210, 0.04);
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters {
  display: flex;
  flex-direction: column;
  gap: 2.5%;
  padding: 25px;
  border-radius: 6px;
  text-align: left;
  width: 30%;
  background: linear-gradient(175.05deg, #EEF7FF -47.67%, #E9ECFE 121.75%);
  overflow: auto;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .subheader_user_profile {
  font-weight: 600;
  font-size: x-large;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .total_selections {
  font-weight: 600;
  display: flex;
  gap: 4%;
  align-items: center;
  font-size: larger;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .total_selections .total_number_none {
  font-size: smaller;
  color: rgb(28, 112, 200);
  border: 1px solid rgb(28, 112, 200);
  border-radius: 4px;
  padding: 0px 12px;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .total_selections .total_number {
  font-size: smaller;
  color: #FFFFFF;
  border-radius: 4px;
  padding: 0px 12px;
  background: rgb(14, 79, 167);
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .selections {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .selections .selections_keys {
  display: flex;
  gap: 6%;
  font-weight: 700;
}
.right_keywords_report_container .org_keywords_table_wrapper .show_applied_filters .selections .selections_keys .value_key {
  color: rgb(28, 112, 200);
}
.right_keywords_report_container .display_pages_org_keywords {
  color: rgb(133, 133, 133);
  justify-content: center;
  display: flex;
  padding-top: 1%;
  float: left;
  position: absolute;
}
.right_keywords_report_container .display_pages_org_keywords .pages_pagination {
  font-weight: bold;
}
.right_keywords_report_container .prev_next_org_keywords {
  justify-content: center;
  display: flex;
  gap: 2%;
  padding-top: 3%;
}
.right_keywords_report_container .prev_next_org_keywords .prev_next_buttons {
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  border: None;
  padding: 1% 1.5%;
}
.right_keywords_report_container .prev_next_org_keywords .prev {
  background: #3B89FE;
  color: #FFFFFF;
}
.right_keywords_report_container .prev_next_org_keywords .next {
  border: 2px solid #BDBDBD;
}

.keywords_popup_filter_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
}
.keywords_popup_filter_popup_content .dashboard_filters_header_Container {
  display: flex;
  justify-content: center;
}
.keywords_popup_filter_popup_content .dashboard_filters_header_Container h2 {
  all: unset;
  font-weight: bold;
  font-size: 2rem;
}
.keywords_popup_filter_popup_content .dashboard_filters_header_Container .clear_dashboard_filter {
  margin-left: auto;
  cursor: pointer;
  width: 5%;
}
.keywords_popup_filter_popup_content .input_search_filters {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.keywords_popup_filter_popup_content .input_search_filters .input_search_filter_labels {
  font-weight: bold;
}
.keywords_popup_filter_popup_content .input_search_filters .input_search_filter_input {
  box-shadow: 0px 0px 4px rgba(23, 25, 28, 0.05);
  border-radius: 8px;
  min-height: 5vh;
}
.keywords_popup_filter_popup_content .input_search_filters .from_date_popup_input, .keywords_popup_filter_popup_content .input_search_filters .to_date_popup_input {
  background: #FFFFFF;
  border: 1px solid #D8DDE3;
  box-shadow: 0px 0px 4px rgba(23, 25, 28, 0.05);
  display: flex;
  padding-left: 0.7vw;
  padding-right: 0.7vw;
}
.keywords_popup_filter_popup_content .input_search_filters .input_search_filter_input_from_Date, .keywords_popup_filter_popup_content .input_search_filters .input_search_filter_input_to_Date {
  flex: auto;
  border: none;
  outline: none;
  color: hsl(0, 0%, 50%);
}
.keywords_popup_filter_popup_content .input_search_filters .input_search_filter_input_from_Date::-webkit-calendar-picker-indicator, .keywords_popup_filter_popup_content .input_search_filters .input_search_filter_input_to_Date::-webkit-calendar-picker-indicator {
  filter: invert(45%);
}
.keywords_popup_filter_popup_content .apply_cancel_input_search_filters {
  margin-top: 4vh;
  display: flex;
  justify-content: center;
  gap: 2vw;
}
.keywords_popup_filter_popup_content .error_container {
  color: red;
  margin-top: 4vh;
  text-align: center;
  font-weight: bold;
}/*# sourceMappingURL=rightKeywordsReport.css.map */