@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
.left_dashboard_container {
  align-items: center;
  width: 16vw;
  justify-content: space-between;
  border-right: 1px solid rgba(59, 137, 254, 0.1);
  border: 1px solid rgba(59, 137, 254, 0.1019607843);
  box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.1215686275);
  background: white;
  height: 100vh;
  top: 0;
  position: sticky;
}
.left_dashboard_container .topic_container {
  margin-top: 3vh;
  text-align: center;
}
.left_dashboard_container .topic_container h3 {
  font-size: 30px;
  font-family: "DM Sans", sans-serif;
  color: #3B89FE;
  font-weight: bold;
}
.left_dashboard_container .elements_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
}
.left_dashboard_container .elements_wrapper .elements_container {
  margin-top: 6vh;
  width: 15vw;
  text-align: left;
}
.left_dashboard_container .elements_wrapper .elements_container .elements {
  margin-bottom: 1vh;
  border-radius: 0.3rem;
  padding: 1vh;
  padding-left: 2vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  cursor: pointer;
  position: relative;
}
.left_dashboard_container .elements_wrapper .elements_container .elements .open_icon {
  margin-left: auto;
  filter: brightness(0) saturate(100%);
  position: absolute;
  right: 8%;
  top: 46%;
}
.left_dashboard_container .elements_wrapper .elements_container .elements .close_icon {
  transform: rotate(180deg);
  filter: brightness(0) saturate(100%);
  position: absolute;
  right: 8%;
  top: 46%;
}
.left_dashboard_container .elements_wrapper .elements_container .elements .icons {
  height: 2.5vh;
  margin-right: 0.7vw;
}
.left_dashboard_container .elements_wrapper .elements_container .elements .icon1, .left_dashboard_container .elements_wrapper .elements_container .elements .icon2, .left_dashboard_container .elements_wrapper .elements_container .elements .icon3 {
  filter: invert(0%) sepia(94%) saturate(2%) hue-rotate(104deg) brightness(103%) contrast(97%);
}
.left_dashboard_container .elements_wrapper .elements_container .popup {
  width: 100%;
  padding: 20px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  background: linear-gradient(135deg, rgba(81, 170, 234, 0.9) 0%, rgba(28, 100, 208, 0.9) 100%);
}
.left_dashboard_container .elements_wrapper .elements_container .popup .dropdown_content {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-weight: 100;
  font-size: larger;
}
.left_dashboard_container .elements_wrapper .elements_container .popup .dropdown_1:hover, .left_dashboard_container .elements_wrapper .elements_container .popup .dropdown_2:hover, .left_dashboard_container .elements_wrapper .elements_container .popup .dropdown_3:hover, .left_dashboard_container .elements_wrapper .elements_container .popup .dropdown_4:hover {
  background-color: #FFFFFF;
  color: rgb(36, 113, 229);
  font-weight: bold;
  border-radius: 6px;
}
.left_dashboard_container .button_container {
  position: absolute;
  bottom: 10%;
}
.left_dashboard_container .button_container button {
  width: 16vw;
  color: black;
  font-weight: 600;
  background: #EEF6FF;
  border: 2px solid rgba(59, 137, 254, 0.2);
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.09);
}
.left_dashboard_container .button_container .button:hover {
  background: initial;
}
.left_dashboard_container .button_container .btn.active,
.left_dashboard_container .button_container .btn:active {
  background: #EEF6FF;
  color: initial;
  box-shadow: none;
}
.left_dashboard_container .button_container .btn:active:focus {
  box-shadow: initial;
}/*# sourceMappingURL=left_dashboard.css.map */