@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_profile_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 4vh;
    font-family: 'Inter';
    background: #FFFFFF;
    position: relative;
    overflow: hidden;
    // .my_profile_dropdown{
    //     position: absolute;
    //     right: 4vw;
    //     top: 1vh;
    //     cursor: pointer;
    //     // font-weight: bold;

    //     .open_d{
    //         padding-left: 0.5vw;
    //     }   

    //     .dropdown_main{
    //         // border: 1px solid;
    //         padding: 1vh 1vw 1vh 1vw;
    //         text-align: center;
    //         color: #3B89FE;
    //     }
    //     .welcome_d{
    //         background-color: #F3F8FF;
    //     }
    //     .profile_d, .logout_d{
    //         border: 1px solid #F1F1F1;
    //         border-top: 0;
    //         // border-color:#F1F1F1;
    //     }
    //     .profile_d:hover, .logout_d:hover{
    //         box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    //     }
    //     .profile_d:active, .logout_d:active{
    //         box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    //     }

    // }
    .headers{
        display: flex;
        gap: 5vw;
        // z-index: 1;
        position: relative;
        backdrop-filter: blur(4px);
        .header_container{
            font-weight: 400;
            font-size: 20px;
            padding-bottom: 1vh;
            cursor: pointer;
        }
        .activeHeader{
            box-shadow: inset 0px -2px 0px #2B6ED3;
        }
    }
    .profile_page_wrapper{
        position: relative;
        backdrop-filter: blur(4px);
        .right_profile_1{
            display: flex;
            margin-top: 6vh;
            padding: 15px 15px 45px 15px;
            border: 1px solid;
            gap: 15px;

            background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0) 77.37%);
            border: 1px solid #ECECEC;
            // backdrop-filter: blur(6.5px);
            border-radius: 22px;
            min-height: 67vh;

    
            .subheader_user_profile{
                font-weight: 700;
                font-size: 28px;
                // margin-top: 3vh;
                text-align: center;
            }
    
            .user_details_container{
                display: flex;
                flex-direction: column;    
                border: 1px solid;   
                flex: 3.5; 
                align-items: center; 
                gap: 2vh;
                border: 1px solid #D0E7FE;
                box-shadow: 0px 15px 34px 0px #00000012;
                border-radius: 5px;
                background: linear-gradient(168deg, rgba(147, 192, 239, 0.12) 0%, rgba(226, 239, 252, 0.425) 84.58%, rgba(226, 239, 252, 0.41) 91.24%);
                padding: 20px 10px;
                justify-content: space-between;
                .user_profile_logo{
                    .ellipse_logo{
                        height: 128px;
                        width: 128px;
                        border-radius: 50%;
                        border: 1px solid #2E46CD;
                        background: #2E46CD;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: 45px;
                        align-content: center;
                        flex-wrap: wrap;
                        justify-content: center;
                        display: flex;
                        border: 7px solid #FFFFFF;
                    }
                }
                .user_details{
                    display: flex;
                    align-items: center;
                    padding: 14px 10px;
                    background: rgba(132, 182, 235, 0.19);
                    border-radius: 8px;
                    width: 100%;
                    .user_details_main{
                        display: flex;
                        flex-direction: column;
                        gap: 3vh;
                        .user_details_content{
                            display: flex;
                            gap: 0.5vw;
                            font-size: larger;
                            span{
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
    
            .first_layer_profile{
                border: 1px solid;
                flex: 6.5;
                display: flex;
                flex-direction: column;    
                align-items: center; 
                gap: 2vh;
                border: 1px solid #D0E7FE;
                box-shadow: 0px 15px 34px 0px #00000012;
                border-radius: 5px;
                background: linear-gradient(168deg, rgba(147, 192, 239, 0.12) 0%, rgba(226, 239, 252, 0.425) 84.58%, rgba(226, 239, 252, 0.41) 91.24%);
                padding: 20px 10px;
                justify-content: space-between;
                // width: 100%;
                .pass_reset_container{
                    width: 100%;
                    padding-inline: 5vw;
                    .grid_password{
                        display: flex;
                        flex-direction: column;
                        gap: 2vh;
                        .curr_pass_profile, .new_pass_profile{
                            position: relative;
                            .eye_icon_container{
                                position: absolute;
                                right: 0.5vw;
                                top: 58%;
                                transform: translateY(50%);
                                cursor: pointer;
                            }
                        }
                        .elems{
                            padding-top: 1vh;
                            margin-right: 18px;
                        }
                        label{
                            font-size: 18px;
                            font-weight: bold;
                            margin-bottom: 0.3rem;
                        }
                        .profile_inputs{
                            border: 1px solid #1C70C833;
                            background: #FFFFFF;
                            border-radius: 8px;
                            height: 7vh;
                            font-size: 20px;
                        } 
                    }
                    .submit_container{
                        display: flex;
                        gap: 1vw;
                        justify-content: center;
                        button{
                            width: 11vw;
                            padding: 7px;
                        }
                        .cancel{
                            background: #E9E9E9;
                        }
                        .update{
                            background: #0E4FA7;
                        }
                    }
                }
            }
        }
    
        .right_profile_2{
            width: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
            .crud_keywords{
                width: auto;
                // margin-top: 3vh;
                // padding: 20px;
                padding: 3% 0 3% 0;
                padding-inline: 6%;
                // padding-top: 2vh;
                background: #FFFFFF;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
                border-radius: 2%;
                // border: 1px solid;
                .subheader_user_profile{
                    font-weight: 600;
                    font-size: large;
                    text-align: center;
                    // margin-top: 3vh;
                }
    
                .crud_search{
                    display: flex;
                    gap: 1vw;
                    margin-top: 1vh;
                    .crud_add{
                        padding-inline-start: 0.5vw;
                        font-weight: 400;
                        font-size: 16px;
                        border: 2px solid #E5E5E6;
                        border-radius: 6px;
                        color: rgba(0, 0, 0, 0.87);
                    }
                    .crud_add:focus{
                        outline: none;
                        box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
                    }
                    
                }
                .crud_table{
                    margin-top: 2vh;
                    .cross_button_profile{
                        border: none;
                        padding: initial;
                        background: #ffffff;
                        cursor: pointer;
                    }
                    .cross_button_profile:focus{
                        outline: none;
                        // box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
                    }
                }
                .total_count_container{
                    // border: 1px solid #E5E5E6;
                    // border-radius: 6px;
                    // width: fit-content;
                    // padding: 2px;
                    // margin-top: 2%;
                    justify-content: right;
                    display: flex;
                    padding-top: 3%;
                    .total_count{
                        border: 1px solid #E5E5E6;
                        width: fit-content;
                        // border-radius: 4%;
                        padding: 0.5%;
                        padding-inline: 3%;
                        /* color: #E5E5E6; */
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    .sources_page_wrapper{
        margin-top: 6vh;
        position: relative;
        backdrop-filter: blur(4px);

        // padding: 15px 15px 15px 15px;
        // background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0) 77.37%);
        // border: 1px solid #ECECEC;
        // border-radius: 22px;
        // min-height: 67vh;
        
        .crud_sources{
            width: 90%;
            // margin-top: 3vh;
            // padding: 20px;
            // padding: 3% 0 3% 0;
            // padding-inline: 1.5%;
            // padding-top: 2vh;
            // background: #FFFFFF;
            // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
            // border-radius: 2%;

            padding: 3vh 5vw;
            // background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0) 77.37%);
            background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
            // background: linear-gradient(219.6deg, rgba(232, 232, 232, 0.2) -16.67%, #FFFFFF 77.37%);

            border: 1px solid #ECECEC;
            border-radius: 22px;
            // border: 1px solid;

            .sources_header{
                display: flex;
                align-items: center;
            }

            .subheader_user_profile{
                font-weight: 600;
                font-size: large;
            }

            .crud_search{
                display: flex;
                gap: 1vw;
                border: 2px solid #E5E5E6;
                border-radius: 9px;
                padding: 1vh 1vw 1vh 1vw;
                background: #FFFFFF;
                margin-left: auto;
                width: 50%;
                .total_keywords_select{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    padding: 10px;
                    background: #3B89FE;
                    color: #ffffff;
                    font-weight: 600;
                    margin-right: 2vw;
                }
                .crud_add{
                    width: 100%;
                    font-weight: 400;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.87);
                    border: none;
                }
                .crud_add:focus{
                    outline: none;
                }
            }

            .crud_table_sources{
                margin-top: 2vh;
                .cross_button_profile{
                    border: none;
                    padding: initial;
                    background: #ffffff;
                    cursor: pointer;
                }
                .cross_button_profile:focus{
                    outline: none;
                    // box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
                }
                .concern_cell{
                    padding: 4px;
                    border:  1px solid #C8C8C8;
                    box-shadow: 0px 4px 15px 0px #00000014;
                    border: 0.5px solid #4C94E0;
                    border-radius: 6px;
                    text-align: center;
                    cursor: pointer;
                    width: 50%;
                }
                .concern_cell:focus{
                    outline: None;
                }
                .whitelist_concern_cell{
                    background:#0E4FA7;
                    color: #FFFFFF;
                }
                .blacklist_concern_cell{
                    background:#FFFFFF;
                    color: #0E4FA7;
                }

            }
        }
        .sources_pagination_container{
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            padding: 2vh;
            width: 90%;
            .display_pages{
                font-family: 'Inter';
                display: flex;
                align-items: center;
                // position: absolute;
                left: 1vw;
                color: #858585;
                span{
                    font-weight: 600;       
                }
            }
            .nav_container{
                // margin-left: 10vw;
                display: flex;
                justify-content: center;
                font-family: 'Inter';
                width: auto;
                height: auto;

            }
        }
    }

    .categories_page_wrapper{
        margin-top: 6vh;
        position: relative;
        backdrop-filter: blur(4px);
        .crud_categories{
            width: 90%;
            padding: 3vh 5vw;
            background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
            border: 1px solid #ECECEC;
            border-radius: 22px;

            .categories_header{
                display: flex;
                align-items: center;
                .subheader_user_profile{
                    font-weight: 600;
                    font-size: large;
                    text-align: center;
                }
                .crud_search{
                    display: flex;
                    gap: 1vw;
                    border: 2px solid #E5E5E6;
                    border-radius: 9px;
                    padding: 1vh 1vw 1vh 1vw;
                    background: #FFFFFF;
                    margin-left: auto;
                    width: 50%;
                    .total_keywords_select{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        padding: 10px;
                        background: #3B89FE;
                        color: #ffffff;
                        font-weight: 600;
                        margin-right: 2vw;
                    }
                    .crud_add{
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                        border: none;
                    }
                    .crud_add:focus{
                        outline: none;
                    }
                }
            }

            .crud_table_categories{
                margin-top: 2vh;
                .concern_cell{
                    padding: 4px;
                    box-shadow: 0px 4px 15px 0px #00000014;
                    border: 0.5px solid #4C94E0;
                    border-radius: 6px;
                    text-align: center;
                    cursor: pointer;
                }             
                .interested_concern_cell{
                    background:#0E4FA7;
                    color: #FFFFFF;
                }
                .makeinterested_concern_cell{
                    background:#FFFFFF;
                    color: #0E4FA7;
                }
                .default_concern_cell{
                    background:#0E4FA7;
                    color: #FFFFFF;
                }
                .makedefault_concern_cell{
                    background:#FFFFFF;
                    color: #0E4FA7;
                }
            }
            .total_count_container{
                // border: 1px solid #E5E5E6;
                // border-radius: 6px;
                // width: fit-content;
                // padding: 2px;
                // margin-top: 2%;
                justify-content: right;
                display: flex;
                padding-top: 3%;
                .total_count{
                    border: 1px solid #E5E5E6;
                    width: fit-content;
                    // border-radius: 4%;
                    padding: 0.5%;
                    padding-inline: 3%;
                    /* color: #E5E5E6; */
                    border-radius: 6px;
                }
            }
        }
        .categories_pagination_container{
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            padding: 2vh;
            width: 90%;
            .display_pages{
                font-family: 'Inter';
                display: flex;
                align-items: center;
                // position: absolute;
                left: 1vw;
                color: #858585;
                span{
                    font-weight: 600;       
                }
            }
            .nav_container{
                // margin-left: 10vw;
                display: flex;
                justify-content: center;
                font-family: 'Inter';
                width: auto;
                height: auto;

            }
        }
    }

    .keywords_page_wrapper{
        position: relative;
        backdrop-filter: blur(4px);
        margin-top: 6vh;
        .crud_keywords{
            width: 64%;
            padding: 3vh 5vw;
            background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
            border: 1px solid hsl(0, 0%, 93%);
            border-radius: 22px;
            .keywords_header{
                display: flex;
                align-items: center;
                .subheader_user_profile{
                    font-weight: 600;
                    font-size: large;
                    text-align: center;
                }
                .crud_search{
                    display: flex;
                    gap: 1vw;
                    border: 2px solid #E5E5E6;
                    border-radius: 9px;
                    padding: 1vh 1vw 1vh 1vw;
                    background: #FFFFFF;
                    margin-left: auto;
                    width: 70%;
                    .total_keywords_select{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        padding: 10px;
                        background: #3B89FE;
                        color: #ffffff;
                        font-weight: 600;
                        margin-right: 2vw;
                    }
                    .crud_add{
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                        border: none;
                    }
                    .crud_add:focus{
                        outline: none;
                    }
                }
            }
            .crud_table{
                margin-top: 2vh;
                .cross_button_profile{
                    border: none;
                    padding: initial;
                    background: #ffffff;
                    cursor: pointer;
                }
                .cross_button_profile:focus{
                    outline: none;
                    // box-shadow: 0 0 0 0.2rem rgba(108,117,125,0.5);
                }
            }
            .genrateReport{
                margin-top: 2vh;
            }
        }
        .keywords_pagination_container{
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            position: relative;
            padding: 2vh;
            width: 64%;
            .display_pages{
                font-family: 'Inter';
                display: flex;
                align-items: center;
                // position: absolute;
                left: 1vw;
                color: #858585;
                span{
                    font-weight: 600;       
                }
            }
            .nav_container{
                // margin-left: 10vw;
                display: flex;
                justify-content: center;
                font-family: 'Inter';
                width: auto;
                height: auto;

            }
        }
    }

    .org_keywords_page_wrapper{
        margin-top: 6vh;
        position: relative;
        backdrop-filter: blur(4px);
        .crud_org_keywords{
            width: 64%;
            // margin-top: 3vh;
            // padding: 20px;
            // padding: 3% 0 3% 0;
            // padding-inline: 1.5%;
            // padding-top: 2vh;
            // background: #FFFFFF;
            // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
            // border-radius: 2%;

            padding: 3vh 5vw;
            // background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0) 77.37%);
            background: linear-gradient(219.6deg, #FFFFFF -16.67%, rgba(232, 232, 232, 0.2) 77.37%);
            // background: linear-gradient(219.6deg, rgba(232, 232, 232, 0.2) -16.67%, #FFFFFF 77.37%);

            border: 1px solid #ECECEC;
            border-radius: 22px;
            // border: 1px solid;
            .org_keywords_header{
                display: flex;
                align-items: center;
                .subheader_user_profile{
                    font-weight: 600;
                    font-size: large;
                    text-align: center;
                }
                .crud_search{
                    display: flex;
                    gap: 1vw;
                    border: 2px solid #E5E5E6;
                    border-radius: 9px;
                    padding: 1vh 1vw 1vh 1vw;
                    background: #FFFFFF;
                    margin-left: auto;
                    width: 70%;
                    .total_keywords_select{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        padding: 10px;
                        background: #3B89FE;
                        color: #ffffff;
                        font-weight: 600;
                        margin-right: 2vw;
                    }
                    .crud_add{
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                        border: none;
                    }
                    .crud_add:focus{
                        outline: none;
                    }
                }
            }
            .crud_table_org_keywords{
                margin-top: 2vh;
                .add_org_keyword_icon{
                    width: 6%;
                    cursor: pointer;
                }
                .edit_button_profile, .cross_button_profile{
                    border: none;
                    padding: initial;
                    background: #ffffff;
                    cursor: pointer;
                }
                .edit_button_profile:focus, .cross_button_profile:focus{
                    outline: none;
                }
            }
        }
        
    .org_keywords_pagination_container{
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        position: relative;
        padding: 2vh;
        width: 64%;
        .display_pages{
            font-family: 'Inter';
            display: flex;
            align-items: center;
            // position: absolute;
            left: 1vw;
            color: #858585;
            span{
                font-weight: 600;       
            }
        }
        .nav_container{
            // margin-left: 10vw;
            display: flex;
            justify-content: center;
            font-family: 'Inter';
            width: auto;
            height: auto;

        }
    }
    }
}

.profile_org_keyword_update_popup_content{
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    align-items: center;
    .profile_org_keyword_update_popup_header{
        font-weight: 600;
        font-size: larger;
    }
    .profile_org_keyword_update_input_bar_container{
        width: 100%;
        margin-top: 10%;
        .profile_org_keyword_update_input{
            border: 1px solid #D4D4D4;
            border-radius: 9px;  
            outline: none; 
            padding: 8px 8px;
            width: 100%;
        }

    }
    .profile_org_keyword_update_add_draft_button{
        margin-top: 10%;
        display: flex;
        gap: 25%;
        width: 100%;
        justify-content: center;    
        .button_main{
            background: #0E4FA7;
        }
        .cancel{
            background: #C8C8C8;
        }
    }
}