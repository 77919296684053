.root_articles_container{
    .table_container{
        // width: 75vw;
        // margin-top: 2vh;
        position: relative;
        z-index: 0;
        @media (max-width:768px){
            td{
               font-size: smaller;
            }
         }         

        .translate_text_icon{
            width: 3.8%;
            cursor: pointer;
        }
        
        .no_results_container{
            display: flex;
            flex-direction: column;
            margin-top: 10vh;
            .child_no_results{
                margin: auto;
            }
            .no_results{
                height: 25vh;
                background: #EFF5FF;
                border-radius: 25px;
                padding: 6vh;
                box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
            }
            .no_results_header{
                margin-top: 2vh;
                color: #8080A2;
                font-size: x-large;
            }
            .no_results_subheader{
                color: #848484;
                font-size: large;
            }
        }

        .dot {
            color: green;
        }
            
        .dot_negative {
            color: red;
        }
            
        .pagination_container{
            display: flex;
            background: #ffffff;
            border-radius: 8px ;
            box-shadow: 0px 20px 50px 0px #0000001a;
            border-radius: 0px 0px 6px 6px;
            padding: 2vh;
            border-top: 1px solid #C8C8C8;
            justify-content: center;
            align-items: center;
            .display_pages{
                font-family: 'Inter';
                display: flex;
                align-items: center;
                position: absolute;
                left: 1vw;
                span{
                    font-weight: 600;       
                }
            }
            .nav_container{
                font-family: 'Inter';
                display: flex;
                align-items: center;
            }
        }
    }
}