@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');
.right_report_gen_articles_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 3vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    .filter_container{
        display: flex;
        .filter_content_container{
            display: flex;
            position: relative;
            align-items: center;
            width: 100%;
            gap: 2vw;
            position: relative;

            .rep_gen_header{
                font-weight: 600;
                font-size: x-large;
            }

            .report_gen_filter{
                display: flex;
                margin-left: auto;
                gap: 15%;
                // position: relative;
                cursor: pointer;
                .filter_title{
                    font-weight: 600;
                    font-size: large;
                }
                .absolute_filters_main{
                    position: absolute;
                    top: 110%; /* Position below the parent div */
                    right: 0;
                    width: 50%; /* Full width of the parent */
                    background-color: #F9F9F9;; /* Sample background color */
                    z-index: 2; /* Ensure a higher z-index for it to appear over other elements */
                    border: 1px solid #B2D5FA;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    .header_section{
                        padding: 5px 10px 2vh 10px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #E3E3E3;
                        .header_title{
                            font-weight: 700;
                        }
                        .cross_modal{
                            margin-left: auto;
                        }
                    }
                    .main_filters_report{
                        display: flex;
                        border-bottom: 1px solid #E3E3E3;
                        .left_main_filters_report{
                            display: flex;
                            flex-direction: column;
                            border: 1px solid #E9E9E9;
                            background: #F4F3F3;
                            box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.06);
                            flex: 3;
                            .indi_filter{
                                padding: 15px 20px;
                                font-weight: 700;
                                cursor: pointer;
                                display: flex;    
                                .selected_filter_temporary{
                                    margin-left: auto;
                                }
                            }
                            .highlight_indi_filter{
                                color: #1C5BB9;
                                background: #FFFFFF;
                                border-left: 3px solid#1C5BB9; 
                            }
                            .indi_filter:hover{
                                background: #FFFFFF;
                                border-left: 3px solid#1C5BB9;
                            }
                        }
                        .right_main_filters_report{
                            padding: 10px ;
                            flex: 7;
                            .filter_value_1{
                                display: flex;
                                justify-content: center;
                                .filter_value_1_input{
                                    width: 80%;
                                }
                            }
                            .filter_value_2{
                                display: flex;
                                justify-content: center;
                                .filter_value_2_input{
                                    width: 80%;
                                }
                            }
                            .filter_value_3{
                                display: flex;
                                flex-direction: column;
                                gap: 2vh;
                                height: 100%;
                                .search_selector{
                                    display: flex;
                                    gap: 10%;
                                    background: #E8F3FF;
                                    border-radius: 6px;
                                    box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
                                    .search_types{
                                        flex: 1;
                                        display: flex;
                                        gap: 5%;
                                        align-items: center;
                                    }
                                    .selector_text{
                                        font-weight: 700;
                                    }
                                    .selector_text_highlight{
                                        color: #11569F;
                                    }
                                    .selector_text_no_highlight{
                                        color: #989898;
                                    }
                                }
                                .search_filters_selections{
                                    display: flex;
                                    gap: 2%;
                                    .search_filters_selections_first{
                                        flex: 4;
                                    } 
                                    .search_filters_selections_second{
                                        flex: 6;
                                    }
                                }
                                .search_keyword_conditions{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1vh;
                                    .header_condition{
                                        font-weight: bold;
                                    }
                                    .conditions_main{
                                        display: flex;
                                        flex-direction: column;
                                        gap: 2.5vh;
                                        .condition_wrapper{
                                            display: flex;
                                            gap: 2%;
                                            width: 80%;
                                            background: #FAFCFF;
                                            border: 0.5px solid #2275F04A;
                                            border-radius: 11px;
                                            padding-inline: 1.5vw;
                                            align-items: center;
                                            .radio_wrapper{
                                                display: flex;
                                                gap: 5%;
                                                flex: 1;
                                                color: #2D2D2D;
                                                font-weight: 600;
                                            }
                                        }
                                    }
                                }

                                .crud_org_keywords{           
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1.2vh;
                                    height: 100%;
                                    .crud_search{
                                        display: flex;
                                        gap: 1vw;
                                        justify-content: center;
                                        border: 2px solid #E5E5E6;
                                        border-radius: 6px;
                                        padding: 1vh 1vw 1vh 1vw;
                                        background: #FFFFFF;
                                        .crud_add{
                                            width: 100%;
                                            font-weight: 400;
                                            font-size: 16px;
                                            color: rgba(0, 0, 0, 0.87);
                                            border: none;
                                        }
                                        .crud_add:focus{
                                            outline: none;
                                        }
                                        
                                    }
                                    .crud_table_org_keywords{
                                        // margin-top: 2vh;
                                        .edit_button_profile, .cross_button_profile{
                                            border: none;
                                            padding: initial;
                                            background: #ffffff;
                                            cursor: pointer;
                                        }
                                        .edit_button_profile:focus, .cross_button_profile:focus{
                                            outline: none;
                                        }
                                        .profile_edit{
                                            width: 36%;
                                        }
                                        .concern_cell{
                                            padding: 4px;
                                            border:  1px solid #C8C8C8;
                                            border-radius: 6px;
                                            text-align: center;
                                            cursor: pointer;
                                        }
                                        .concern_cell:focus{
                                            outline: None;
                                        }
                                        .unique_checkbox{
                                            cursor : pointer;
                                            padding : 6px;
                                            width: 2.7rem;
                                            height: 2.4vh;
                                            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                        }
                                        .unique_checkbox:hover{
                                            background-color: rgba(25, 118, 210, 0.04);
                                        }
                                    }
                                    .nav_container{
                                        justify-content: center;
                                        display: flex;
                                        gap: 2%;
                                        // padding-top: 3%;
                                    }
                                    .selected_count{
                                        display: flex;
                                        align-items: center;
                                        margin-top: auto;
                                        .total_selected_display{
                                            font-weight: 700;
                                            border-radius: 9px;
                                            border: 1px solid #EDEDED;
                                            background: linear-gradient(182deg, #EEF7FF -54.29%, #E9ECFE 142.37%);
                                            padding: 3px 6px;
                                            span{
                                                color: #0F69E3;
                                            }
                                        }
                                        .reset_feature{
                                            background: #FFFFFF;
                                            color: black;
                                            border: 1px solid #0F69E3;
                                            margin-left: auto;
                                        }
                                    }
                                }
                            }

                            .filter_value_4{
                                .location_value_new{
                                    flex: 1;
                                    .location_popup_input{
                                        display: flex;
                                        flex-direction: column;
                                        gap: 6px;
                                        align-items: center;
                                        .select_location_dropdown{
                                            width: 80%;
                                        }
                                        .verify_location_inputs_container{
                                            display: flex;
                                            gap: 6px;
                                            width: 80%;
                                            .location_inputs{
                                                border-radius: 6px;
                                                outline: none;
                                                padding-inline: 0.5vw;
                                                border-color: hsl(0, 0%, 50%);
                                                flex: 8;
                                            }
                                        }
                                        .verify_coordinates_inputs_container{
                                            display: flex;
                                            gap: 6px;
                                            width: 80%;
                                            .coordinates_inputs{
                                                border-radius: 6px;
                                                outline: none;
                                                padding-inline: 0.5vw;
                                                border-color: hsl(0, 0%, 50%);
                                                width: 45%;
                                            }
                                        }
                                        .verify_states_inputs_container{
                                            width: 80%;
                                        }
                                        .radius_selector{
                                            display: flex;
                                            gap: 6px;
                                            align-items: center;
                                            justify-content: center;
                                            width: 80%;
                                        }
                                        .map{
                                            width: 100%;
                                        }
                                        .leaflet-container{
                                            // width: 316px;
                                            height: 174px;                
                                        }
                                        .Map_approval_button{
                                            width: 80%;
                                            .approval_locaion_wrapper{
                                                display: flex;
                                                margin-top: 1vh;
                                                .approval_tick{
                                                    width: 3%;
                                                }
                                                .approval_locaion_wrapper{
                                                    margin-left: auto;
                                                    background: #114DA8;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .filter_value_5{
                                display: flex;
                                justify-content: center;
                                .filter_value_5_input{
                                    width: 80%;
                                }
                            }
                            .filter_value_6{
                                display: flex;
                                justify-content: center;
                                .filter_value_6_input{
                                    width: 80%;
                                }
                            }
                            .filter_value_7{
                                display: flex;
                                flex-direction: column;
                                gap: 1vh;
                                .filter_value_7_value_1{
                                    display: flex;
                                    gap: 2%;
                                    span{
                                        font-weight: 600;
                                    }
                                }
                                .filter_value_7_value_2{
                                    border-radius: 6px;
                                    border: 0.5px solid #238FFF;
                                    background: #FAFCFF;
                                    padding: 4px;
                                    span{
                                        color: #1C70C8;
                                        font-weight: 600;
                                    }
                                }
                            }
                            .filter_value_8{
                                display: flex;
                                justify-content: center;
                                .filter_value_8_input{
                                    width: 80%;
                                }
                            }
                        }
                    }
                    .cancel_apply_filters{
                        display: flex;
                        padding: 10px 25px;
                        .buttons_container{
                            display: flex;
                            gap: 1.5vw;
                            margin-left: auto;   
                        }
                        .cancel{
                            border: 1px solid #0E4FA7;
                            color: #000000;
                            background-color: #FFFFFF;
                        }
                        .apply{
                            background-color: #0E4FA7;
                        }
                    }
                }
            }
            
            .articles_category{
                margin-left: auto;
                .articles{
                    font-family: "Inter";                   
                    background-color: rgba(250, 250, 250, 1);
                    color: #000000;
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
                    padding: 0.8vh 0.8vw 0.8vh 0.8vw;
                    outline: none;
                    border-radius: 11px;
                    border: 1px solid rgba(237, 237, 237, 1);
                    font-weight: 700;
                    .articles_dropdown{
                        margin-left: 4vw;
                        fill: black;
                    }
                    .articles_dropdown_close{
                        fill: black;
                        margin-left: 4vw;
                        transform: rotate(180deg)
                    }
                }
                .dropdown_container{
                    position: absolute;
                    top: calc(100% + 10px); /* Adjust the top value as needed */
                    left: 0;
                    background: white;
                    border: 1px solid #ccc;
                    padding: 10px;
                    z-index: 2;
                    max-height: 30vh; /* Adjust the maximum height as needed */
                    overflow-y: auto;
                }
                .dropdown_container::-webkit-scrollbar {
                    width: 0;
                    background: transparent;
                  }
                .dropdown_main_category{
                    padding: 1vh 1vw 1vh 1vw;
                    text-align: center;
                    color: #000000;
                    background-color: #ffffff;
                    border: 1px solid #F1F1F1;
                    cursor: pointer;
                }
                .dropdown_main_category:hover{
                    box-shadow: inset 0 0 0 0.1rem rgba(0, 123, 255, 0.5);
                }
                .dropdown_main_category:active{
                    box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                }
            }

            .date_range{
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.13);
                background-color: rgba(250, 250, 250, 1);
                // margin-left: 2vw;
            }

            .prompt_toggler{
                font-weight: 700;
                // margin-left: 2vw;
                gap: 0.5vw;
                display: flex;
                align-items: center; 
                input{
                    cursor: pointer;
                }
                .toggler_main{
                    background-color: #3B89FE;
                    border-radius: 25px;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 12px 4px 12px 4px;

                    .toggles{
                        font-family: "Inter";
                        font-size: 1rem;
                        color: #ffffff;
                        transition: right 1s ease;
                        box-shadow: none;
                        flex: 1;
                        outline: none;
                        border-radius: 0;
                        padding-top: 0.2px;
                        padding-bottom: 0.2px;
                        position: relative;
                        .toggle_text{
                            text-align: left;
                            position: relative;
                            z-index: 1;
                        }
                    }
                
                    .left_toggle, .left_toggle:active, .left_toggle:focus{
                        border-right: 0.05px solid #ffffff6e;
                        border-left: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                    }
                    .right_toggle, .right_toggle:active, .right_toggle:focus{
                        border-left: 0px;
                        border-right: 0px;
                        border-top: 0px;
                        border-bottom: 0px;
                    }
                                        
                    .right_toggle_circle::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 3px; /* Adjust the distance from the right edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease
                    }
                    .left_toggle_circle::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 3px; /* Adjust the distance from the left edge as needed */
                        transform: translate(0, -50%);
                        width: 35px; /* Adjust the size of the circle as needed */
                        height: 35px;
                        background-color: rgba(22, 78, 160, 1); /* Adjust the color of the circle as needed */
                        border-radius: 50%; /* Create a circular shape */
                        transition: transform 0.3s ease;
                    }
                }
              
            }

            .export_pdf_container{
                // margin-left: 2vw;
                .Export_button{
                    cursor: pointer;
                    background:#1C5BB9; 
                    display: flex;
                    align-items: center;
                    gap: 5%;
                    .export_image{
                        margin-left: 0.5vw;
                    }
                }
                .absolute_verify_filters{
                    position: absolute;
                    top: 110%; /* Position below the parent div */
                    right: 0;
                    width: 25%; /* Full width of the parent */
                    background: linear-gradient(247deg, #F1F7FD 8.58%, #F9FCFF 66.71%); /* Sample background color */
                    box-shadow: 0px 15px 19px 0px rgba(0, 0, 0, 0.09);
                    backdrop-filter: blur(15.5px);
                    z-index: 2; /* Ensure a higher z-index for it to appear over other elements */
                    border-radius: 10px;
                    border: 1px solid #C3C3C3;              
                    display: flex;
                    flex-direction: column;
                    gap: 1.5vh;
                    padding: 10px;
                    .cross_modal{
                        cursor: pointer;
                        margin-left: auto;
                    }
                    .details_container{
                        display: flex;
                        flex-direction: column;
                        gap: 1vh;
                        padding-inline: 1vw;
                        .TotalArticles, .ReportFormat{
                            font-weight: 700;
                            font-size: small;                
                            span{
                                float: right;
                                color: #0E4FA7;
                                font-size: larger;                
                            }
                        }
                    }
                    .verify_buttons{
                        display: flex;
                        justify-content: center;
                        padding-inline: 1vw;
                        gap: 25%;
                        .verify, .proceed{
                            color: #0E4FA7;
                            background-color: #FFFFFF;
                            border-color: #0E4FA7;
                        }
                    }
                }
            }
        }
    }

    // tabs wrapper
    .tabs_section_wrapper{
        display: flex;
        .tabs_container{
            display: flex;
            gap: 2%;
            flex: 1;
            .expand_collapse{
                // display: flex;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                // align-items: center;
                border: 0.5px solid rgba(178, 178, 178, 1);
                border-radius: 6px;
                align-items: center;
                // padding: 6px;
                .back_blue{
                    background-color: rgba(49, 122, 231, 1);
                }
                .white_icon{
                    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
                }
                .black_icon{
                    filter:  invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
                }
                .expan, .collaps{
                    cursor: pointer;
                    padding: 8px;
                    padding-inline: 10px;
                    height: 100%;
                }
            }
            .selected, .not_selected{
                padding: 6px;
                cursor: pointer;
                font-weight: 700;
                font-size: large;
            }
            .selected{
                color: #1C70C8;
                position: relative;
            }
            .not_selected{
                color: #7A7A7A;
            }
            .selected::before{
                content: "";
                position: absolute;
                bottom: 0;
                left: 25%;
                width: 50%; /* Adjust this value to control the length of the border */
                height: 3px; /* Border thickness */
                background-color: #48A1FE;
            }
        }
    }

    .info_text_for_selected_articles{
        text-align: center;
        font-weight: 700;
        padding: 4px;
        margin-top: 2vh;
        border: 1px solid #1C70C8;
        z-index: 1; 
        border-radius: 4px;
        width: 80%;
        margin-inline: auto;
    }

    .applied_filters_container{
        display: flex;
        margin-top: 2vh;
        width: 70vw;
        gap: 5vw;
        .expand_collapse{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border: 0.5px solid rgba(178, 178, 178, 1);
            border-radius: 6px;
            align-items: center;
            .back_blue{
                background-color: rgba(49, 122, 231, 1);
            }
            .white_icon{
                filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(189deg) brightness(107%) contrast(100%);
            }
            .black_icon{
                filter:  invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(101%);
            }
            .expan, .collaps{
                cursor: pointer;
                padding: 8px;
                padding-inline: 10px;
                height: 100%;
            }
        }
        .total_articles{
            font-weight: bold;
            margin-top: auto;
            padding: 1vh;
            font-size: medium;
            background: white;
            // color: #8080ab;
            border-radius: 6px;
            span{
                font-weight: 600;
                background: rgba(49, 122, 231, 1);
                padding: 6px;
                border-radius: 6px;
                color: #FFFFFF;
            }
        }
        .date_applied{
            margin-left: auto;
            padding: 2vh;
            border-radius: 2vh;
            background: #FFFFFF;
            box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.12);
            .header_date{
                font-weight: 600;
            }
            .date_text{
                margin-top: 1vw;
                color: #3B89FE;
            }
        }
    }

    .table_wrapper{
        display: flex;
        margin-top: 2vh;
        .on_demand_table_container{
            width: 100%;
            .no_filters_logo_wrapper{
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                gap: 1vh;
                .no_filters_text{
                    font-size: x-large;
                    font-weight: 600;
                    color: #1C70C8;
                    text-align: center;
                }
            }

            .no_results_container{
                display: flex;
                flex-direction: column;
                margin-top: 18vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
            
            .arrow{
                height: 33px;
            }

            .dot {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.7vw;
            }
                
            .dot:before {
                content: "";
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #3B89FE;
                width: 0.8vw;
                height: 2.1vh;
                border: 5px solid #3B89FE;
            }
            .view_articles_heatmap{
                position: relative;
                z-index: 0;            
            }
            .pagination_container{
                background: transparent;
                backdrop-filter: blur(10px);
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    font-family: 'Inter';
                    position: absolute;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .quick_scroll{
            display: flex;
            flex-direction: column;
            padding-left: 2vw;
            .bottom{
                position: sticky;
                top: 1.5vh;
                cursor: pointer;
            }
            .top{
                margin-top: auto;
                position: sticky;
                bottom: 0;
                bottom: 1.5vh;
                cursor: pointer;
            }
        }
    }

    .selected_articles_table_wrapper{
        display: flex;
        margin-top: 2vh;
        .on_demand_table_container{
            width: 100%;
            .no_articles_logo_wrapper{
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                gap: 1vh;
                .no_articles_text{
                    font-size: x-large;
                    font-weight: 600;
                    color: #1C70C8;
                    text-align: center;
                }
            }

            .no_results_container{
                display: flex;
                flex-direction: column;
                margin-top: 18vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }
            
            .arrow{
                height: 33px;
            }

            .dot {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 0.7vw;
            }
                
            .dot:before {
                content: "";
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background-color: #3B89FE;
                width: 0.8vw;
                height: 2.1vh;
                border: 5px solid #3B89FE;
            }
            .view_articles_heatmap{
                position: relative;
                z-index: 0;            
            }
            .pagination_container{
                background: transparent;
                backdrop-filter: blur(10px);
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                .display_pages{
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    font-family: 'Inter';
                    position: absolute;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .quick_scroll{
            display: flex;
            flex-direction: column;
            padding-left: 2vw;
            .bottom{
                position: sticky;
                top: 1.5vh;
                cursor: pointer;
            }
            .top{
                margin-top: auto;
                position: sticky;
                bottom: 0;
                bottom: 1.5vh;
                cursor: pointer;
            }
        }
    }
} 