@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap');

.right_history_container{
    width: 84vw;
    padding: 6vh;
    padding-top: 2vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    position: relative;
    overflow: hidden;
    .top_filter_container{
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 0.8vw;
        .report_history_header{
            font-family: 'Inter';
            margin-right: 2vw;
            font-size: 25px;
            font-weight: bold;
            margin-top: 0;
            margin-top: 6px;
        }
        .refresh_logo_container{
            cursor: pointer;
            padding: 1vh;
            margin-top: 4px;
            height: 4.5vh;
        }
        .searchbar_container{
            margin-left: 5vw;
            .input_container{
                border: 1px solid #E5E5E6;
                border-radius: 0.2rem;
                padding: 1vh;
                width: 22vw;
            }
        }
        .date_range_container{
            display: flex;
            align-items: center; 
            justify-content: center;     
            .custom-date-picker{
                width: 15vw;
            }
        }
        .apply_filter_container{
            button{
                // width: 6vw;
                background: #0E4FA7;
                padding-inline: 2vw;
            }
        }
        .auto_manual_view{
            margin-left: auto;
            display: flex;
            gap: 1vw;
            background: #FFFFFF;
            border-radius: 6px;
            padding: 8px 15px;
            .auto_manual_tags{
                display: flex;
                gap: 0.5vw;
            }
            .color_auto{
                background: #0ABCB2;
            }
            .color_manual{
                background: #D4D4D4;
            }
            .color_auto, .color_manual{
                padding: 8px;
            }
            .tag_name{
                font-weight: 600;
            }
        }
    }

    .history_table_and_content{
        display: flex;
        margin-top: 4vh;
        .history_table_container{
            width: 100%;
            // width: 55vw;
            // padding: 1vh 4vw 1vh 4vw;
            // background-color: #ffffff;
            // box-shadow: '2px 4px 36px rgba(0, 0, 0, 0.08)';
            // border-radius: '2vh';

            .no_results_container{
                // border: 1px solid;
                display: flex;
                flex-direction: column;
                margin-top: 10vh;
                .child_no_results{
                    margin: auto;
                }
                .no_results{
                    height: 25vh;
                    background: #EFF5FF;
                    border-radius: 25px;
                    padding: 6vh;
                    // margin: auto;
                    box-shadow: 0px 2px 24px rgba(0, 96, 255, 0.16);
                }
                .no_results_header{
                    margin-top: 2vh;
                    color: #8080A2;
                    font-size: x-large;
                }
                .no_results_subheader{
                    color: #848484;
                    font-size: large;
                }
            }

            .table_btn{
                min-width: 6vw;
                align-items: center;
                display: flex;
            }

            .report_type_name{
                margin-left: 0.5vw;
                padding: 4px;
                border-radius: 30px;
                background-color: #adadada6;
                text-align: center;
                // font-weight: bold;
            }

            .no_report_type{
                padding: 1vh;
                border-radius: 30px;
                background-color: #FEEADB;
                text-align: center;
            }
            .report_type{
                padding: 1vh;
                border-radius: 30px;
                background-color: #55ACF517;
                text-align: center;
            }

            

            .pagination_container{
                display: flex;
                background: #ffffff;
                border-radius: 8px ;
                /* top-left | top-right | bottom-right | bottom-left */
                box-shadow: 0px 20px 50px 0px #0000001a;
                border-radius: 0px 0px 6px 6px;
                padding: 2vh;
                // justify-content: center;
                border-top: 1px solid #C8C8C8;
                justify-content: center;
                align-items: center;
                position: relative;
                .display_pages{
                    // font-family: 'Inter';
                    // height: auto;
                    // position: absolute;
                    // left: 1vw;
                    font-family: 'Inter';
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 1vw;
                    span{
                        font-weight: 600;       
                    }
                }
                .nav_container{
                    // margin-left: 10vw;
                    font-family: 'Inter';
                    width: auto;
                    height: auto;
    
                }
            }

        }
    }

}