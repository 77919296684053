@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&display=swap");
.right_article_container {
  width: 84vw;
  padding: 6vh;
  padding-top: 1vh;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
}
.right_article_container .translate_text_icon {
  cursor: pointer;
  width: 3.9%;
}
.right_article_container .top_navigation_container {
  display: flex;
  align-items: center;
}
.right_article_container .top_navigation_container .home_article_view_container {
  font-weight: bolder;
  font-family: "Inter";
  font-size: 25px;
}
.right_article_container .top_navigation_container .absolute_draft {
  cursor: pointer;
  margin-left: auto;
  z-index: 1;
}
.right_article_container .top_navigation_container .refresh_logo_container {
  cursor: pointer;
  margin-left: 0.5vw;
}
.right_article_container .top_navigation_container .preview_export_container {
  display: flex;
  margin-left: auto;
}
.right_article_container .top_navigation_container .preview_export_container button {
  border: 1px solid #C8C8C8;
  border-radius: 6px;
  padding: 1.1vh;
  background: white;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.right_article_container .top_navigation_container .preview_export_container button img {
  margin-left: 0.5vw;
}
.right_article_container .top_navigation_container .preview_export_container .export_container {
  margin-left: 1vw;
}
.right_article_container .article_content_container {
  display: flex;
  z-index: 1;
}
.right_article_container .article_content_container .left_article_content_container {
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  width: 55vw;
}
.right_article_container .article_content_container .left_article_content_container .tags_date_container {
  display: flex;
  margin-top: 2vh;
}
.right_article_container .article_content_container .left_article_content_container .tags_date_container .tags_container .tag {
  border: 1px solid #3B89FE;
  border-radius: 10px;
  color: #3B89FE;
  padding: 0.2vw;
  font-family: "DM Sans", sans-serif;
}
.right_article_container .article_content_container .left_article_content_container .tags_date_container .date_container {
  margin: auto;
  margin-left: 0.6vw;
  font-family: "DM Sans", sans-serif;
}
.right_article_container .article_content_container .left_article_content_container .article_topic_container {
  margin-top: 1vh;
  color: #3B89FE;
  font-family: "Inter", sans-serif;
  font-size: 5.5vh;
  font-weight: 500;
  line-height: 6.5vh;
  text-align: left;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container {
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container .article_keywords_container_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container .key {
  padding: 0.5vh 1vh 0.5vh 1vh;
  border-radius: 5px;
  background-color: #FEEADB;
  text-align: center;
  font-weight: bold;
  margin-right: 0.3vw;
  margin-bottom: 1vh;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container .keywords {
  padding: 0.5vh 1vh 0.5vh 1vh;
  border-radius: 5px;
  background-color: #DBEBFE;
  text-align: center;
  margin-left: 0.3vw;
  margin-bottom: 1vh;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container .dot_negative {
  color: red;
}
.right_article_container .article_content_container .left_article_content_container .article_keywords_container .dot {
  color: green;
}
.right_article_container .article_content_container .left_article_content_container .source_link_container {
  margin-top: 1vh;
}
.right_article_container .article_content_container .left_article_content_container .source_link_container img {
  margin-right: 0.8vw;
}
.right_article_container .article_content_container .left_article_content_container .source_link_container a {
  margin-left: 0.8vw;
}
.right_article_container .article_content_container .left_article_content_container .header {
  font-weight: bold;
}
.right_article_container .article_content_container .left_article_content_container .article_summary {
  margin-top: 2vh;
  font-family: "Inter", sans-serif;
  color: #1E1E1E;
  text-align: justify;
}
.right_article_container .article_content_container .left_article_content_container .article_summary pre {
  font-size: initial;
  font-family: "Inter", sans-serif;
  white-space: pre-wrap;
  color: #1E1E1E;
  font-size: large;
  line-height: 28px;
  overflow-x: hidden;
}
.right_article_container .article_content_container .left_article_content_container .article_summary pre span {
  padding: 0.3vh 0.2vw 0.3vh 0.2vw;
}
.right_article_container .article_content_container .left_article_content_container .full_article {
  margin-top: 2vh;
  font-family: "Inter", sans-serif;
  text-align: justify;
}
.right_article_container .article_content_container .left_article_content_container .full_article pre {
  font-size: initial;
  font-family: "Inter", sans-serif;
  white-space: pre-wrap;
  color: #1E1E1E;
  font-size: large;
  line-height: 28px;
}
.right_article_container .article_content_container .left_article_content_container .map {
  width: 50%;
}
.right_article_container .article_content_container .left_article_content_container .leaflet-container {
  height: 40vh;
}
.right_article_container .article_content_container .right_article_conatainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 17vw;
  margin-top: 3vh;
}
.right_article_container .article_content_container .right_article_conatainer .fls_container .fls_progress {
  display: flex;
  flex-direction: column-reverse;
  padding: 2vh;
  gap: 10px;
}
.right_article_container .article_content_container .right_article_conatainer .fls_container .fls_progress .fls_data {
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
}
.right_article_container .article_content_container .right_article_conatainer .fls_container .fls_progress .fls_data .progress_bar_manual {
  background-image: linear-gradient(to right, #0056b3, #007bff, #0d6efd);
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers {
  margin-top: 3vh;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 10px;
  background: linear-gradient(133.46deg, #ECF4FF -3.86%, rgba(244, 244, 244, 0) 137.07%);
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers hr {
  margin: 0;
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers .heading {
  color: #000000;
  text-align: center;
  font-size: 2.2vh;
  font-weight: bold;
  margin-top: 2vh;
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers .no_report_type {
  padding: 1vh;
  border-radius: 5px;
  background-color: #FEEADB;
  margin: 2vh;
  text-align: center;
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers .lops {
  display: flex;
  flex-direction: column;
  padding: 2vh;
  gap: 10px;
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers .lops li {
  color: #000000;
}
.right_article_container .article_content_container .right_article_conatainer .lop_containers .lops li::marker {
  color: #317ae7;
}
.right_article_container .related_articles_container {
  background: transparent;
  margin-top: 2vh;
  z-index: 1;
}
.right_article_container .related_articles_container .related_articles_header {
  font-weight: bold;
}
.right_article_container .related_articles_container .related_articles_cards {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2%;
}
.right_article_container .related_articles_container .related_articles_cards .prev_next_button {
  cursor: pointer;
  height: 3vh;
  width: 2vw;
}
.right_article_container .related_articles_container .related_articles_cards .prev_next_wrapper {
  padding: 0;
  background: white;
  border: none;
}
.right_article_container .related_articles_container .related_articles_cards .prev_next_wrapper_hidden {
  display: None;
}
.right_article_container .related_articles_container .related_articles_cards .next_button {
  transform: rotate(180deg);
}
.right_article_container .related_articles_container .related_articles_cards .pre_next_faded {
  opacity: 0.5;
}
.right_article_container .related_articles_container .related_articles_cards .all_cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Set the number of columns to 4 */
  gap: 2%;
  background: transparent;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: linear-gradient(133.46deg, #ECF4FF -3.86%, rgba(244, 244, 244, 0) 137.07%);
}
.right_article_container .related_articles_container .related_articles_cards .all_cards .article_card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 8px;
  gap: 2%;
  cursor: pointer;
}
.right_article_container .related_articles_container .related_articles_cards .all_cards .article_card .card_title {
  font-weight: 600;
  margin-bottom: 10%;
}
.right_article_container .related_articles_container .related_articles_cards .all_cards .article_card .card_domain_name {
  margin-top: auto;
}
.right_article_container .related_articles_container .related_articles_cards .all_cards .article_card:hover {
  transition: all 0.2s ease;
  box-shadow: 0px 12px 30px 0px rgba(13, 110, 253, 0.2784313725);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.article_draft_individual_popup_content {
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  align-items: center;
}
.article_draft_individual_popup_content .article_draft_popup_header {
  font-weight: 600;
  font-size: larger;
}
.article_draft_individual_popup_content .article_draft_search_bar_container {
  width: 100%;
  margin-top: 10%;
}
.article_draft_individual_popup_content .article_add_draft_button {
  display: flex;
  gap: 2vw;
  margin-top: 10%;
}
.article_draft_individual_popup_content .article_add_draft_button .button_main {
  background: #0E4FA7;
}
.article_draft_individual_popup_content .article_add_draft_button .cancel {
  background: #C8C8C8;
}/*# sourceMappingURL=rightArticle.css.map */